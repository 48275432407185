import Footer from "../../Footer";
import Header from "../../../frontendPages/Header";
import ContentManagementSideBar from "../ContentManagementSideBar/ContentManagementSideBar";
import mccm from "../../../images/assets/cm/mccm.svg";
import search from "../../../images/assets/cm/search.svg";
import edit from "../../../images/assets/cm/edit.svg";
import contact from "../../../images/assets/cm/contact.svg";
import trash from "../../../images/assets/cm/trash.svg";
import ContentHeaderTopDetail from "../ContentHeaderTopDetail/ContentHeaderTopDetail";
import "../ManageCorousel/ManageCorousel.scss";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { post, put } from "../../../utils/common";
import axios from "axios";
import LoadSpinner from "../../Loader/LoadSpinner";

function ManageCorousel() {
  const [data, setData] = useState([]);
  const [sortData, setSortData] = useState([]);

  const [summaryDiv, setSummaryDiv] = useState(true);
  const [statusDiv, setStatusDiv] = useState(true);

  const [openEditDiv, setOpenEditDiv] = useState(false);

  const [sortDiv, setSortDiv] = useState(false);

  const [showFormDiv, setShowFormDiv] = useState(false);

  const [corouselData, setCaraouselData] = useState([]);

  const [selectedCaraouselData, setSelectedCaraouselData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const showSummaryDivison = () => {
    setSummaryDiv(true);
    setSortDiv(false);
    setShowFormDiv(false);
    setOpenEditDiv(false);
  };

  const showSortDiv = () => {
    setSortDiv(true);
    setSummaryDiv(false);
    setShowFormDiv(false);
    setOpenEditDiv(false);
  };

  const setShowFormDivison = () => {
    setShowFormDiv(true);
    setSortDiv(false);
    setSummaryDiv(false);
    setOpenEditDiv(false);
    setSelectedCaraouselData([]);
  };

  const hideFormDivision = () => {
    setShowFormDiv(false);
    setSortDiv(false);
    setSummaryDiv(true);
    window.location.reload(false);
  };

  const requestEditLicense = (rowData) => {
    setSummaryDiv(false);
    setOpenEditDiv(true);
    setSelectedCaraouselData(data[rowData]);
  };

  const setAddCaraouselData = async (e) => {
    const id = e.target.value;
    setSelectedCaraouselData(data[id]);
  };

  const fetchData = async () => {
    setIsLoading(true);
    let data = await fetch("/api/managecaraousel/index", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (!data.ok) {
      return;
    }
    data = await data.json();
    console.log(data);

    setOptionStateForSort("isKansas");

    // let res = data.filter(())
    let res1 = data.data;
    let res = res1.filter((d) => d.isKansas === true);
    setSortData(res);
    setData(res1);

    setIsLoading(false);
  };

  const storeData = () => {
    setIsLoading(true);
    const data = new FormData();
    data.append("file", selectedCaraouselData.ImageName);
    data.append("corouselData", JSON.stringify(selectedCaraouselData));
    axios
      .post("/api/managecaraousel/store/", data, {
        // receive two    parameter endpoint url ,form data
      })
      .then((res) => {
        // then print response status
        alert("Record created successfully !");
        setIsLoading(false);
        window.location.reload(false);
      });
  };

  // console.log("updateData",selectedCaraouselData)
  const updateData = async () => {
    setIsLoading(true);
    // console.log("added", selectedCaraouselData);
    const id = selectedCaraouselData.CarouselImagesID;

    const data = new FormData();
    data.append("file", selectedCaraouselData.ImageName);
    data.append("corouselData", JSON.stringify(selectedCaraouselData));

    console.log("data", data);
    axios
      .put("/api/managecaraousel/update/" + id, data, {
        // receive two    parameter endpoint url ,form data
      })
      .then((res) => {
        // then print response status
        alert("Record Updated successfully !");
        setIsLoading(false);
        window.location.reload(false);
      });
    //  await put("/api/managecaraousel/update/" + id, { data });
  };

  const getAllDataByState = async (s) => {
    setIsLoading(true);
    if (s == "active") {
      let data = await post("/api/managecaraousel/getcaraouselbystatus", {
        status: 1,
      });
      setData(data.data.data);
    }
    if (s == "inactive") {
      let data = await post("/api/managecaraousel/getcaraouselbystatus", {
        status: 0,
      });
      setData(data.data.data);
    }
    if (s == "all") {
      fetchData();
    }

    setIsLoading(false);
  };

  const [optionStateForSort, setOptionStateForSort] = useState();

  const handleOnChangeOptionForSort = (e, num) => {
    if (num == 1) {
      setOptionStateForSort("isKansas");

      let res = data.filter((d) => d.isKansas === true);
      setSortData(res);
    }
    if (num == 2) {
      setOptionStateForSort("isNebraska");
      let res = data.filter((d) => d.isNebraska === true);
      setSortData(res);
    }
    if (num == 3) {
      setOptionStateForSort("isOklahoma");
      let res = data.filter((d) => d.isOklahoma === true);
      setSortData(res);
    }

    // isKansas
    // isNebraska
    // isOklahoma

    console.log("e", e.target.checked);
  };

  console.log("optionStateForSort", optionStateForSort);
  const DeleteCaraousel = async (j) => {
    setIsLoading(true);

    const temp = [...data];
    let id = temp[j].CarouselImagesID;
    temp.splice(j, 1);

    try {
      let data = await axios.delete("/api/managecaraousel/delete/" + id);

      alert("Data Deleted Successfully");
      setData(temp);
    } catch (err) {}

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      name: "Title",
      selector: "ImageTitle",
      sortable: true,
    },
    {
      name: "Button Url Link",
      selector: "urlButtonLink",
      sortable: true,
    },

    {
      name: "Action",
      sortable: true,
      cell: (row, j) => (
        <div className>
          <button className="normal" onClick={() => requestEditLicense(j)}>
            Select
          </button>
          &nbsp;&nbsp;
          <button className="close" onClick={() => DeleteCaraousel(j)}>
            Delete
          </button>
          &nbsp;&nbsp;
        </div>
      ),
    },
  ];

  const Sortcolumns = [
    {
      name: "Title",
      selector: "ImageTitle",
      sortable: true,
    },
    {
      name: "SortOrder",
      selector: "KansasOrder",
      sortable: true,
    },

    {
      name: "Action",
      sortable: true,
      cell: (row, j) => (
        <div className="inline-flex">
          <button className="normal">Edit</button>
          &nbsp;&nbsp;
        </div>
      ),
    },
  ];

  return (
    <div className="main">
      <Header />
      <div className="main-content">
        <ContentHeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <ContentManagementSideBar />
              <div className="tab-detail">
                <div className="tab-detail-top children-tabs">
                  <div className="children-section">
                    <img src={mccm} alt="" />
                    <div className="pagesubtitle">Manage Carousel</div>
                  </div>
                  <div className="mc-tabs">
                    <form>
                      <div className="manage-corousel-tab">
                        <span
                          className={summaryDiv ? "active" : ""}
                          onClick={showSummaryDivison}
                        >
                          Summary
                        </span>
                        |{" "}
                        <span
                          className={sortDiv ? "active" : ""}
                          onClick={showSortDiv}
                        >
                          Sort
                        </span>
                        |{" "}
                        <span
                          className={showFormDiv ? "active" : ""}
                          onClick={setShowFormDivison}
                        >
                          Add New
                        </span>
                      </div>

                      {/* <select name="cars" id="cars">
                                                <option value="volvo">Select</option>

                                            </select> */}
                    </form>
                  </div>
                  {isLoading ? <LoadSpinner /> : ""}
                </div>
                <div className="tab-detail-bottom children-data">
                  <div className="mb-30 tab-one">
                    {showFormDiv ? (
                      <form className="children-doc-form">
                        <div className="checkbox-design">
                          <span>
                            <input
                              onChange={(e) =>
                                setSelectedCaraouselData({
                                  ...selectedCaraouselData,
                                  isKansas: e.target.checked,
                                })
                              }
                              // checked={
                              //   selectedCaraouselData.isKansas ? "checked" : ""
                              // }
                              type="checkbox"
                              name="Kansas"
                              value="Kansas"
                            />
                            <label for="Kansas"> Kansas</label>
                          </span>
                          <span>
                            <input
                              onChange={(e) =>
                                setSelectedCaraouselData({
                                  ...selectedCaraouselData,
                                  isNebraska: e.target.checked,
                                })
                              }
                              // checked={
                              //   selectedCaraouselData.isNebraska
                              //     ? "checked"
                              //     : ""
                              // }
                              type="checkbox"
                              name="Nebraska"
                              value="Nebraska"
                            />
                            <label for="Nebraska">Nebraska</label>
                          </span>
                          <span>
                            <input
                              onChange={(e) =>
                                setSelectedCaraouselData({
                                  ...selectedCaraouselData,
                                  isOklahoma: e.target.checked,
                                })
                              }
                              // checked={
                              //   selectedCaraouselData.isOklahoma
                              //     ? "checked"
                              //     : ""
                              // }
                              type="checkbox"
                              name="Oklahoma"
                              value="Oklahoma"
                            />
                            <label for="Oklahoma">Oklahoma</label>
                          </span>
                        </div>

                        <div className="form-grey mt-25">
                          <div className="form-row">
                            <div className="one-third">
                              <label>Image Title:</label>
                              {/* {/*  */}
                              {/* <select onChange={setAddCaraouselData}>
                                <option>Select</option>
                                {data
                                  ? data.map((titles) => (
                                      <option value={titles.CarouselImagesID}>
                                        {titles.ImageTitle}
                                      </option>
                                    ))
                                  : "No Data"}
                              </select> */}
                              <input
                                onChange={(e) =>
                                  setSelectedCaraouselData({
                                    ...selectedCaraouselData,
                                    ImageTitle: e.target.value,
                                  })
                                }
                                type="text"
                              />
                            </div>
                            <div className="url-field">
                              <label>Button Link URL:</label>
                              <input
                                onChange={(e) =>
                                  setSelectedCaraouselData({
                                    ...selectedCaraouselData,
                                    urlButtonLink: e.target.value,
                                  })
                                }
                                type="text"
                                // value={
                                //   selectedCaraouselData.urlButtonLink
                                //     ? selectedCaraouselData.urlButtonLink
                                //     : ""
                                // }
                              />
                            </div>
                          </div>
                          <div className="form-row">
                            <div className="one-third">
                              <label>Begin Date *:</label>
                              <input
                                onChange={(e) =>
                                  setSelectedCaraouselData({
                                    ...selectedCaraouselData,
                                    BeginDate: e.target.value,
                                  })
                                }
                                // value={
                                //   selectedCaraouselData.BeginDate
                                //     ? selectedCaraouselData.BeginDate.split(
                                //       "T"
                                //     )[0]
                                //     : ""
                                // }
                                type="date"
                                id="birthday"
                                name="birthday"
                              />
                            </div>
                            <div className="one-third">
                              <label>End Date *:</label>
                              <input
                                onChange={(e) =>
                                  setSelectedCaraouselData({
                                    ...selectedCaraouselData,
                                    EndDate: e.target.value,
                                  })
                                }
                                // value={
                                //   selectedCaraouselData.EndDate
                                //     ? selectedCaraouselData.EndDate.split(
                                //       "T"
                                //     )[0]
                                //     : ""
                                // }
                                type="date"
                                id="birthday"
                                name="birthday"
                              />
                            </div>
                            <div className="one-third">
                              <label>Picture:</label>
                              <input
                                onChange={(e) =>
                                  setSelectedCaraouselData({
                                    ...selectedCaraouselData,
                                    ImageName: e.target.files[0],
                                  })
                                }
                                type="file"
                                id="myFile"
                                name="filename"
                              />
                            </div>
                          </div>
                          <Link
                            to="#"
                            className="close"
                            onClick={hideFormDivision}
                          >
                            Close
                          </Link>
                          <span></span>
                          {/* <br></br> */}
                          <Link to="#" onClick={storeData} className="normal">
                            Submit
                          </Link>
                        </div>
                      </form>
                    ) : (
                      ""
                    )}

                    {openEditDiv ? (
                      <div>
                        <form className="children-doc-form">
                          <div className="checkbox-design">
                            <span>
                              <input
                                onChange={(e) =>
                                  setSelectedCaraouselData({
                                    ...selectedCaraouselData,
                                    isKansas: e.target.checked,
                                  })
                                }
                                checked={
                                  selectedCaraouselData.isKansas
                                    ? "checked"
                                    : ""
                                }
                                type="checkbox"
                                name="Kansas"
                                value="Kansas"
                              />
                              <label for="Kansas"> Kansas</label>
                            </span>
                            <span>
                              <input
                                onChange={(e) =>
                                  setSelectedCaraouselData({
                                    ...selectedCaraouselData,
                                    isNebraska: e.target.checked,
                                  })
                                }
                                checked={
                                  selectedCaraouselData.isNebraska
                                    ? "checked"
                                    : ""
                                }
                                type="checkbox"
                                name="Nebraska"
                                value="Nebraska"
                              />
                              <label for="Nebraska">Nebraska</label>
                            </span>
                            <span>
                              <input
                                onChange={(e) =>
                                  setSelectedCaraouselData({
                                    ...selectedCaraouselData,
                                    isOklahoma: e.target.checked,
                                  })
                                }
                                checked={
                                  selectedCaraouselData.isOklahoma
                                    ? "checked"
                                    : ""
                                }
                                type="checkbox"
                                name="Oklahoma"
                                value="Oklahoma"
                              />
                              <label for="Oklahoma">Oklahoma</label>
                            </span>
                          </div>

                          <div className="form-grey mt-25">
                            <div className="form-row">
                              <div className="one-third">
                                <label>Image Title:</label>
                                <input
                                  onChange={(e) =>
                                    setSelectedCaraouselData({
                                      ...selectedCaraouselData,
                                      ImageTitle: e.target.value,
                                    })
                                  }
                                  type="text"
                                  value={
                                    selectedCaraouselData.ImageTitle
                                      ? selectedCaraouselData.ImageTitle
                                      : ""
                                  }
                                />
                              </div>
                              <div className="url-field">
                                <label>Button Link URL:</label>
                                <input
                                  onChange={(e) =>
                                    setSelectedCaraouselData({
                                      ...selectedCaraouselData,
                                      urlButtonLink: e.target.value,
                                    })
                                  }
                                  type="text"
                                  value={
                                    selectedCaraouselData.urlButtonLink
                                      ? selectedCaraouselData.urlButtonLink
                                      : ""
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="one-third">
                                <label>Begin Date *:</label>
                                <input
                                  onChange={(e) =>
                                    setSelectedCaraouselData({
                                      ...selectedCaraouselData,
                                      BeginDate: e.target.value,
                                    })
                                  }
                                  type="date"
                                  id="birthday"
                                  name="birthday"
                                  value={
                                    selectedCaraouselData.BeginDate
                                      ? selectedCaraouselData.BeginDate.split(
                                          "T"
                                        )[0]
                                      : ""
                                  }
                                />
                              </div>
                              <div className="one-third">
                                <label>End Date *:</label>
                                <input
                                  onChange={(e) =>
                                    setSelectedCaraouselData({
                                      ...selectedCaraouselData,
                                      EndDate: e.target.value,
                                    })
                                  }
                                  type="date"
                                  id="birthday"
                                  name="birthday"
                                  value={
                                    selectedCaraouselData.EndDate
                                      ? selectedCaraouselData.EndDate.split(
                                          "T"
                                        )[0]
                                      : ""
                                  }
                                />
                              </div>
                              <div className="one-third">
                                <label>Picture:</label>
                                <input
                                  onChange={(e) =>
                                    setSelectedCaraouselData({
                                      ...selectedCaraouselData,
                                      ImageName: e.target.files[0],
                                    })
                                  }
                                  type="file"
                                  id="myFile"
                                  name="filename"
                                />
                              </div>
                            </div>
                            <Link
                              to="#"
                              className="close"
                              onClick={hideFormDivision}
                            >
                              Close
                            </Link>{" "}
                            {/* <br></br> */}
                            <Link
                              to="#"
                              onClick={updateData}
                              className="normal"
                            >
                              Submit
                            </Link>
                          </div>
                        </form>
                      </div>
                    ) : (
                      ""
                    )}

                    {summaryDiv ? (
                      <div>
                        <div className="manage-anounce mb-30">
                          <Link
                            to="#"
                            onClick={() => getAllDataByState("all")}
                            className="normal"
                          >
                            All
                          </Link>
                          <Link
                            to="#"
                            onClick={() => getAllDataByState("active")}
                            className="normal"
                          >
                            Active
                          </Link>
                          <Link
                            to="#"
                            onClick={() => getAllDataByState("inactive")}
                            className="normal"
                          >
                            In-Active
                          </Link>
                        </div>
                        <DataTable
                          columns={columns}
                          data={data}
                          pagination={false}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {sortDiv ? (
                    <div>
                      <div className="checkbox-design">
                        <span>
                          <input
                            onChange={(e) => handleOnChangeOptionForSort(e, 1)}
                            checked={
                              optionStateForSort == "isKansas" ? true : false
                            }
                            type="radio"
                            name="Nebraska"
                            value="Nebraska"
                          />
                          <label for="Kansas"> Kansas</label>
                        </span>
                        <span>
                          <input
                            onChange={(e) => handleOnChangeOptionForSort(e, 2)}
                            type="radio"
                            checked={
                              optionStateForSort == "isNebraska" ? true : false
                            }
                            name="Nebraska"
                            value="Nebraska"
                          />
                          <label for="Nebraska">Nebraska</label>
                        </span>
                        <span>
                          <input
                            onChange={(e) => handleOnChangeOptionForSort(e, 3)}
                            type="radio"
                            checked={
                              optionStateForSort == "isOklahoma" ? true : false
                            }
                            name="Nebraska"
                            value="Nebraska"
                          />
                          <label for="Oklahoma">Oklahoma</label>
                        </span>
                      </div>
                      <DataTable
                        columns={Sortcolumns}
                        data={sortData}
                        pagination={false}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ManageCorousel;
