import Footer from "../../Footer";
import Header from "../../Header";
import ContentManagementSideBar from "../ContentManagementSideBar/ContentManagementSideBar";
import manageanounce from "../../../images/assets/cm/manageanounce.svg";
import ContentHeaderTopDetail from "../ContentHeaderTopDetail/ContentHeaderTopDetail";
import "../AdoptionContact/AdoptionContact.scss";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import editimage from "../../../images/assets/cm/edit.svg";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import axios from "axios";
import { post, put } from "../../../utils/common";
import LoadSpinner from "../../Loader/LoadSpinner";

function AnnouncementList() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [AnnouncementTypeData, setAnnouncementTypeData] = useState([]);

  const [announcementFilterId, setAnnouncementFilterId] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    let data = await fetch("/api/manageannouncement/index");
    if (!data.ok) {
      return;
    }
    data = await data.json();
    console.log(data);
    setData(data.data);
    setIsLoading(false);
  };

  const fetchAllAnnouncementTypeData = async () => {
    setIsLoading(true);
    let data = await post("/api/manageannouncement/getallannouncementtype");
    // if (!data.ok) {
    //    return;
    // }
    // data = await data.json();
    console.log("sssss", data);
    setAnnouncementTypeData(data.data.data);
    setIsLoading(false);
  };

  const FilterAnnouncement = async () => {
    setIsLoading(true);
    const announcementtypeid = announcementFilterId.AnnouncementTypeID;

    if (announcementtypeid == 0) {
      fetchData();
    } else {
      if (announcementtypeid) {
        let announcementtypedata = await post(
          "/api/manageannouncement/getallannouncementbytypeid",
          { announcementtypeid }
        );
        setData(announcementtypedata.data.data);
      }
    }

    setIsLoading(false);
  };

  console.log("ss", AnnouncementTypeData);

  const [selectedDataToDisplay, setSelectedDataToDisplay] = useState([]);
  const [showSelectedData, setShowSelectedData] = useState(false);

  const showDetail = (row) => {
    //   const selectedData = ;
    setIsLoading(true)
    console.log(row);
    setSelectedDataToDisplay(data[row]);
    sessionStorage.setItem("announcementViewData", JSON.stringify(data[row]));
    window.location.href = "/contentmanage/detailview-announcement";
    setIsLoading(false)
    //  setShowSelectedData(true);
  };


  const removeRow = async (id) => {
    setIsLoading(true)
    console.log(id)
    // setIsLoading(true)
    const temp = [...data];
    const primaryKey = id;
    temp.splice(id, 1);
    try {
        await axios.delete(`/api/manageannouncement/delete/${encodeURIComponent(primaryKey)}`);
        alert("Are you sure to Delete Data");
        setData(temp);
        window.location.reload(false);
    } catch (err) { }
    setIsLoading(false)
    
};

  useEffect(() => {
    fetchData();
    fetchAllAnnouncementTypeData();
  }, []);

  return (
    <div className="main">
      <Header />
      <div className="main-content">
        <ContentHeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              {/* <ContentManagementSideBar /> */}
              <div className="tab-detail full-page-view">
                <div className="tab-detail-top children-tabs">
                  <div className="children-section">
                    <img src={manageanounce} alt="" />
                    <div className="pagesubtitle">Announcements</div>
                  </div>
                </div>

                {isLoading ? <LoadSpinner /> : ""}

                <div className="form-row Announcement-filter">
                  <div className="Announcement-filter-row">
                    {/* <span className="checkbox-design"> */}
                    <label for="vehicle1">Type Of Announcement</label>
                    <div className="Announcement-filter-area">
                      <select
                        onChange={(e) =>
                          setAnnouncementFilterId({
                            ...announcementFilterId,
                            AnnouncementTypeID: e.target.value,
                          })
                        }
                      >
                        <option value="0">All</option>

                        {AnnouncementTypeData.map((announcement) => (
                          <option value={announcement.AnnouncementTypeID}>
                            {announcement.AnnouncementTypeName}
                          </option>
                        ))}
                      </select>
                      <Link
                        to="#"
                        onClick={FilterAnnouncement}
                        className="normal"
                      >
                        Filter
                      </Link>
                    </div>
                  </div>
                 
                </div>

               
                <div className="main-page">
                  {data.map((d, index) => (
                    <div>
                      <Link to="#" onClick={()=>removeRow(d.AnnouncementsID)}>Delete</Link>
                    <div
                      className="announcement-list"
                      onClick={() => showDetail(index)}
                    >
                      <div className="imageSection">
                        <img
                          src={`https://sfmfamilies.org/api/manageannouncement/download/${d.Picturepath}`}
                          width="150px"
                        ></img>
                      </div>
                      <div className="content-section cut-text" >
                        <h1>{d.Title}</h1>
                        <h3>{d.Description}</h3>
                        <span><button className="normal" onClick={() => showDetail(index)}>More...</button></span>
                      </div>
                      
                    </div>
                    
                    </div>
                    
                  ))}
                </div>

                {/* <DataTable columns={columns} data={data} pagination={true} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AnnouncementList;
