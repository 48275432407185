import Footer from "../../Footer";
import Header from "../../../frontendPages/Header";
import placeinner from "../../../images/assets/currentChildren/placeinner.svg";
import edit from "../../../images/assets/currentChildren/edit.svg";
import "../../CurrentChildren/ImmidiateAssesmentCurrentChildren/ImmidiateAssesmentCurrentChildren.scss";
import CurrentChildrenSideBar from "../CurrentChildrenSidebar/CurrentChildrenSidebar";
import HeaderTopDetail from "../HeaderTopDetail/HeaderTopDetail";
import { useState } from "react";
import { Link } from "react-router-dom";
import { post } from "../../../utils/common";
import LoadSpinner from "../../Loader/LoadSpinner";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import mail from "../../../images/mail.png";

function PlacementHistory() {
  const [updateDiv, setShowUpdateDiv] = useState(false);

  const [selectedDataForEdit, setSelectedDataForEdit] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [updateData, setUpdateData] = useState([]);

  // const showUpdateDiv = () => {
  //     setShowUpdateDiv(true)
  //     setShowAddDiv(false)
  // }

  const hideUpdateDiv = () => {
    setShowUpdateDiv(false);
  };

  // const showAddDiv = () => {
  //     setShowUpdateDiv(false)
  //     setShowAddDiv(true)

  // }

  // const ondAdditionSubmit = async () => {
  //     setIsLoading(true)

  //     let username = sessionStorage.getItem('username');
  //     await post('/api/placementHistory/update-placementHistory-addition', { username, updateData });
  //     alert('Request Update  Emailed Successfully !');
  //     setIsLoading(false)
  //    // window.location.reload(false)
  //    // showUpdateDiv(true)
  // }

  const onupdateClick = async () => {
    // setIsLoading(true)

    let username = sessionStorage.getItem("username");

    setShowUpdateDiv(false);
    // setIsLoading(false)
    setUpdateData([]);

    await post("/api/placementhistory/updateplacementhistory", {
      username,
      updateData,
    });
    alert("Request Update  Emailed Successfully !");

    //  window.location.reload(false)
    // alert("Submitted Successfully");
    // window.location.reload(false)
  };

  const requestEditPlacementHistory = (rowData) => {
    setShowUpdateDiv(true);

    setUpdateData(data[rowData]);
    setSelectedDataForEdit(data[rowData]);
    // console.log(LicenseDataForEdit)
  };
  const [data, setData] = useState([]);

  const [childrenData, setChildrenData] = useState([]);
  const fetchChildData = async () => {
    const id = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/currentchildren/index", { id });
    setChildrenData(data.data.data);

    let children_id = data.data.data[0].ClientID;
    setActiveId(children_id);

    let provider_id = id;
    let detailData = await post("/api/currentchildren/placement-history", {
      provider_id,
      children_id,
    });
    console.log("detailData", detailData);

    setData(detailData.data.data);
  };

  const [activeId, setActiveId] = useState();

  const handleCurrentChildGlobalPlacementHistory = async (children_id) => {
    setIsLoading(true);
    setActiveId(children_id);

    sessionStorage.setItem("childrenId", children_id);
    children_id = sessionStorage.getItem("childrenId");
    const provider_id = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/currentchildren/placement-history", {
      provider_id,
      children_id,
    });
    setData(data.data.data);
    let detaildata = await post("/api/currentchildren/children-detail", {
      provider_id,
      children_id,
    });
    sessionStorage.setItem(
      "current_child",
      detaildata.data.data.length > 0
        ? detaildata.data.data[0].ClientName
        : "Null"
    );

    // setData(detaildata.data.data)
    // console.log("Data", data[0])

    setIsLoading(false);
  };

  // const fetchData = async () => {

  //     const child_id = sessionStorage.getItem("childrenId")
  //     let data = await post('/api/medication/getallmedicationdata', { child_id });
  //     if (!data.ok) {

  //         return;
  //     }
  //     data = await data.json();
  //     console.log(data);
  //     setData(data.data);
  // };

  useEffect(() => {
    // fetchData();
    fetchChildData();
  }, []);

  const columns = [
    {
      name: "Name of Placement",
      selector: "Name_Of_Placement",
      sortable: true,
      wrap: true,
    },

    {
      name: "Reason for Move",
      selector: "Reason_for_Move",
      sortable: true,
      wrap: true,
    },
    {
      name: "Behaviors",
      selector: "Behaviors",
      sortable: true,
      wrap: true,
    },
    // {
    //     name: 'Level of Care',
    //     selector: 'Level_of_Care',
    //     sortable: true,
    // },
    {
      name: "Begin Date",
      selector: "Begin_Date",
      cell: (row, j) => (row.Begin_Date ? row.Begin_Date.slice(0, 10) : "NULL"),
      // cell: (row, j) => ((new Date().getUTCFullYear() - new Date(row.DOB).getUTCFullYear())),
      sortable: true,
      wrap: true,
    },
    {
      name: "End Date",
      selector: "End_Date",
      cell: (row, j) => (row.End_Date ? row.End_Date.slice(0, 10) : "NULL"),
      sortable: true,
      wrap: true,
    },

    {
      name: "Action",
      sortable: true,
      cell: (row, j) => (
        <div class="inline-flex">
          <Link
            to="#"
            onClick={() => requestEditPlacementHistory(j)}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={mail} alt="tag"></img>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div className="main">
      <Header />
      <div className="main-content">
        <HeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <CurrentChildrenSideBar />
              <div className="tab-detail">
                <div className="tab-detail-top childprofile-tabs">
                  <div className="children-section">
                    <img src={placeinner} alt="" />
                    <div className="pagesubtitle">Placement History</div>
                  </div>
                  {/*<Link to="#" onClick={showAddDiv} className="btn-big">Request Addition</Link>*/}
                </div>
                <div className="children-tab">
                  {childrenData.map((child) => {
                    return (
                      <span
                        className={activeId === child.ClientID ? "active" : ""}
                        onClick={() =>
                          handleCurrentChildGlobalPlacementHistory(
                            child.ClientID
                          )
                        }
                      >
                        {child.ClientName}
                      </span>
                    );
                  })}
                  {/* <span className="active">Easton Elliott</span> | <span>Easton Easton</span> | <span>Anna Litchfield</span> */}
                </div>
                {isLoading ? <LoadSpinner /> : ""}

                <div className="tab-detail-bottom children-data">
                  <form className="children-doc-form">
                    {updateDiv ? (
                      <div className="req-update-textarea">
                        <label>
                          <span>
                            <b>Request Update for</b> - Current
                            children-Placement History |
                            <b>
                              {" "}
                              Child: {sessionStorage.getItem(
                                "current_child"
                              )} |{" "}
                            </b>
                            Name of Placement:{" "}
                            {selectedDataForEdit.Name_Of_Placement
                              ? selectedDataForEdit.Name_Of_Placement
                              : ""}{" "}
                            | Reason for Move:{" "}
                            {selectedDataForEdit.Reason_for_Move
                              ? selectedDataForEdit.Reason_for_Move
                              : ""}{" "}
                            | Begin Date :{" "}
                            {selectedDataForEdit.Begin_Date
                              ? selectedDataForEdit.Begin_Date
                              : ""}
                            <br></br>
                            <br></br>
                            <b> Description for Update:</b>{" "}
                          </span>
                        </label>
                        <textarea
                          onChange={(e) =>
                            setUpdateData({
                              ...updateData,
                              description: e.target.value,
                            })
                          }
                        ></textarea>
                        <div className="req-update-action">
                          <Link
                            to="#"
                            onClick={hideUpdateDiv}
                            className="close"
                          >
                            close
                          </Link>
                          <Link
                            to="#"
                            onClick={onupdateClick}
                            className="normal"
                          >
                            Submit
                          </Link>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* {AddDiv ? <div className="req-update-textarea">
                                            <label><span><b>Request Addition for</b>  -  Current children-Placement History|

                                                <b> Child: {sessionStorage.getItem("current_child")} |
                                                </b>  <br></br>
                                                <br></br>
                                                <b> Description for Additon:</b></span></label>
                                            <textarea onChange={e => setUpdateData({ ...updateData, description: e.target.value })}></textarea>
                                            <div className="req-update-action">
                                                <Link to="#" onClick={hideAddDiv} className="close">close</Link>
                                                <Link to="#" onClick={onupdateClick} className="normal">Submit</Link>
                                            </div>
                                        </div> : ''} */}
                    {/* <table>
                                            <tr>
                                                <th>Provider Name</th>
                                                <th>Provider Type</th>
                                                <th>Begin Date</th>
                                                <th>End Date</th>
                                                <th className="download-action"></th>
                                            </tr>
                                            <tr>
                                                <td>Provider Name</td>
                                                <td>100.00MG</td>
                                                <td>mm/dd/yy</td>
                                                <td>mm/dd/yy</td>
                                                <td className="download-icon"><Link to="#" onClick={showUpdateDiv}><img src={edit} alt="" /></Link></td>
                                            </tr>
                                            <tr>
                                                <td>Provider Name</td>
                                                <td>100.00MG</td>
                                                <td>mm/dd/yy</td>
                                                <td>mm/dd/yy</td>
                                                <td className="download-icon"><Link to="#" onClick={showUpdateDiv}><img src={edit} alt="" /></Link></td>
                                            </tr>

                                            <tr>
                                                <td>Provider Name</td>
                                                <td>100.00MG</td>
                                                <td>mm/dd/yy</td>
                                                <td>mm/dd/yy</td>
                                                <td className="download-icon"><Link to="#" onClick={showUpdateDiv}><img src={edit} alt="" /></Link></td>
                                            </tr>




                                        </table> */}

                    <DataTable
                      columns={columns}
                      data={data}
                      pagination={true}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PlacementHistory;
