import Footer from '../../Footer';
import Header from '../../Header';
import schoolinner from '../../../images/assets/myinfo/schoolinner.svg';
import DataTable from 'react-data-table-component';
import download from '../../../images/assets/myinfo/download.svg';
import edittwo from '../../../images/assets/myinfo/edittwo.svg';
import print from '../../../images/assets/myinfo/print.svg';

// import acmanage from '../../images/assets/images/ManageInfo.svg';
import SideBar from '../../sidebar/sidebar';
import '../School/school.scss'
import HeaderTopDetail from '../../CurrentChildren/HeaderTopDetail/HeaderTopDetail';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { post } from '../../../utils/common';
import mail from '../../../images/mail.png';
import LoadSpinner from '../../Loader/LoadSpinner';
import plus from '../../../images/assets/cm/plus.svg';




function School() {

    const [data, setData] = useState([]);


    const [updateDiv, setShowUpdateDiv] = useState(false);

    const [addDiv, setAddDiv] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [schoolDataSelectedForEditRequest, setschoolDataSelectedForEditRequest] = useState([]);

    const showUpdateDiv = () => {
        setShowUpdateDiv(true)
    }
    const hideUpdateDiv = () => {
        setShowUpdateDiv(false);
    }

    const hideAdditionDiv = () => {
        setAddDiv(false)
    }

    const ShowAdditionDiv = () => {
        setAddDiv(true)
        setShowUpdateDiv(false)
    }
    const fetchData = async () => {
        // const providerid = sessionStorage.getItem('providerSessionId');
        const provider_id = sessionStorage.getItem('providerSessionId');
        let data = await post('/api/school/index', { provider_id });
        // if (!data.ok) {
        //     return;
        // }
        // data = await data.json();
        console.log("dcsd",data);
        setData(data.data.data);
    };


    const onAdditionClick =async () => {
       // setIsLoading(true);
        


        let username = sessionStorage.getItem('username');
        setShowUpdateDiv(false)
        setAddDiv(false)
        setUpdateData([])
        await post('/api/school/update-school-addition', { username, updateData });
        alert('Request Addition Emailed Successfully !');
        setIsLoading(false);
        window.location.reload(false)
        // alert("Submitted Successfully");
        // window.location.reload(false)
    }


    const [updateData, setUpdateData] = useState([])

    const onUpdateClick = async () => {
      //  setIsLoading(true);
        


        let username = sessionStorage.getItem('username');
        setShowUpdateDiv(false)
        setAddDiv(false)
        setUpdateData([])
        await post('/api/school/update-school', { username, updateData });
        alert('Request Updation  Emailed Successfully !');
        setIsLoading(false);
        window.location.reload(false)
        // alert("Submitted Successfully");
        // window.location.reload(false)
    }

    const requestEdit = (j) => {

        setschoolDataSelectedForEditRequest(data[j])
        setAddDiv(false)
        setShowUpdateDiv(true)
    }

    useEffect(() => {
        fetchData();
    }, []);

    const columns = [
        {


            name: 'School Name',
            selector: 'School_Name',
            sortable: true,
        },
        {


            name: 'USD',
            selector: 'USD',
            sortable: true,
        },
        {


            name: 'City',
            selector: 'City',
            sortable: true,
        },
        {


            name: 'State',
            selector: 'State',
            sortable: true,
        },

        {


            name: 'Begin Date',
            selector: 'Begin_Date',
            // cell: (row, j) => (new Date(row.BeginDate).toLocaleDateString()),
            sortable: true,
        },


        {
            name: 'Action',
            sortable: true,
            cell: (row, j) => (
                <div class="inline-flex">
                    <Link onClick={() => requestEdit(j)}

                        class="text-pink-500 bg-transparent border border-solid border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        to="#"
                    >
                        <img src={mail}></img>
                    </Link>
                    {/* &nbsp;&nbsp; */}
                    {/* <button
               
                class="text-pink-500 bg-transparent border border-solid border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
              >
              <img src={edittwo}></img>
              </button> */}

                </div>
            ),
        },
    ];
    return (


        <div className="main">

            <Header />

            <div className="main-content" >
                <HeaderTopDetail />
                <div className="pagewithsidebar">
                    <div className="container">
                        <div className="pagewithsidebar-inner">
                            <SideBar />
                            <div className="tab-detail pl">
                                <div className="tab-detail-top">
                                    <div className="m-profile">
                                        <img src={schoolinner} alt="" />
                                        <div className="pagesubtitle">
                                            School
                                            <p>Below are the schools in my district.</p>
                                        </div>
                                    </div>
                                    <Link to="#" onClick={ShowAdditionDiv} className="request-btn"><img src={plus} style={{"height": "18px"}} alt="#"/>Add new school</Link>
                                </div>
                                {isLoading ? (<LoadSpinner />) : ''}
                                <div className="tab-detail-bottom">
                                    <form className="attach-doc-form">

                                        {addDiv ? <div className="req-update-textarea">
                                            <label><span><b>Request Addition for</b> - school
                                            <br></br>
                                                <br></br>
                                           <b> Description for Addition:</b></span></label>
                                            <textarea onChange={e => setUpdateData({ ...updateData, description: e.target.value })}></textarea>
                                            <div className="req-update-action">
                                                <Link to="#" onClick={hideAdditionDiv} className="close">close</Link>
                                                <Link to="#" onClick={onAdditionClick} className="normal">Submit</Link>
                                            </div>
                                        </div> : ''}

                                        {updateDiv ? <div className="req-update-textarea">
                                            <label><span><b>Request Update for - </b>School   |
                                                School Name: {schoolDataSelectedForEditRequest.School_Name ? schoolDataSelectedForEditRequest.School_Name : ''}    |
                                                USD: {schoolDataSelectedForEditRequest.USD ? schoolDataSelectedForEditRequest.USD : ''}    |
                                                City: {schoolDataSelectedForEditRequest.City ? schoolDataSelectedForEditRequest.City : ''} |
                                                State: {schoolDataSelectedForEditRequest.State ? schoolDataSelectedForEditRequest.State : ''}|
                                                Begin Date: {schoolDataSelectedForEditRequest.Begin_Date ? schoolDataSelectedForEditRequest.Begin_Date : ''}
                                                <br></br>
                                                <br></br>
                                           <b> Description for Update:</b>
                                                </span></label>
                                            <textarea onChange={e => setUpdateData({ ...updateData, description: e.target.value })}></textarea>
                                            <div className="req-update-action">
                                                <Link to="#" onClick={hideUpdateDiv} className="close">close</Link>
                                                <Link to="#" onClick={onUpdateClick} className="normal">Submit</Link>
                                            </div>
                                        </div> : ''}
                                        {/* <table>
                                            <tr>
                                                <th>School Name</th>
                                                <th>USD</th>
                                                <th>City</th>
                                                <th>State</th>
                                                <th>Begin Date</th>
                                                <th></th>
                                            </tr>
                                            
                                                {data.map(d => ( <tr>
                                                <td>{d.SchoolName}</td>
                                                <td>{d.USD}</td>
                                                <td>{d.CityID}</td>
                                                <td>{d.StateID}</td>
                                                <td>{d.BeginDate}</td>
                                                </tr>
                                                ))}
                                                {/* <tr>
                                                <td>{data.SchoolName}</td>
                                                <td>{data.USD}</td>
                                                <td>Bentley</td>
                                                <td>Bentley</td>
                                                <td>04/21/08</td>
                                                <td className="table-act"><Link to=""><img src={edittwo} alt="" /></Link></td>
                                            </tr> */}


                                        <DataTable columns={columns} data={data} pagination={true} />


                                        {/* </table> */}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    );
}


export default School;

