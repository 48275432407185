import Footer from "../../Footer";
import Header from "../../Header";
import "../FormIndex/FormIndex.scss";
import forminner from "../../../images/assets/form/forminner.svg";
import f1 from "../../../images/assets/form/f1.svg";
import f2 from "../../../images/assets/form/f2.svg";
import f3 from "../../../images/assets/form/f3.svg";
import f4 from "../../../images/assets/form/f4.svg";
import f5 from "../../../images/assets/form/f5.svg";
import pdf from "../../../images/assets/form/pdf.svg";
import dicon from "../../../images/assets/form/dicon.svg";
import downloadform from "../../../images/assets/form/downloadform.svg";
import SideBar from "../../sidebar/sidebar";
import HeaderTopDetail from "../../CurrentChildren/HeaderTopDetail/HeaderTopDetail";
import FormSideBar from "../FormSidebar/FormSidebar";
import { Link } from "react-router-dom";
import CriticalUnusualIncidentFormPrint from "../../downloadableForms/CriticalUnusualIncidentDownload/CriticalUnusualIncidentPrintForm";
import ImmidiateAssesmentFormPrint from "../../downloadableForms/ImmidiateAssesmentDownload/ImmidiateAssesmentFormPrint";
import LogOfDailyEventsFormPrint from "../../downloadableForms/LogOfDailyEventsDownload/LogOfDailyEventsFormPrint";
import MilageRembursementFormPrint from "../../downloadableForms/MilageRembursementForm/MilageRemburseMentPrintForm";
import PowerOfYouFormPrint from "../../downloadableForms/PowerOfYouFormPrint/PowerofYouFormPrint";
import { post } from "../../../utils/common";
import React, { useState, useEffect } from "react";
import { get } from "http";
import DataTable from "react-data-table-component";
import axios from "axios";
import printJS from "print-js";
import download from "../../../images/assets/myinfo/download.svg";
import edit from "../../../images/assets/myinfo/edit.svg";
import print from "../../../images/assets/myinfo/print.svg";
function FormIndex() {
  const [FileData, setFileData] = useState([]);
  const [FilterData, setFilterData] = useState([]);
  const [AllFileData, setAllFileData] = useState([]);

  const fetchData = async () => {
    let data = await fetch("/api/downlodableform/index");
    data = await data.json();

    setFileData(data.data);
    setAllFileData(data.data);
  };

  const handleFilter = (e) => {
    let fileType = e.target.value;
    console.log(e.target.value);
    let res = FileData.filter((file) => file.Type == fileType);
    setAllFileData(res);
    if (e.target.value == "ALL_FORM") {
      setAllFileData(FileData);
    }
  };

  const downLoadFile = async (filename) => {
    return await fetch("/api/downlodableform/files/" + filename).then(
      (response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();
        });
      }
    );
  };

  const Printpdf = async (filename, j) => {
    let url = "https://sfmfamilies.org/api/downlodableform/files/" + filename;
    printJS(url);
    // var pdf = document.getElementById("examplePDF_" + j);
    // window.focus(pdf);
    // window.print();

    // window.print(pdf);
  };

  const [expanded, setExpanded] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  // const showMore = (j) => {};
  const ExpandSection = () => {
    setExpanded(true);
    setCollapsed(false);
  };

  const CollapseSection = () => {
    setExpanded(false);
    setCollapsed(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  console.log(FileData);

  const columns = [
    {
      name: "Title",
      selector: "Title",
      sortable: true,
      wrap: true,
    },
    {
      name: "Description",
      selector: "Description",
      cell: (row, j) => (
        <div className="inline-flex">
          {collapsed
            ? row.Description
              ? row.Description.substring(0, 20) + "..."
              : ""
            : ""}
          {expanded ? (row.Description ? row.Description : "") : ""}
        </div>
      ),
      wrap:true,
      sortable:true
    },

    {
      name: "Action",
      sortable: true,
      cell: (row, j) => (
        <div className="inline-flex">
          <Link to="#"
            onClick={() => downLoadFile(row.Filename)}
            className="text-pink-500 bg-transparent border border-solid border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          >
            <img src={download} alt="#"></img>
          </Link> &nbsp;
          <Link to="#"
            onClick={() => Printpdf(row.Filename, j)}
            className="text-pink-500 bg-transparent border border-solid border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          >
            <img src={print} alt="#"></img>
          </Link>

          <br></br>
        </div>
      ),
    },
  ];

  return (
    <div className="main">
      <Header />

      <div className="main-content">
        <HeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <FormSideBar />
              <div className="tab-detail forms">
                <div className="tab-detail-top">
                  <div className="form-index">
                    <img src={forminner} alt="" />
                    Forms
                  </div>
                </div>
                <div className="tab-detail-bottom">
                  <div className="print-form">
                    <ul>
                      <li>
                        <div className="form-print-icon">
                          <img src={f1} alt="" />
                        </div>
                        <div className="form-print-content">
                          <section>
                            <h3>Critical/Unusual Incident</h3>
                            <p>
                              This is an online form that can be filled out to
                              enter the critical / unusual incident form for
                              reporting a critical or unusual event. After a
                              critical / unusual incident has been entered, then
                              you will be able to find it in the profile page to
                              print for your copy.
                            </p>
                          </section>
                          <div className="print-action">
                            <Link to="/form/criticalunusualincidentform">
                              Online Form
                            </Link>{" "}
                            {/* |{" "} */}
                            <span>
                              {/* <CriticalUnusualIncidentFormPrint /> */}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="form-print-icon">
                          <img src={f2} alt="" />
                        </div>
                        <div className="form-print-content">
                          <section>
                            <h3>Immediate Assessments</h3>
                            <p>
                              This is an online form that can be filled out to
                              enter the immediate assessment & admission
                              progress note. After an immediate assessment has
                              been entered, then you will be able to find it on
                              the profile page to print for your copy.
                            </p>
                          </section>
                          <div className="print-action">
                            <Link to="/form/immidiateassesmentform">
                              Online Form
                            </Link>{" "}
                            {/* | <ImmidiateAssesmentFormPrint /> */}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="form-print-icon">
                          <img src={f3} alt="" />
                        </div>
                        <div className="form-print-content">
                          <section>
                            <h3>Log of Daily Events - (Lodes)</h3>
                            <p>
                              This is an online form that can be filled out to
                              enter the log of daily events for the child. After
                              a LODE has been entered, then you will be able to
                              find it in the profile page to print for your
                              copy.
                            </p>
                          </section>
                          <div className="print-action">
                            <Link to="/form/logofdailyevents">Online Form</Link>{" "}
                            {/* | <LogOfDailyEventsFormPrint /> */}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="form-print-icon">
                          <img src={f4} alt="" />
                        </div>
                        <div className="form-print-content">
                          <section>
                            <h3>Mileage Reimbursement</h3>
                            <p>
                              This is an online form that can be filled out to
                              enter the mileage reimbursement form for
                              transporting a child. After a mileage
                              reimbursement has been entered, then you will be
                              able to find it in the profile page to print for
                              your copy. This is only available for Saint
                              Francis children and not other agency children.
                              For other agency children, please use their
                              mileage form if available and if not available,
                              then you may download our handwritten mileage form
                              below.
                            </p>
                          </section>
                          <div className="print-action">
                            <Link to="/forms/milagereimbursment">
                              Online Form
                            </Link>{" "}
                            {/* |<MilageRembursementFormPrint /> */}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="form-print-icon">
                          <img src={f5} alt="" />
                        </div>
                        <div className="form-print-content">
                          <section>
                            <h3>Power of You</h3>
                            <p>
                              This is an online form that can be filled out to
                              enter the name of a prospective foster parent to
                              Saint Francis ministries through our Power Of You
                              program.
                            </p>
                          </section>
                          <div className="print-action">
                            <Link to="/forms/powerofyou">Online Form</Link>
                            {/* |{" "}
                            <PowerOfYouFormPrint /> */}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="tab-detail-top d-form">
                  <div className="form-index">
                    <img src={downloadform} alt="" />
                    Downloadable Forms
                  </div>

                  <Link to="#" className="imp-link">
                    A program that can view PDF files will be required. The most
                    common free program is Acrobat Reader and can be downloaded
                    from http://get.adobe.com/reader/
                  </Link>
                  <div>
                    <select onChange={(e) => handleFilter(e)}>
                      {/* <option>Select</option> */}
                      <option value="ALL_FORM">All Forms</option>
                      <option value="DCF_FORM">DCF Forms</option>
                      <option value="SFM_FORM">SFM Forms</option>
                      <option value="INITIAL_LICENSING_FORM">
                        Initial Licensing Forms
                      </option>
                      <option value="ANNUAL_RENEWAL_LICENSING">
                        Annual Renewal Licensing Forms
                      </option>
                    </select>
                  </div>
                </div>
                <div className="collapse_hide">
                  {collapsed ? (
                    <Link to="#" className="normal" onClick={() => ExpandSection()}>
                      Expand Description
                    </Link>
                  ) : (
                    <Link  to="#" className="normal" onClick={() => CollapseSection()}>
                      Collapse Description
                    </Link>
                  )}
                </div>
                <div className="download-forms">
                  <DataTable
                    columns={columns}
                    data={AllFileData}
                    pagination={true}
                  />
                </div>

                {/* <div className="download-forms">
                  <ul>
                    {AllFileData.map((files) => (
                      <li>
                        <div className="form-print-icon">
                          <img src={pdf} alt="" />
                        </div>
                        <div className="form-print-content">
                          <section>
                            <p>{files.Title}</p>
                            <br></br>
                            <p>{files.Description}</p>
                          </section>
                          <div className="download-pdf-action">
                            <Link
                              to="#"
                              onClick={() => downLoadFile(files.Filename)}
                            >
                              Download Form <img src={dicon} alt="" />
                            </Link>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FormIndex;
