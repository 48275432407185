import Footer from "../../Footer";
import Header from "../../../frontendPages/Header";
import ContentManagementSideBar from "../ContentManagementSideBar/ContentManagementSideBar";
import manageanounce from "../../../images/assets/cm/manageanounce.svg";
import ContentHeaderTopDetail from "../ContentHeaderTopDetail/ContentHeaderTopDetail";
import "../AdoptionContact/AdoptionContact.scss";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import editimage from "../../../images/assets/cm/edit.svg";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import axios from "axios";
import { post, put } from "../../../utils/common";
import LoadSpinner from "../../Loader/LoadSpinner";

function ManageAnnouncement() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [AnnouncementById, setAnnounceMentById] = useState([]);

  const [AnnouncementId, setAnnouncementId] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [addvsupdate, setaddvsupdate] = useState(true);

  const [AnnouncementTypeData, setAnnouncementTypeData] = useState([]);
  const [allAnnouncementData, setAllannouncementData] = useState([]);
  const fetchData = async () => {
    setIsLoading(true);

    let data = await fetch("/api/manageannouncement/index");
    if (!data.ok) {
      return;
    }
    data = await data.json();
    console.log(data);
    setData(data.data);
    setIsLoading(false);
  };

  const addNewAnnouncement = () => {
    setaddvsupdate(true);

    console.log("announcement added");
    setAnnounceMentById([]);
    setAllannouncementData([]);
    window.location.reload(false);
  };

  const deleteAnnouncement = async () => {
    setIsLoading(true);
    let response = await axios.delete(
      `/api/manageannouncement/delete/${encodeURIComponent(AnnouncementId)}`
    );
    if (response) {
      alert("Data deleted Successfully");
      window.location.reload(false);
    }
    window.location.reload(false);
    setIsLoading(false);
  };

  const CancelAnnouncement = () => {
    // alert("Data deleted Successfully")
    window.location.reload(false);
  };

  const storeData = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const data = new FormData();
    if (!AnnouncementById.Picturepath) {
      alert("Select File");
      setIsLoading(false);
      return;
    }
    data.append("file", AnnouncementById.Picturepath);
    data.append("AnnouncementById", JSON.stringify(AnnouncementById));
    axios
      .post("/api/manageannouncement/store/", data, {
        // receive two    parameter endpoint url ,form data
      })
      .then((res) => {
        // then print response status
        alert("Record created successfully !");
        setIsLoading(false);
        window.location.reload(false);
      });
  };

  const [announcementTypeList, setAnnouncementTypeList] = useState([]);

  const fetchAllAnnouncementTypeData = async () => {
    setIsLoading(true);
    let data = await post("/api/manageannouncement/getallannouncementtype");
    // if (!data.ok) {
    //    return;
    // }
    // data = await data.json();
    console.log("sssss", data);
    setAnnouncementTypeList(data.data.data);
    setIsLoading(false);
  };

  const updateAnnoncement = async () => {
    setIsLoading(true);
    const id = AnnouncementId;
    console.log("announcement updated", id);
    await put("/api/manageannouncement/update/" + id, { AnnouncementById });
    alert("Record updated successfully !");
    setIsLoading(false);
    window.location.reload(false);
  };

  const [dataByIdLoadedStatus, setdataByIdLoadedStatus] = useState(false);
  //get all provider by selected  provider tyoe
  const getAnnouncementById = async (e) => {
    setIsLoading(true);
    setaddvsupdate(false);
    const id = AnnouncementId.Id;
    setAnnouncementId(id);
    console.log(id);
    let allannouncementById = await post(
      "/api/manageannouncement/getannouncementbyid",
      { id }
    );
    setAnnounceMentById(allannouncementById.data.data[0]);
    console.log(AnnouncementById);

    const announcementtypeid = AnnouncementById.AnnouncementTypeID;
    if (announcementtypeid) {
      let announcementtypedata = await post(
        "/api/manageannouncement/getannouncementtypebyid",
        { announcementtypeid }
      );
      setAnnouncementTypeData(announcementtypedata.data.data[0]);
      console.log("type", AnnouncementTypeData);
    }

    setdataByIdLoadedStatus(true);
    setIsLoading(false);
  };

  const getAllDataByState = async (s) => {
    setIsLoading(true);
    if (s == "active") {
      let data = await post("/api/manageannouncement/getannouncementbystatus", {
        status: "true",
      });
      setData(data.data.data);
    }
    if (s == "inactive") {
      let data = await post("/api/manageannouncement/getannouncementbystatus", {
        status: "false",
      });
      setData(data.data.data);
    }
    if (s == "all") {
      fetchData();
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    fetchAllAnnouncementTypeData();
  }, []);

  const columns = [
    {
      name: "AnnouncementsID",
      selector: "AnnouncementsID",
      sortable: true,
      wrap: true,
    },
    {
      name: "Title",
      selector: "Title",
      sortable: true,
      wrap: true,
    },
    {
      name: "Description",
      selector: "Description",
      sortable: true,
      wrap: true,
    },
    {
      name: "LinkPath",
      selector: "LinkPath",
      sortable: true,
      wrap: true,
    },

    {
      name: "AnnouncementTypeID",
      selector: "AnnouncementTypeID",
      sortable: true,
      wrap: true,
    },

    {
      name: "Picturepath",
      selector: "Picturepath",
      sortable: true,
      wrap: true,
    },
    {
      name: "IsKansas",
      selector: "IsKansas",
      cell: (row, j) => (j ? "Yes" : "No"),
      sortable: true,
      wrap: true,
    },
    {
      name: "IsNebraska",
      selector: "IsNebraska",
      cell: (row, j) => (j ? "Yes" : "No"),
      sortable: true,
      wrap: true,
    },
    {
      name: "IsCarousel",
      selector: "IsCarousel",
      cell: (row, j) => (j ? "Yes" : "No"),
      sortable: true,
      wrap: true,
    },
    {
      name: "CarouselImagesID",
      selector: "CarouselImagesID",

      sortable: true,
      wrap: true,
    },

    // {
    //    name: 'Action',
    //    sortable: true,
    //    cell: (row, j) => (
    //       <div class="inline-flex">
    //          <button >
    //             <Link to="/add``"><img src={editimage} alt="" /></Link>
    //          </button>
    //          &nbsp;&nbsp;

    //       </div>
    //    ),
    // },
  ];

  return (
    <div className="main">
      <Header />
      <div className="main-content">
        <ContentHeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <ContentManagementSideBar />
              <div className="tab-detail">
                <div className="tab-detail-top children-tabs">
                  <div className="children-section">
                    <img src={manageanounce} alt="" />
                    <div className="pagesubtitle">Manage Announcements</div>
                  </div>
                </div>

                {isLoading ? <LoadSpinner /> : ""}

                <div className="tab-detail-bottom children-data">
                  <form className="children-doc-form manage-announce-form">
                    <div className="form-row mb-30 gap-20 ai-fe">
                      <div className="one-half">
                        <label>Title Name:</label>
                        {/* onChange={e => setSelectedTitleId({ ...titleId, titleId: e.target.value })} */}
                        <select
                          className="mb-0"
                          onChange={(e) =>
                            setAnnouncementId({
                              ...AnnouncementId,
                              Id: e.target.value,
                            })
                          }
                          name="cars"
                          id="cars"
                        >
                          <option value="null">Select</option>
                          {data.map((d) => (
                            <option value={d.AnnouncementsID}>{d.Title}</option>
                          ))}
                        </select>
                      </div>
                      <div className="one-half d-flex gap-20">
                        {/* onClick={handleSelected} */}
                        <Link
                          to="#"
                          onClick={getAnnouncementById}
                          className="normal"
                        >
                          Select
                        </Link>
                        <Link
                          to="#"
                          onClick={addNewAnnouncement}
                          className="normal"
                        >
                          Add New Announcement
                        </Link>
                      </div>
                    </div>
                    <div className="manage-anounce mb-30">
                      <Link
                        to="#"
                        onClick={() => getAllDataByState("all")}
                        className="normal"
                      >
                        All
                      </Link>
                      <Link
                        to="#"
                        onClick={() => getAllDataByState("active")}
                        className="normal"
                      >
                        Active
                      </Link>
                      <Link
                        to="#"
                        onClick={() => getAllDataByState("inactive")}
                        className="normal"
                      >
                        In-Active
                      </Link>
                    </div>
                    <div className="form-row gap-20">
                      <div className="one-third">
                        <label>Announcement Title:</label>
                        <input
                          value={
                            AnnouncementById.Title ? AnnouncementById.Title : ""
                          }
                          onChange={(e) =>
                            setAnnounceMentById({
                              ...AnnouncementById,
                              Title: e.target.value,
                            })
                          }
                          type="text"
                        />
                      </div>
                      {/* dataByIdLoadedStatus */}

                      <div className="one-third">
                        <label>Announcement Type:</label>
                        <select
                          onChange={(e) =>
                            setAnnounceMentById({
                              ...AnnouncementById,
                              AnnouncementTypeID: e.target.value,
                            })
                          }
                        >
                          {dataByIdLoadedStatus ? (
                            announcementTypeList
                              .filter(
                                (ann) =>
                                  ann.AnnouncementTypeID ===
                                  AnnouncementById.AnnouncementTypeID
                              )
                              .map((ann) => (
                                <option selected value={ann.AnnouncementTypeID}>
                                  {ann.AnnouncementTypeName}
                                </option>
                              ))
                          ) : (
                            <option value="0">Please Select</option>
                          )}
                          {announcementTypeList.map((ann) => (
                            <option value={ann.AnnouncementTypeID}>
                              {ann.AnnouncementTypeName}
                            </option>
                          ))}
                          {/* <option>Select</option>
                          {announcementTypeList.map(ann=>(
                           <option value={ann.AnnouncementTypeID}>{ann.AnnouncementTypeName}</option>
                          ))} */}
                        </select>

                        {/* <input onChange={e => setAnnouncementTypeData({ ...AnnouncementTypeData, AnnouncementTypeName: e.target.value })} value={AnnouncementTypeData.AnnouncementTypeName ? AnnouncementTypeData.AnnouncementTypeName : ''} type="text" /> */}
                      </div>
                      <div className="one-third">
                        <label>URL Link:</label>
                        <input
                          onChange={(e) =>
                            setAnnounceMentById({
                              ...AnnouncementById,
                              LinkPath: e.target.value,
                            })
                          }
                          value={
                            AnnouncementById.LinkPath
                              ? AnnouncementById.LinkPath
                              : ""
                          }
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="form-row gap-20">
                      <div className="one-third">
                        <label>
                          Picture:{" "}
                          {dataByIdLoadedStatus
                            ? AnnouncementById.Picturepath
                            : ""}
                        </label>
                        <input
                          type="file"
                          onChange={(e) =>
                            setAnnounceMentById({
                              ...AnnouncementById,
                              Picturepath: e.target.files[0],
                            })
                          }
                        />
                      </div>
                      <div className="one-third">
                        <label>Begin Date *:</label>
                        <input
                          onChange={(e) =>
                            setAnnounceMentById({
                              ...AnnouncementById,
                              BeginDate: e.target.value,
                            })
                          }
                          value={
                            AnnouncementById.BeginDate
                              ? AnnouncementById.BeginDate.split("T")[0]
                              : ""
                          }
                          type="date"
                        />
                      </div>
                      <div className="one-third">
                        <label>End Date *:</label>
                        <input
                          onChange={(e) =>
                            setAnnounceMentById({
                              ...AnnouncementById,
                              EndDate: e.target.value,
                            })
                          }
                          value={
                            AnnouncementById.EndDate
                              ? AnnouncementById.EndDate.split("T")[0]
                              : ""
                          }
                          type="date"
                        />
                      </div>
                    </div>
                    <br />
                    <div className="checkbox-design">
                      <span>
                        <input
                          onChange={(e) =>
                            setAnnounceMentById({
                              ...AnnouncementById,
                              IsKansas: e.target.checked,
                            })
                          }
                          type="checkbox"
                          checked={AnnouncementById.IsKansas ? "checked" : ""}
                          name="Kansas Foster-Family"
                          value="Kansas Foster-Family"
                        />
                        <label for="Kansas-Foster Family"> Kansas</label>
                      </span>
                      <span>
                        <input
                          onChange={(e) =>
                            setAnnounceMentById({
                              ...AnnouncementById,
                              IsNebraska: e.target.checked,
                            })
                          }
                          type="checkbox"
                          checked={AnnouncementById.IsNebraska ? "checked" : ""}
                          name="Kansas Kinship Family"
                          value="Kansas Kinship Family"
                        />
                        <label for="Kansas Kinship Family">Nebraska</label>
                      </span>
                      <span>
                        <input
                          onChange={(e) =>
                            setAnnounceMentById({
                              ...AnnouncementById,
                              IsOklahoma: e.target.checked,
                            })
                          }
                          type="checkbox"
                          checked={AnnouncementById.IsOklahoma ? "checked" : ""}
                          name="Kansas Therapeutic Family"
                          value="Kansas Therapeutic Family"
                        />
                        <label for="Kansas Therapeutic Family">Oklahoma</label>
                      </span>
                    </div>
                    <br />
                    <br />

                    <div className="form-row gap-20">
                      <div className="full-input">
                        <div className="desc-add-to-caraousel">
                          <label>Description::</label>
                          {/* <span>
                                          <input type="checkbox" name="Kansas Therapeutic Family" value="Kansas Therapeutic Family" />
                                          <label for="Kansas Therapeutic Family">Add to Carousel</label>
                                       </span> */}
                        </div>

                        <textarea
                          onChange={(e) =>
                            setAnnounceMentById({
                              ...AnnouncementById,
                              Description: e.target.value,
                            })
                          }
                          row="100"
                          column="20"
                          value={
                            AnnouncementById.Description
                              ? AnnouncementById.Description
                              : ""
                          }
                        ></textarea>
                        <div className="checkbox-design">
                          <span>
                            <input
                              type="checkbox"
                              name="Kansas Therapeutic Family"
                              value="Kansas Therapeutic Family"
                            />
                            <label for="Kansas Therapeutic Family">
                              Add to Carousel
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="form-grey mt-25">
                      <h6 className="small-text">Carousel Addition</h6>
                      <div className="form-row">
                        <div className="one-third">
                          <label>Image Title: *</label>
                          <input type="text" />
                        </div>
                        <div className="one-third">
                          <label>Button Link URL:</label>
                          <input type="text" />
                        </div>
                        <div className="one-third">
                          <label>Upload File:</label>
                          <input type="file" />
                        </div>
                      </div>
                    </div>
                    <div className="req-update-action">
                      {addvsupdate ? (
                        <Link to="#" onClick={storeData} className="normal">
                          Submit
                        </Link>
                      ) : (
                        ""
                      )}
                      {addvsupdate ? (
                        ""
                      ) : (
                        <Link
                          to="#"
                          onClick={updateAnnoncement}
                          className="normal"
                        >
                          Update
                        </Link>
                      )}
                      {/* {AnnouncementId?'': <Link to="#" onClick={deleteAnnouncement} className="close">Delete</Link>} */}
                      <Link
                        to="#"
                        onClick={CancelAnnouncement}
                        className="close"
                      >
                        Cancel
                      </Link>
                    </div>
                  </form>
                </div>
                <DataTable columns={columns} data={data} pagination={true} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ManageAnnouncement;
