import Footer from "../../Footer";
import Header from "../../Header";
import accountactivity from "../../../images/assets/myinfo/accountactivity.svg";
import download from "../../../images/assets/myinfo/download.svg";
import edittwo from "../../../images/assets/myinfo/edittwo.svg";
// import acmanage from '../../images/assets/images/ManageInfo.svg';
import SideBar from "../../sidebar/sidebar";
import "../ActivityLog/ActivityLog.scss";
import HeaderTopDetail from "../../CurrentChildren/HeaderTopDetail/HeaderTopDetail";
import { useEffect, useState } from "react";
import { post } from "../../../utils/common";
import DataTable from "react-data-table-component";

function ActivityLog() {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const providerid = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/accountactivity/activitylog", { providerid });

    console.log("dcsd", data);
    setData(data.data.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      name: "section",
      selector: "section",
      sortable: true,
    },
    {
      name: "Last Updated",
      selector: "LastUpdated",
      cell: (row, j) => row.LastUpdated ? (row.LastUpdated).slice(0, 10) : '',

      sortable: true,
    },
    
    
  ];

  return (
    <div className="main">
      <Header />

      <div className="main-content">
        <HeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <SideBar />
              <div className="tab-detail account-acitivity pl">
                <div className="tab-detail-top">
                  <div className="account-acitivity-top">
                    <img src={accountactivity} alt="" />
                    <div className="pagesubtitle">Account activity</div>
                  </div>
                </div>
                <div className="tab-detail-bottom">
                  <form className="account-acitivity-form">
                  <DataTable columns={columns} data={data} pagination={true} />

                    {/* <table>
                      <tr>
                        <th>Section</th>
                        <th>Last Updated</th>
                        <th></th>
                      </tr>
                      <tr>
                        <td>My Profile</td>
                        <td>00/00/2021</td>
                        <td className="table-act"></td>
                      </tr>
                      <tr>
                        <td>Account Information</td>
                        <td>00/00/2021</td>
                        <td className="table-act"></td>
                      </tr>
                      <tr>
                        <td>Account Management</td>
                        <td>00/00/2021</td>
                        <td className="table-act"></td>
                      </tr>
                      <tr>
                        <td> My Household </td>
                        <td>00/00/2021</td>
                        <td className="table-act"></td>
                      </tr>
                      <tr>
                        <td> Attached Documents </td>
                        <td>00/00/2021</td>
                        <td className="table-act"></td>
                      </tr>
                      <tr>
                         <td> Critical/Unusual Incidents </td>
                        <td>00/00/2021</td>
                        <td className="table-act"></td>
                      </tr>
                      <tr>
                        <td> Immediate Assessment </td>
                        <td>00/00/2021</td>
                        <td className="table-act"></td>
                      </tr>
                      <tr>
                        <td> Least Desired Condition </td>
                        <td>00/00/2021</td>
                        <td className="table-act"></td>
                      </tr>

                      <tr>
                        <td> Level of Care </td>
                        <td>00/00/2021</td>
                        <td className="table-act"></td>
                      </tr>

                      <tr>
                        <td> Lodes </td>
                        <td>00/00/2021</td>
                        <td className="table-act"></td>
                      </tr>
                      <tr>
                        <td>Licensing </td>
                        <td>00/00/2021</td>
                        <td className="table-act"></td>
                      </tr>
                      <tr>
                        <td> License Exception </td>
                        <td>00/00/2021</td>
                        <td className="table-act"></td>
                      </tr>

                      <tr>
                        <td> Reimbursement History </td>
                        <td>00/00/2021</td>
                        <td className="table-act"></td>
                      </tr>

                      <tr>
                        <td> Mileage Reimbursement </td>
                        <td>00/00/2021</td>
                        <td className="table-act"></td>
                      </tr>

                      <tr>
                        <td> Placement Agreement </td>
                        <td>00/00/2021</td>
                        <td className="table-act"></td>
                      </tr>
                      <tr>
                        <td> Preferences </td>
                        <td>00/00/2021</td>
                        <td className="table-act"></td>
                      </tr>
                      <tr>
                        <td> School </td>
                        <td>00/00/2021</td>
                        <td className="table-act"></td>
                      </tr>

                      <tr>
                        <td> Account Activity </td>
                        <td>00/00/2021</td>
                        <td className="table-act"></td>
                      </tr>

                      <tr>
                        <td> Training </td>
                        <td>00/00/2021</td>
                        <td className="table-act"></td>
                      </tr>
                    </table> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ActivityLog;
