import Footer from '../../Footer';
import Header from '../../../frontendPages/Header';
import preme from '../../../images/assets/currentChildren/preme.svg';
import '../../CurrentChildren/ImmidiateAssesmentCurrentChildren/ImmidiateAssesmentCurrentChildren.scss'
import CurrentChildrenSideBar from '../CurrentChildrenSidebar/CurrentChildrenSidebar';
import HeaderTopDetail from '../HeaderTopDetail/HeaderTopDetail';
import { useState } from 'react';
import { post } from '../../../utils/common';
import DataTable from 'react-data-table-component';
import LoadSpinner from '../../Loader/LoadSpinner';
import { useEffect } from 'react';





function PreventiveMeasure() {

    const [data, setData] = useState([])
    // const fetchData = async () => {
    //     //  const id = sessionStorage.getItem('providerSessionId');
    //     let data = await post('/api/preventativemeasure/getpreventativemeasurebyid');
    //     console.log(data)
    //     setData(data.data.data);
    // };

    const [isLoading, setIsLoading] = useState(false);



    const [childrenData, setChildrenData] = useState([]);
    const fetchChildData = async () => {
        const id = sessionStorage.getItem('providerSessionId');
        let data = await post('/api/currentchildren/index', { id });
        setChildrenData(data.data.data);

        
        let children_id = data.data.data[0].ClientID;
        setActiveId(children_id)

        let provider_id = id
        let detailData = await post('/api/currentchildren/preventative-measure', { provider_id, children_id });
        console.log("detailData",detailData)

        setData(detailData.data.data);
    };

    const [activeId, setActiveId] = useState();

    const handleCurrentChildGlobalPreventativeMeasure = async (children_id) => {
        setActiveId(children_id)

        setIsLoading(true);
        sessionStorage.setItem("childrenId", children_id);
        children_id = sessionStorage.getItem("childrenId");
        const provider_id = sessionStorage.getItem('providerSessionId');
        let data = await post('/api/currentchildren/preventative-measure', { provider_id, children_id });
        setData(data.data.data)
        // setselectedChildrenData(data.data.data);
        let detaildata = await post('/api/currentchildren/children-detail', { provider_id, children_id });
        sessionStorage.setItem("current_child", detaildata.data.data[0].ClientName);
        setIsLoading(false);
    }










    useEffect(() => {
        // fetchData();
        fetchChildData();
    }, []);






    const columns = [

        {


            name: 'Preventative Measure',
            selector: 'Preventative_Measures',
           // cell: (row, j) => (row.PreventativeMeasure[0]),
            sortable: true,
        },
        {


            name: 'Begin Date',
            selector: 'Begin_Date',
            // cell: (row, j) => (new Date(row.BeginDate).toLocaleDateString()),
            sortable: true,
        },
        // {


        //     name: 'EndDate',
        //     selector: 'End_Date',
        //     // cell: (row, j) => (new Date(row.EndDate).toLocaleDateString()),
        //     sortable: true,
        // },

        {


            name: 'Notes',
            selector: 'Notes',
            sortable: true,
        },

    ];
    return (
        <div className="main">
            <Header />
            <div className="main-content" >
                <HeaderTopDetail />
                <div className="pagewithsidebar">
                    <div className="container">
                        <div className="pagewithsidebar-inner">
                            <CurrentChildrenSideBar />
                            <div className="tab-detail">
                                <div className="tab-detail-top childprofile-tabs">
                                    <div className="children-section">
                                        <img src={preme} alt="" />
                                        <div className="pagesubtitle">
                                            Preventative Measure
                                        </div>
                                    </div>
                                </div>
                                <div className="children-tab">
                                    {childrenData.map(child => {
                                        return <span className={activeId === child.ClientID ? "active" : ""} onClick={() => handleCurrentChildGlobalPreventativeMeasure(child.ClientID)}>{child.ClientName}</span>
                                    })}
                                    {/* <span className="active">Easton Elliott</span> | <span>Easton Easton</span> | <span>Anna Litchfield</span> */}
                                </div>
                                {isLoading ? (<LoadSpinner />) : ''}

                                <div className="tab-detail-bottom children-data">
                                    <form className="children-doc-form">
                                        {/* <table>
                                            <tr>
                                                <th className="preventative-measure">Preventative Measure</th>
                                                <th className="beign-date">Begin Date</th>
                                                <th>Notes</th>
                                            </tr>
                                            <tr>
                                                <td>Provider Name</td>
                                                <td>mm/dd/yy</td>
                                                <td>Notes</td>
                                            </tr>
                                            <tr>
                                                <td>Provider Name</td>
                                                <td>mm/dd/yy</td>
                                                <td>Notes</td>
                                            </tr>
                                        </table> */}
                                        <DataTable columns={columns} data={data} pagination={true} />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}




export default PreventiveMeasure;
