import Footer from "../../Footer";
import Header from "../../../frontendPages/Header";
import ContentManagementSideBar from "../ContentManagementSideBar/ContentManagementSideBar";
import informationcm from "../../../images/assets/cm/informationcm.svg";
import ContentHeaderTopDetail from "../ContentHeaderTopDetail/ContentHeaderTopDetail";
import "../AdoptionContact/AdoptionContact.scss";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import editimage from "../../../images/assets/cm/edit.svg";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { post, put } from "../../../utils/common";

import { Editor } from "@tinymce/tinymce-react";
import LoadSpinner from "../../Loader/LoadSpinner";
import { rows } from "mssql";

function InformationUpdate() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [titleId, setSelectedTitleId] = useState({
    titleId: "",
  });
  // const handleSelected = () => {
  //     console.log(titleId);

  // }

  const [informationData, setinformationData] = useState([]);
  const [showAddArticle, setShowAddArticle] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const showAddArticleDiv = () => {
    setShowAddArticle(true);
    setinformationData([]);
    
  };
  const hidAddArticleDiv = () => {
    // setShowAddArticle(false);

    setinformationData([]);
  };

  
  const fetchData = async () => {
    
    setIsLoading(true);

    let data = await fetch("/api/informationupdate/index");
    if (!data.ok) {
      return;
    }
    data = await data.json();
    console.log(data);
    setData(data.data);
    setIsLoading(false);
  };

  const [editData, setEditData] = useState([]);
  const [EditDiv, setShowEditDiv] = useState(false);
  const [AddDiv, setShowAddDiv] = useState(true);

  const EditOnClick = (row) => {
    setShowEditDiv(true);
    setShowAddDiv(false);
    setEditData(data[row]);
    setIsLoading(false);
    window.location.href = "#pagesubtitle";
   
  };

  const OnCloseEditDiv = () => {
    setShowEditDiv(false);
    setShowAddDiv(true);
    window.location.reload(false); 
  };

  const UpdateData = async () => {
    setIsLoading(true);
    const id = editData.InformationUpdateID;
    setinformationData(editData);
    console.log(id);
    let data = await put("/api/informationupdate/update/" + id, {
      editData,
    });
    alert("Updated Successfully");
    setIsLoading(false);
    window.location.reload(false);

    console.log(data);
  };

  
  const getDescriptionDataById = async (e) => {
    setIsLoading(true);

    const id = titleId.titleId;
    let informationData = await post(
      "/api/informationupdate/getinformationdatabydescription",
      { id }
    );
    console.log(informationData.data.data[0].UpdateDate);
    setShowAddArticle(true);
    setinformationData(informationData.data.data[0]);
    console.log(informationData);
    setIsLoading(false);
  };

  const updateDescriptionById = async (e) => {
    setIsLoading(true);
    const id = titleId.titleId;
    console.log(id);
    let data = await put("/api/informationupdate/update/" + id, {
      informationData,
    });
    alert("Updated Successfully");
    setIsLoading(false);
    window.location.reload(false);

    console.log(data);
    //\\ let informationData = await post('/api/informationupdate/getinformationdatabydescription', { id });
  };

  const storeData = async (e) => {
    setIsLoading(true);

    e.preventDefault();
    await post("/api/informationupdate/store/", { informationData });
    alert("Record created successfully !");
    setIsLoading(false);
    window.location.reload(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    // {
    //     name: 'InformationUpdateID',
    //     selector: 'InformationUpdateID',
    //     sortable: true,
    // },
    {
      name: "Description",
      selector: "Description",
      sortable: true,
      wrap: true,
    },

    {
      name: "Information",
      selector: "InformationBrief",
      cell: (row, j) => (
        <span dangerouslySetInnerHTML={{ __html: row.InformationBrief }} />
      ),
      // cell: (row, j) =><b>dangerouslySetInnerHTML={{ __html: row.Information }}</b>,
      sortable: true,
      wrap: true,
    },

    // {
    //     name: 'Information',
    //     selector: 'Information',
    //     cell: (row, j) => <span dangerouslySetInnerHTML={{__html: row.Information}} />,

    //     sortable: true,
    //     wrap:true
    // },
    // {
    //     name: 'InformationBrief',
    //     selector: 'InformationBrief',
    //     sortable: true,
    //     wrap:true
    // },
    {
      name: "UpdateDate",
      selector: "UpdateDate",
      cell: (row, j) =>
        row.UpdateDate !== null ? row.UpdateDate.slice(0, 10) : "",

      sortable: true,
      wrap: true,
    },

    {
      name: "Action",
      sortable: true,
      cell: (row, j) => (
        <div class="inline-flex">
          {/* <button> */}
            <Link to="#" onClick={() => EditOnClick(j)}>
              <img src={editimage} alt="NO Image" />
            </Link>
          {/* </button> */}
          &nbsp;&nbsp;
        </div>
      ),
    },
  ];

  return (
    <div className="main">
      <Header />
      <div className="main-content">
        <ContentHeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <ContentManagementSideBar />
              <div className="tab-detail">
                <div className="tab-detail-top children-tabs">
                  <div className="children-section">
                    <img src={informationcm} alt="" />
                    <div className="pagesubtitle" id="pagesubtitle">Information Update</div>
                  </div>
                  {isLoading ? <LoadSpinner /> : ""}
                </div>
                <div className="tab-detail-bottom children-data">
                  {EditDiv ? (
                    <form className="children-doc-form info-update-form">
                      <div className="form-row ai-fe gap-20">
                        {/* <div className="one-half">
                          <label>Update Description:</label>
                          <select
                            onChange={(e) =>
                                editData({
                                ...titleId,
                                titleId: e.target.value,
                              })
                            }
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          >
                            <option value="">All</option>

                            {data.map((d) => (
                              <option value={d.InformationUpdateID}>
                                {d.Description}
                              </option>
                            ))}
                          </select>
                        </div> */}
                        {/* <div className="one-half d-flex gap-20">
                          <Link
                            to="#"
                            onClick={getDescriptionDataById}
                            className="normal"
                          >
                            Select
                          </Link>
                          <Link
                            to="#"
                            onClick={showAddArticleDiv}
                            className="grey-btn"
                          >
                            Add Update
                          </Link>
                        </div> */}
                      </div>

                      {showAddArticle ? (
                        <div className="form-grey mt-25">
                          <h6 className="small-text">Information Update:</h6>
                          <div className="form-row ai-fe">
                            <div className="one-fourth">
                              <label>Date:</label>
                              <input
                                type="date"
                                value={
                                  editData.UpdateDate
                                    ? editData.UpdateDate.split("T")[0]
                                    : ""
                                }
                                onChange={(e) =>
                                  setEditData({
                                    ...editData,
                                    UpdateDate: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="full-input">
                              <label>Description:</label>
                              <input
                                type="text"
                                value={
                                  editData.Description
                                    ? editData.Description
                                    : ""
                                }
                                onChange={(e) =>
                                  setEditData({
                                    ...editData,
                                    Description: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="req-update-textarea">
                            <label>
                              <span>
                                <b>Brief explanation or instructions</b>
                              </span>
                            </label>

                            {/* <Editor apiKey="43knvham046kn3i9brlvbog2h3aahv3yb590n0p7tcmhu9kh"

                              initialValue={ editData.InformationBrief}
                              value={
                                editData.InformationBrief
                                  ? editData.InformationBrief
                                  : ""
                              }
                              onChange={(e) =>
                                setEditData({
                                  ...editData,
                                  editData: e.target.value,
                                })
                              }

                              init={{
                                selector: "textarea",

                                // plugins: 'link image table',
                                contextmenu:
                                  "link image imagetools table spellchecker lists",
                                height: 500,
                                menubar: true,
                                plugins: [
                                  "advlist autolink lists link image",
                                  "charmap print preview anchor help",
                                  "searchreplace visualblocks code",
                                  "insertdatetime media table paste wordcount",
                                  "link image imagetools table spellchecker lists",
                                ],
                                toolbar:
                                  "undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help",
                              }}
                            /> */}

                             <textarea value={editData.InformationBrief ? editData.InformationBrief : ''} onChange={e => setEditData({ ...editData, InformationBrief: e.target.value })}></textarea>


                            <div className="req-update-action">
                              <Link
                                to="#"
                                type="submit"
                                onClick={UpdateData}
                                className="normal"
                              >
                                Submit
                              </Link>

                              <Link
                                to="#"
                                onClick={OnCloseEditDiv}
                                className="close"
                              >
                                Close
                              </Link>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </form>
                  ) : (
                    ""
                  )}

                  {AddDiv ? (
                    <form className="children-doc-form info-update-form">
                      <div className="form-row ai-fe gap-20">
                        <div className="one-half">
                          <label>Update Description:</label>
                          <select
                            onChange={(e) =>
                              setSelectedTitleId({
                                ...titleId,
                                titleId: e.target.value,
                              })
                            }
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          >
                            <option value="">All</option>

                            {data.map((d) => (
                              <option value={d.InformationUpdateID}>
                                {d.Description}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="one-half d-flex gap-20">
                          <Link
                            to="#"
                            onClick={getDescriptionDataById}
                            className="normal"
                          >
                            Select
                          </Link>
                          <Link
                            to="#"
                            onClick={showAddArticleDiv}
                            className="grey-btn"
                          >
                            Add Update
                          </Link>
                        </div>
                      </div>

                      {showAddArticle ? (
                        <div className="form-grey mt-25">
                          <h6 className="small-text">Information Update:</h6>
                          <div className="form-row ai-fe">
                            <div className="one-fourth">
                              <label>Date:</label>
                              <input
                                type="date"
                                value={
                                  informationData.UpdateDate
                                    ? informationData.UpdateDate.split("T")[0]
                                    : ""
                                }
                                onChange={(e) =>
                                  setinformationData({
                                    ...informationData,
                                    UpdateDate: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="full-input">
                              <label>Description:</label>
                              <input
                                type="text"
                                value={
                                  informationData.Description
                                    ? informationData.Description
                                    : ""
                                }
                                onChange={(e) =>
                                  setinformationData({
                                    ...informationData,
                                    Description: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="req-update-textarea">
                            <label>
                              <span>
                                <b>Brief explanation or instructions</b>
                              </span>
                            </label>

                            {/* <Editor
                              apiKey="43knvham046kn3i9brlvbog2h3aahv3yb590n0p7tcmhu9kh"
                              initialValue={
                                informationData.InformationBrief
                                  ? informationData.InformationBrief
                                  : ""
                              }
                              onChange={(e) =>
                                setinformationData({
                                  ...informationData,
                                  InformationBrief: e.target.value,
                                })
                               }
                              init={{
                                selector: "textarea",

                                // plugins: 'link image table',
                                contextmenu:
                                  "link image imagetools table spellchecker lists",
                                height: 500,
                                menubar: true,
                                plugins: [
                                  "advlist autolink lists link image",
                                  "charmap print preview anchor help",
                                  "searchreplace visualblocks code",
                                  "insertdatetime media table paste wordcount",
                                  "link image imagetools table spellchecker lists",
                                ],
                                toolbar:
                                  "undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help",
                              }}
                            /> */}

                             <textarea  name= "textarea" value={informationData.InformationBrief ? informationData.InformationBrief : ''} onChange={e => setinformationData({ ...informationData, InformationBrief: e.target.value })}></textarea> 
                            <div className="req-update-action">
                              {titleId.titleId ? (
                                <Link
                                  to="#"
                                  type="submit"
                                  onClick={updateDescriptionById}
                                  className="normal"
                                >
                                  Submit
                                </Link>
                              ) : (
                                ""
                              )}
                              {titleId.titleId ? (
                                ""
                              ) : (
                                <Link
                                  to="#"
                                  type="submit"
                                  onClick={storeData}
                                  className="normal"
                                >
                                  Submit
                                </Link>
                              )}

                              <Link
                                to="#"
                                onClick={hidAddArticleDiv}
                                className="close"
                              >
                                Close
                              </Link>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </form>
                  ) : (
                    ""
                  )}
                </div>

                <DataTable columns={columns} data={data} pagination={true} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default InformationUpdate;
