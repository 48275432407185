import Footer from "../../Footer";
import Header from "../../Header";
import ContentManagementSideBar from "../ContentManagementSideBar/ContentManagementSideBar";
import manageagencyinner from "../../../images/assets/cm/manageagencyinner.svg";
import search from "../../../images/assets/cm/search.svg";
import edit from "../../../images/assets/cm/edit.svg";
import plus from "../../../images/assets/cm/plus.svg";
import ContentHeaderTopDetail from "../ContentHeaderTopDetail/ContentHeaderTopDetail";
import "../AdoptionContact/AdoptionContact.scss";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import editimage from "../../../images/assets/cm/edit.svg";
import DataTable from "react-data-table-component";
import { post, put } from "../../../utils/common";
import { Editor } from "@tinymce/tinymce-react";
import LoadSpinner from "../../Loader/LoadSpinner";
import axios from "axios";

function ManageCaraouselDocument() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);

    let data = await fetch("/api/managecaraousel/docIndex");
    if (!data.ok) {
      return;
    }
    data = await data.json();
    setData(data.data);
    setIsLoading(false);
  };

  const [AddstoreData,setAddStoreData] = useState([])
  const storeData = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const data = new FormData();
   
    data.append("file", AddstoreData.DocumentName);
    axios
      .post("/api/managecaraousel/docStore/", data, {
        // receive two    parameter endpoint url ,form data
      })
      .then((res) => {
        // then print response status
        alert("Record created successfully !");
        setIsLoading(false);
        window.location.reload(false);
      });
  };

  const deleteData = async (row) => {
    setIsLoading(true);
    const id = data[row].CarouselDocumentID;

    let response = await axios.delete(`/api/managecaraousel/docDelete/${id}`);
    if (response) {
      alert("Deleted");
      setIsLoading(false);
      window.location.reload(false);
    }

    window.location.reload(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      name: "ID",
      selector: "CarouselDocumentID",
      sortable: true,
      wrap: true,
    },
    {
      name: "DocumentName ",
      selector: "DocumentName",
      sortable: true,
      wrap: true,
    },
    {
        name: "Generated Document URL",
        selector: "URL_File",
        cell: (row, j) => "https://sfmfamilies.org/api/managecaraousel/carasouldoc/"+row.DocumentName,
        sortable: true,
        wrap: true,
      },

    {
      name: "Action",
      sortable: true,
      cell: (row, j) => (
        <div class="close">
          {/* <button> */}
            <Link to="#" onClick={() => deleteData(j)}>
              Delete
            </Link>
          {/* </button> */}
          &nbsp;&nbsp;
        </div>
      ),
    },
  ];

  return (
    <div className="main">
      <Header />
      <div className="main-content">
        <ContentHeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <ContentManagementSideBar />
              <div className="tab-detail">
                <div className="tab-detail-top children-tabs">
                  <div className="children-section">
                    <img src={manageagencyinner} alt="" />
                    <div className="pagesubtitle">
                      Manage Carousel Documents
                    </div>
                  </div>
                </div>
                {isLoading ? <LoadSpinner /> : ""}

                <div className="tab-detail-bottom children-data">
                  <label>Upload File</label>
                  <input type="file" onChange={(e) =>
                            setAddStoreData({
                              ...AddstoreData,
                              DocumentName: e.target.files[0],
                            })
                          }></input>
                  <Link to="#" onClick={storeData} className="normal">Submit</Link>
                </div>

                <DataTable columns={columns} data={data} pagination={false} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ManageCaraouselDocument;
