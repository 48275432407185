import Footer from '../../Footer';
import Header from '../../../frontendPages/Header';
import medicationinner from '../../../images/assets/currentChildren/medicationsidebar.svg';
import edit from '../../../images/assets/currentChildren/edit.svg';
import search from '../../../images/assets/currentChildren/search.svg';
import '../../CurrentChildren/ImmidiateAssesmentCurrentChildren/ImmidiateAssesmentCurrentChildren.scss'
import CurrentChildrenSideBar from '../CurrentChildrenSidebar/CurrentChildrenSidebar';
import HeaderTopDetail from '../HeaderTopDetail/HeaderTopDetail';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import LoadSpinner from '../../Loader/LoadSpinner';
import { post } from '../../../utils/common';
import { useEffect } from 'react';
import mail from '../../../images/mail.png';
import plus from '../../../images/assets/cm/plus.svg';




function Medication() {
    const [updateDiv, setShowUpdateDiv] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [updateData, setUpdateData] = useState([])

    const showUpdateDiv = () => {
        setShowUpdateDiv(true)
        setShowAddDiv(false)
    }

    const hideUpdateDiv = () => {
        setShowUpdateDiv(false)
    }


    const [showAddDiv, setShowAddDiv] = useState(false);
    const showAddDivision = () => {
        setShowAddDiv(true)
        setShowUpdateDiv(false)
    }

    const hideAddDivision = () => {
        setShowAddDiv(false)
    }

    const submitUpdate = async() => {
      

       // setIsLoading(true);

        let username = sessionStorage.getItem('username')
        setShowAddDiv(false)
        setShowUpdateDiv(false)
        setUpdateData([])

        await post('/api/medication/add-medication', { username, updateData });
        alert("Request Addition Emailed Sucssesfully ");
       

    }

    const onupdateClick = async () => {
       
       // setIsLoading(true)


        let username = sessionStorage.getItem('username');
        setShowUpdateDiv(false);
        setShowAddDiv(false)
        setUpdateData([])
        await post('/api/medication/update-medication', { username, updateData });
        alert('Request Update  Emailed Successfully !');
        setIsLoading(false)
        //  window.location.reload(false)
        // alert("Submitted Successfully");
        // window.location.reload(false)
    }



    const [childrenData, setChildrenData] = useState([]);
    const fetchChildData = async () => {
        const id = sessionStorage.getItem('providerSessionId');
        let data = await post('/api/currentchildren/index', { id });
        setChildrenData(data.data.data);

        let children_id = data.data.data[0].ClientID;
        setActiveId(children_id)

        let provider_id = id
        let detailData = await post('/api/currentchildren/medication', { provider_id, children_id });
        console.log("detailData", detailData)

        setData(detailData.data.data);
    };

    const [activeId, setActiveId] = useState();

    const [data, setData] = useState([]);
    const [selectedDataForEdit, setSelectedDataForEdit] = useState([])


    const handleCurrentChildGlobalMedication = async (children_id) => {
        setActiveId(children_id)

        setIsLoading(true);
        sessionStorage.setItem("childrenId", children_id);
        children_id = sessionStorage.getItem("childrenId");
        const provider_id = sessionStorage.getItem('providerSessionId');
        let data = await post('/api/currentchildren/medication', { provider_id, children_id });
        // setselectedChildrenData(data.data.data);
        let detaildata = await post('/api/currentchildren/children-detail', { provider_id, children_id });
        sessionStorage.setItem("current_child", detaildata.data.data.length > 0 ? detaildata.data.data[0].ClientName : "Null");
        setData(data.data.data);
        setIsLoading(false);
        //setData(detaildata.data.data)

    }




    // const [data, setData] = useState([]);
    // const fetchData = async () => {

    //     const child_id = sessionStorage.getItem("childrenId")
    //     let data = await post('/api/medication/getallmedicationdata', { child_id });
    //     if (!data.ok) {

    //         return;
    //     }
    //     data = await data.json();
    //     console.log(data);
    //     setData(data.data);
    // };


    const requestEdit = (j) => {
        setShowUpdateDiv(true);
        setShowAddDiv(false)
        setSelectedDataForEdit(data[j])
    }

    useEffect(() => {
        // fetchData();
        fetchChildData();
    }, []);

    const columns = [

        {
            name: 'Medication',
            selector: 'Medication',
            sortable: true,
        },
        {
            name: 'Dosage',
            selector: 'Dosage',

            sortable: true,
        },
        {
            name: 'Frequency',
            selector: 'Frequency',
            sortable: true,
        },
        {
            name: 'Prescribed For',
            selector: 'Prescribed_For',
            sortable: true,
        },
        {
            name: 'Prescribed By',
            selector: 'Prescribed_By',
            sortable: true,
        },
        {
            name: 'Source Of Info',
            selector: 'Source_Of_Info',
            sortable: true,
        },
        {
            name: 'Frequency Notes',
            selector: 'Frequency_Notes',
            // cell: (row, j) => (row.FrequencyNotes ? row.FrequencyNotes : 'Not Found'),
            sortable: true,
        },
        {
            name: 'Action',
            sortable: true,
            cell: (row, j) => (
                <div class="inline-flex">

                    <Link onClick={() => requestEdit(j)}

                        class="text-pink-500 bg-transparent border border-solid border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        to="#"
                    >
                        <img src={mail} alt="edit"></img>
                    </Link>

                </div>
            ),
        },


    ];


    return (
        <div className="main">
            <Header />
            <div className="main-content" >
                <HeaderTopDetail />
                <div className="pagewithsidebar">
                    <div className="container">
                        <div className="pagewithsidebar-inner">
                            <CurrentChildrenSideBar />
                            <div className="tab-detail">
                                <div className="tab-detail-top childprofile-tabs">
                                    <div className="children-section">
                                        <img src={medicationinner} alt="" />
                                        <div className="pagesubtitle">
                                            Medication
                                        </div>
                                    </div>
                                    <Link to="#" onClick={showAddDivision} className="btn-big"><img src={plus} style={{"height": "18px"}} alt="#"/> Request Addition</Link>
                                </div>
                                <div className="children-tab">
                                    {childrenData.map(child => {
                                        return <span className={activeId === child.ClientID ? "active" : ""} onClick={() => handleCurrentChildGlobalMedication(child.ClientID)}>{child.ClientName}</span>
                                    })}
                                    {/* <span className="active">Easton Elliott</span> | <span>Easton Easton</span> | <span>Anna Litchfield</span> */}
                                </div>
                                {isLoading ? (<LoadSpinner />) : ''}

                                <div className="tab-detail-bottom children-data">
                                    <div className="children-doc-form">

                                        {showAddDiv ?

                                            <div>New Medication<br /><br />
                                                <form className="child-case-form">
                                                    <div className="form-col">

                                                        <div class="ot-field">
                                                            <label class="form-label">Medication:</label>
                                                            <input onChange={e => setUpdateData({ ...updateData, Medication: e.target.value })}  type="text"></input>
                                                        </div>
                                                        <div class="ot-field">
                                                            <label class="form-label">Dosage:</label>
                                                            <input onChange={e => setUpdateData({ ...updateData, Dosage: e.target.value })} type="text"></input>
                                                        </div>
                                                    </div>
                                                    <div className="form-col">

                                                        <div class="ot-field">
                                                            <label class="form-label">Frequency:</label>
                                                            <input onChange={e => setUpdateData({ ...updateData, Frequency: e.target.value })} type="text"></input>
                                                        </div>
                                                        <div class="ot-field">
                                                            <label class="form-label">Prescribed for:</label>
                                                            <input onChange={e => setUpdateData({ ...updateData, PrescribedFor: e.target.value })} type="text"></input>
                                                        </div>
                                                    </div>
                                                    <div className="form-col">

                                                        <div class="ot-field">
                                                            <label class="form-label">Prescribed by:</label>
                                                            <input onChange={e => setUpdateData({ ...updateData, PrescribedBy: e.target.value })} type="text"></input>
                                                        </div>
                                                        <div class="ot-field">
                                                            <label class="form-label">Source info:</label>
                                                            <input onChange={e => setUpdateData({ ...updateData, SourceInfo: e.target.value })} type="text"></input>
                                                        </div>
                                                    </div>

                                                    <div className="form-col">

                                                        <div class="ot-field">
                                                            <label class="form-label">Frequency notes:</label>
                                                            <input onChange={e => setUpdateData({ ...updateData, FrequencyNotes: e.target.value })} type="text"></input>
                                                        </div>
                                                        {/* <div class="ot-field">
                                                          <label class="form-label">Source info:</label>
                                                          <input type="text"></input>
                                                      </div> */}
                                                    </div>
                                                </form>

                                                <div className="req-update-action">
                                                    <Link to="#" onClick={hideAddDivision} className="close">close</Link>
                                                    <Link to="#" onClick={submitUpdate} className="normal">Submit</Link>
                                                </div>
                                            </div>

                                            : ''

                                        }



                                        {updateDiv ? <div className="req-update-textarea">
                                            <label><span><b>Request Update For</b>- Medication |
                                                <b> Child: {sessionStorage.getItem("current_child")} | </b>
                                                Medication: {selectedDataForEdit.Medication ? selectedDataForEdit.Medication : ''}    |
                                                Dosage: {selectedDataForEdit.Dosage ? selectedDataForEdit.Dosage : ''} |
                                                Frequency: {selectedDataForEdit.Frequency ? selectedDataForEdit.Frequency : ''}
                                                <br></br>
                                                <br></br>
                                                <b>Description for Update:</b>
                                            </span></label>
                                            <textarea onChange={e => setUpdateData({ ...updateData, description: e.target.value })}></textarea>
                                            <div className="req-update-action">
                                                <Link to="#" onClick={hideUpdateDiv} className="close">close</Link>
                                                <Link to="#" onClick={onupdateClick} className="normal">Submit</Link>
                                            </div>
                                        </div>
                                            : ''}
                                        {/* <table>
                                            <tr>
                                                <th>MEDICATION</th>
                                                <th>DOSAGE</th>
                                                <th>FREQUENCY</th>
                                                <th>PRESCRIBED FOR</th>
                                                <th>PRESCRIBED BY</th>
                                                <th>SOURCE OF INFO</th>
                                                <th>FREQUENCY NOTES</th>
                                                <th className="download-action"></th>
                                            </tr>
                                            <tr>
                                                <td>Co Q 10</td>
                                                <td>100.00MG</td>
                                                <td>Daily - Morning</td>
                                                <td>Meals</td>
                                                <td>Beltran</td>
                                                <td>Foster Parent</td>
                                                <td></td>
                                                <td className="download-icon"><Link to="#"><img src={edit} alt="" /></Link></td>
                                            </tr>
                                            <tr>
                                                <td>Co Q 10</td>
                                                <td>100.00MG</td>
                                                <td>Daily - Morning</td>
                                                <td>Meals</td>
                                                <td>Beltran</td>
                                                <td>Foster Parent</td>
                                                <td></td>
                                                <td className="download-icon"><Link to="#"><img src={edit} alt="" /></Link></td>
                                            </tr>
                                            <tr>
                                                <td>Co Q 10</td>
                                                <td>100.00MG</td>
                                                <td>Daily - Morning</td>
                                                <td>Meals</td>
                                                <td>Beltran</td>
                                                <td>Foster Parent</td>
                                                <td></td>
                                                <td className="download-icon"><Link to="#"><img src={edit} alt="" /></Link></td>
                                            </tr>



                                        </table> */}
                                        <DataTable columns={columns} data={data} pagination={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}




export default Medication;
