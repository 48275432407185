import Footer from "../../Footer";
import Header from "../../Header";
import placementagree from "../../../images/assets/myinfo/placementagree.svg";
import download from "../../../images/assets/myinfo/download.svg";
import edit from "../../../images/assets/myinfo/edit.svg";
import print from "../../../images/assets/myinfo/print.svg";
import search from "../../../images/assets/myinfo/search.svg";
//import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import {BlobServiceClient} from "@azure/storage-blob";


// import acmanage from '../../images/assets/images/ManageInfo.svg';
import SideBar from "../../sidebar/sidebar";
import "../PlacementAgreement/PlacementAgreement.scss";
import HeaderTopDetail from "../../CurrentChildren/HeaderTopDetail/HeaderTopDetail";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { post } from "../../../utils/common";
import DataTable from "react-data-table-component";
import mail from "../../../images/mail.png";
import LoadSpinner from "../../Loader/LoadSpinner";
import { DownloadURL } from "../../common/commonURL";
import plus from '../../../images/assets/cm/plus.svg';
//import { PlacementAgreementV3 } from   "../../../documents/PlacementAgreementV3.pdf";

function PlacementAgreement() {
  const [updateDiv, setShowUpdateDiv] = useState(false);
  const [addDiv, setAddDiv] = useState(false);
  const [selectedDataForEdit, setSelectedDataForEdit] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const showUpdateDiv = () => {
    setShowUpdateDiv(false);
    setAddDiv(true);
  };

  const hideUpdateDiv = () => {
    setShowUpdateDiv(false);
  };
  const hideAdditionDiv = () => {
    setAddDiv(false);
  };

  const viewPlacementAgreement = (row) => {
    sessionStorage.setItem("CLIENT_ID_FOR_PA",data[row].clientid);
    sessionStorage.setItem("CLIENT_NAME_FOR_PA",data[row].Name);
    sessionStorage.setItem("ElecSignPlacementAgreementIDFORPA",data[row].downloaddocumentID);
    sessionStorage.setItem("PAStatus",data[row].Status);
    window.open("/print/placementagreementform", "PRINT", "height=1000,width=1000");
    //window.location.href = "/print/placementagreementform";
  }

  const [data, setData] = useState([]);
  const fetchData = async () => {
    // let data = await fetch('/api/unusualincident/index');
    const id = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/placementagreement/getplacement", { id });
    console.log(data);
    setData(data.data.data);
  };
  const [dataSet, setDataSet] = useState([]);
  const getEsignData = async () =>{
    let ElecSignPlacementAgreementIDFORPA = sessionStorage.getItem("ElecSignPlacementAgreementIDFORPA");
    let data = await post("/api/placementagreement/get-Esign-data", {
    ElecSignPlacementAgreementIDFORPA
  });
  setDataSet(data.data.data[0]);
}




  const requestEditPlacementAgreement = (rowData) => {
    setShowUpdateDiv(true);
    setUpdateData(data[rowData]);
    setSelectedDataForEdit(data[rowData]);
    // console.log(LicenseDataForEdit)
  };

  const requestEditPlacementArchiveAgreement = (rowData) => {};


  
  const clickToDownloadPlacementAgreemment =(row) => {
    sessionStorage.setItem("CLIENT_ID_FOR_PA",data[row].clientid);
    sessionStorage.setItem("CLIENT_NAME_FOR_PA",data[row].Name);
    sessionStorage.setItem("ElecSignPlacementAgreementIDFORPA",data[row].downloaddocumentID);
    sessionStorage.setItem("PAStatus",data[row].Status);
    sessionStorage.setItem("fromButton","true");
    window.location.href="/print/placementagreementDownload";


  }
 
  const clickToPrintPlacementAgreemment =(row)=> {
    sessionStorage.setItem("CLIENT_ID_FOR_PA",data[row].clientid);
    sessionStorage.setItem("CLIENT_NAME_FOR_PA",data[row].Name);
    sessionStorage.setItem("ElecSignPlacementAgreementIDFORPA",data[row].downloaddocumentID);
    sessionStorage.setItem("PAStatus",data[row].Status);
    //sessionStorage.setItem("fromButton","false");
    window.location.href="/print/placementagreementPrint";
  };


  const [updateData, setUpdateData] = useState([]);

  const OnSubmitButtonMailClick = async () => {
    //  setIsLoading(true);
    let username = sessionStorage.getItem("username");
    let ProviderName = sessionStorage.getItem("ProviderName");

    setShowUpdateDiv(false);
    setAddDiv(false);
    setUpdateData([]);
    await post("/api/placementagreement/send-mail-placement-agreement", {
      username,
      updateData,
      ProviderName
    });
    alert("Request Update  Emailed Successfully !");
    setIsLoading(false);
    window.location.reload(false);
  };

  const OnSubmitButtonMailAdditionClick = async () => {
    // setIsLoading(true);
    let username = sessionStorage.getItem("username");
    setShowUpdateDiv(false);
    setAddDiv(false);
    setUpdateData([]);
    await post(
      "/api/placementagreement/send-mail-placement-agreement-addition",
      { username, updateData }
    );
    alert("Request Addition  Emailed Successfully !");
    setIsLoading(false);
    window.location.reload(false);
  };

  const [showArchiveAgreement, setShowArchiveAgreement] = useState(false);
  const [archiveAgreementData, setArchiveAgreementData] = useState([]);
  const [showAgreementData, setAgreementData] = useState(true);

  const fetchArchiveData = async () => {
    // let data = await fetch('/api/unusualincident/index');
    const id = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/placementagreement/archive-agreement", { id });
    console.log(data);
    setArchiveAgreementData(data.data.data);
  };
  
  const showArchivePlacementData = () => {
    setShowArchiveAgreement(true);
    setAgreementData(false);
  };

  const showPlacementData = () => {
    setShowArchiveAgreement(false);
    setAgreementData(true);
  };
  const getDataAsXmlForSign = (row) => {
    sessionStorage.setItem("CLIENT_ID_FOR_PA",data[row].clientid);
    sessionStorage.setItem("CLIENT_NAME_FOR_PA",data[row].Name);
    sessionStorage.setItem("ElecSignPlacementAgreementIDFORPA",data[row].downloaddocumentID);

    // ElecSignPlacementAgreementID
    // console.log(data[row].ClientID);
    window.location.href = "/electronic-sign";

    //  const MileageReimburseMentId = data[row].101422
  };


  const getFchAcknowledge = (row) => {
    if(sessionStorage.RoleInfo === 'FosterHome'  ) {

      alert("Not authorised to acknowledge");
      window.location.href =" /placementagreement";
    }
 else{ 
    sessionStorage.setItem("CLIENT_ID_FOR_PA",data[row].clientid);
    sessionStorage.setItem("CLIENT_NAME_FOR_PA",data[row].Name);
    sessionStorage.setItem("ElecSignPlacementAgreementIDFORPA",data[row].downloaddocumentID);

    // ElecSignPlacementAgreementID
    // console.log(data[row].ClientID);
    window.location.href = "/fch-acknowledgement";

    //  const MileageReimburseMentId = data[row].101422 
  }}
  useEffect(() => {
    fetchData();
    fetchArchiveData();
    getEsignData();
  }, []);

  const columns = [
    {
      name: "Name",
      selector: "Name",
      sortable: true,
      wrap: true,
    },
    {
      name: "Details",
      selector: "Details",
      cell: (row, j) =>
        row.Details.split("<br />")[0] + row.Details.split("<br />")[1],
      sortable: true,
      wrap: true,
    },

    // {
    //   name: "Approved On",
    //   selector: "Details",
    //   cell: (row, j) => row.Details.split("<br />")[1],
    //   sortable: true,
    //   wrap: true,
    // },

    {
      name: "Status",
      selector: "Status",

      sortable: true,
      wrap: true,
    },

    // {

    //     name: 'UnusualIncidentAnonymityTypeID',
    //     selector: 'UnusualIncidentAnonymityTypeID',
    //     sortable: true,
    // },

  

    {
      name: "Action",
      sortable: true,
      cell: (row, j) => (
        <div class="inline-flex">
          <Link
            // onClick={() =>
            //   window.open(
            //     DownloadURL.url +
            //       "ElecSignedPlacementAgreement_" +
            //       row.downloaddocumentID +
            //       ".PDF" +
            //       DownloadURL.tokenString,"popup","width=800,height=600"
            //   )
            // }
            onClick={() => viewPlacementAgreement(j)}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={search} alt="#"></img>
          </Link>
          &nbsp;
          <Link
            to="#"
            onClick={() => requestEditPlacementAgreement(j)}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={mail} alt="tag"></img>
          </Link>
        
          &nbsp;
          <Link
          
            download
            to="#"
            onClick={()=> clickToDownloadPlacementAgreemment(j)}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={download} alt="#"></img>
          </Link>
        
          &nbsp;
          <Link
            
            print
            to="#"
            onClick={()=>clickToPrintPlacementAgreemment(j)}
            class="text-pink-500 bg-transparent border border-solid border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          >
            <img src={print} alt="#"></img>
          </Link>
          &nbsp;
          {row.Status == "Waiting on Home Approval" ? (
            <Link
              onClick={() => getDataAsXmlForSign(j)}
            
              className="btn bg-indigo-500 hover:bg-indigo-600 text-white normal"
            >
              ESign
            </Link>
          ) : (
            ""
          )}
          &nbsp;
          {row.Status == "Waiting on FCH Staff acknowledgement" ? (
            <Link
              onClick={() => getFchAcknowledge(j)}
            
              className="btn bg-indigo-500 hover:bg-indigo-600 text-white normal"
            >
            Staff Ack
            </Link>
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];

  const ArchiveColumnscolumns = [
    {
      name: "Name",
      selector: "Child_Name",
      sortable: true,
      wrap: true,
    },
    {
      name: "Date Range",
      selector: "Date_Range",
      sortable: true,
      wrap: true,
    },

    {
      name: "Provider Rate",
      selector: "Provider_Rate",
      sortable: true,
      wrap: true,
    },
    {
      name: "Action",
      sortable: true,
      cell: (row, j) => (
        <div class="inline-flex">
          &nbsp;
          <Link
            onClick={() =>
              window.open(
                DownloadURL.url + row.Download + DownloadURL.tokenString
              )
            }
            to="#"
            class="text-pink-500 bg-transparent border border-solid border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          >
            <img src={print} alt="#"></img>
          </Link>
          &nbsp;
          <Link
            target="_blank"
            download
            to={{
              pathname:
                DownloadURL.url + row.Download + DownloadURL.tokenString,
            }}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={download} alt="#"></img>
          </Link>
          &nbsp;
          <Link
            to="#"
            onClick={() => requestEditPlacementArchiveAgreement(j)}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={mail} alt="tag"></img>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div className="main">
      <Header />

      <div className="main-content">
        <HeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <SideBar />
              <div className="tab-detail pl">
                <div className="tab-detail-top">
                  <div className="m-profile">
                    <img src={placementagree} alt="" />
                    <div className="pagesubtitle">
                      Placement Agreement
                      <p>
                        If a Placement Agreement is waiting on home approval,
                        please open and proceed to electronically sign and
                        submit.
                      </p>
                      <br></br>
                      {showAgreementData ? (
                        <div onClick={showArchivePlacementData}>
                          <Link to="#" className="close">
                            Placement Agreement Archive
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                      {showArchiveAgreement ? (
                        <div onClick={showPlacementData}>
                          <Link to="#" className="close">
                            Placement Agreement
                          </Link>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <Link to="#" onClick={showUpdateDiv} className="request-btn"><img src={plus} style={{"height": "18px"}} alt="#"/>
                    Request Addition
                  </Link>
                </div>
                {isLoading ? <LoadSpinner /> : ""}
                <div className="tab-detail-bottom">
                  <form className="attach-doc-form">
                    {updateDiv ? (
                      <div className="req-update-textarea">
                        <label>
                          <span>
                            <b>Request Update for</b>-Placement Agreement| Name:{" "}
                            {selectedDataForEdit.Child_Name
                              ? selectedDataForEdit.Child_Name
                              : ""}
                            | Details:{" "}
                            {selectedDataForEdit.Date_Range
                              ? selectedDataForEdit.Date_Range
                              : ""}
                            {/* Status: {selectedDataForEdit.Status? selectedDataForEdit.Status: ''} */}
                            <br></br>
                            <br></br>
                            <b> Description for Update:</b>
                          </span>
                        </label>
                        <textarea
                          onChange={(e) =>
                            setUpdateData({
                              ...updateData,
                              description: e.target.value,
                            })
                          }
                        ></textarea>
                        <div className="req-update-action">
                          <Link
                            to="#"
                            onClick={hideUpdateDiv}
                            className="close"
                          >
                            close
                          </Link>
                          <Link
                            to="#"
                            onClick={OnSubmitButtonMailClick}
                            className="normal"
                          >
                            Submit
                          </Link>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {addDiv ? (
                      <div className="req-update-textarea">
                        <label>
                          <span>
                            <b>Request Addition for</b>-Placement Agreement
                            {/* Name: {selectedDataForEdit.Child_Name ? selectedDataForEdit.Child_Name : ''}|
                                                Details: {selectedDataForEdit.Date_Range ? selectedDataForEdit.Date_Range : ''} */}
                            {/* Status: {selectedDataForEdit.Status? selectedDataForEdit.Status: ''} */}
                            <br></br>
                            <br></br>
                            <b> Description for Addition:</b>
                          </span>
                        </label>
                        <textarea
                          onChange={(e) =>
                            setUpdateData({
                              ...updateData,
                              description: e.target.value,
                            })
                          }
                        ></textarea>
                        <div className="req-update-action">
                          <Link
                            to="#"
                            onClick={hideAdditionDiv}
                            className="close"
                          >
                            close
                          </Link>
                          <Link
                            to="#"
                            onClick={OnSubmitButtonMailAdditionClick}
                            className="normal"
                          >
                            Submit
                          </Link>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {showAgreementData ? (
                      <DataTable
                        columns={columns}
                        data={data}
                        pagination={false}
                      />
                    ) : (
                      ""
                    )}

                    {showArchiveAgreement ? (
                      <DataTable
                        columns={ArchiveColumnscolumns}
                        data={archiveAgreementData}
                        pagination={false}
                      />
                    ) : (
                      ""
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );

  
}


export default PlacementAgreement;
