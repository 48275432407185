import Footer from "../../Footer";
import Header from "../../Header";
import ContentManagementSideBar from "../ContentManagementSideBar/ContentManagementSideBar";
import manageanounce from "../../../images/assets/cm/manageanounce.svg";
import ContentHeaderTopDetail from "../ContentHeaderTopDetail/ContentHeaderTopDetail";
import "../AdoptionContact/AdoptionContact.scss";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import editimage from "../../../images/assets/cm/edit.svg";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import axios from "axios";
import { post, put } from "../../../utils/common";
import LoadSpinner from "../../Loader/LoadSpinner";

function DetailView() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const [selectedDataToDisplay, setSelectedDataToDisplay] = useState([]);

  const showDetail = (row) => {
    setIsLoading(true);
    let data = JSON.parse(sessionStorage.getItem("announcementViewData"));
    console.log(row);
    setSelectedDataToDisplay(data);
    setIsLoading(false);
  };

  useEffect(() => {
    showDetail();
  }, []);

  return (
    <div className="main">
      <Header />
      <div className="main-content">
        <ContentHeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              {/* <ContentManagementSideBar /> */}
              <div className="tab-detail full-page-view">
                <div className="tab-detail-top children-tabs">
                  <div className="children-section">
                    <img src={manageanounce} alt="" />
                    <div className="pagesubtitle">Announcements</div>
                  </div>
                </div>

                {isLoading ? <LoadSpinner /> : ""}

                {/* {showSelectedData ? ( */}
                  <div className="main-page">
                    <h3>Detail View</h3>
                    <div className="announcement-list">
                      <div className="imageSection">
                        <img
                          src={`https://sfmfamilies.org/api/manageannouncement/download/${selectedDataToDisplay.Picturepath}`}
                          width="150px"
                        ></img>
                      </div>
                      <div className="content-section">
                        <h1>Title:{selectedDataToDisplay.Title}</h1>
                        <h3>Description:{selectedDataToDisplay.Description}</h3>
                        <span><button className="normal"><Link to ={{pathname:selectedDataToDisplay.LinkPath}} target="_blank" style={{"color":"white"}}>Click Here to Open...</Link></button></span>
                        {/* <h3>Begin Date: {selectedDataToDisplay.BeginDate}</h3>
                        <h3>Picture Path:{selectedDataToDisplay.Picturepath}</h3>
                        <h3>Link Path:</h3><Link to={{pathname:selectedDataToDisplay.LinkPath}} target="_blank" >{selectedDataToDisplay.LinkPath}</Link> */}

                        
                      </div>
                    </div>
                  </div>
                {/* ) : (
                  ""
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DetailView;
