import Footer from '../../Footer';
import Header from '../../../frontendPages/Header';
import '../../CurrentChildren/Children/Children.scss'
import CurrentChildrenSideBar from '../CurrentChildrenSidebar/CurrentChildrenSidebar';
import HeaderTopDetail from '../HeaderTopDetail/HeaderTopDetail';
import childreninner from '../../../images/assets/currentChildren/childreninner.svg';
import { useState, useEffect } from 'react';
import edit from '../../../images/assets/myinfo/edit.svg';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { post } from '../../../utils/common';
import LoadSpinner from '../../Loader/LoadSpinner';
import mail from '../../../images/mail.png';
import plus from '../../../images/assets/cm/plus.svg';


function Children() {
    const [childrenData, setChildrenData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDataForEdit, setSelectedDataForEdit] = useState([])
    const [data, setData] = useState([])



    const [updateDiv, setShowUpdateDiv] = useState(false);
    const [AddDiv, setShowAddDiv] = useState(false);


    const showUpdateDiv = () => {
        setShowUpdateDiv(true)
        setShowAddDiv(false)
    }

    const hideUpdateDiv = () => {
        setShowUpdateDiv(false)
        setShowAddDiv(false)
    }

    const showAddDiv = () => {
        setShowUpdateDiv(false)
        setShowAddDiv(true)

    }

    const hideAddDiv = () => {
        setShowAddDiv(false)
    }

    const [updateData, setUpdateData] = useState([])

    const onupdateClick = async () => {

        // setIsLoading(true)



        let username = sessionStorage.getItem('username');
        setShowUpdateDiv(false)
        setShowAddDiv(false)
        setUpdateData([])
        await post('/api/currentchildren/update-currentchildren', { username, updateData });
        alert('Request Update  Emailed Successfully !');
        // window.location.reload(false)
        setIsLoading(false)

    }


    const OnAddClick = async () => {

        //setIsLoading(true)



        let username = sessionStorage.getItem('username');
        setShowUpdateDiv(false)
        setShowAddDiv(false)
        setUpdateData([])
        await post('/api/currentchildren/add-email-currentchildren', { username, updateData });
        alert('Request Addition  Emailed Successfully !');
        //  window.location.reload(false)
        setIsLoading(false)

    }


    // const [firstChildId,setFirstChildId] = useState([])

    const fetchData = async () => {
        const id = sessionStorage.getItem('providerSessionId');
        let data = await post('/api/currentchildren/index', { id });
        setChildrenData(data.data.data);

        //load default first child data 
        let children_id = data.data.data[0].ClientID;
        setActiveId(children_id)
        let provider_id = id
        let detailData = await post('/api/currentchildren/children-detail', { provider_id, children_id });
        sessionStorage.setItem("current_child", detailData.data.data[0].ClientName);
        console.log("detailData", detailData)

        setData(detailData.data.data);
        //end default first data


    };

    // console.log("firstChildId",firstChildId.ClientID)


    // const fetchFirstChildData = async () =>{
    //     let children_id = firstChildId.ClientID;
    //     console.log("children_id",children_id)
    //     let provider_id = sessionStorage.getItem("providerSessionId")
    //     let detailData = await post('/api/currentchildren/children-detail', { provider_id, children_id });
    //     console.log("detailData",detailData)
    //     setData(detailData.data.data);

    // }

    const requestEditChildren = (rowData) => {
        setShowUpdateDiv(true)
        setShowAddDiv(false)


        setUpdateData(data[rowData])
        setSelectedDataForEdit(data[rowData])

    }



    const [activeId, setActiveId] = useState();

    const handleCurrentChildGlobal = async (children_id) => {

        setActiveId(children_id)

        //common code 
        sessionStorage.setItem("childrenId", children_id);
        // children_id = sessionStorage.getItem("childrenId");
        const provider_id = sessionStorage.getItem('providerSessionId');
        let detaildata = await post('/api/currentchildren/children-detail', { provider_id, children_id });
        setData(detaildata.data.data);
        sessionStorage.setItem("current_child", detaildata.data.data.length > 0 ? detaildata.data.data[0].ClientName : "Null");


        console.log("Data", data[0])
        setShowUpdateDiv(false)
        //common code end 

        setIsLoading(false);
    }

    console.log(data);

    useEffect(() => {

        fetchData();
        // fetchFirstChildData()
    }, []);

    const columns = [

        {
            name: 'Name',
            selector: 'ClientName',
            sortable: true,
            wrap: true
        },
        {
            name: 'Gender',
            selector: 'Gender',
            sortable: true,
            wrap: true
        },
        {
            name: 'Age',
            selector: 'Age',
            //cell: (row, j) => ((new Date().getUTCFullYear() - new Date(row.DOB).getUTCFullYear())),
            sortable: true,
            wrap: true
        },
        {
            name: 'DOB',
            selector: 'DOB',
            sortable: true,
            wrap: true
        },
        {
            name: 'Race',
            selector: 'Race',
            sortable: true,
            wrap: true
        },
        {
            name: 'Case Manager',
            selector: 'CaseManager',
            cell: (row, j) => (row.Casemanager ? row.Casemanager : 'Not Found'),
            sortable: true,
            wrap: true
        },

        {
            name: 'Office Phone Number',
            selector: 'OfficePhone',
            sortable: true,
            wrap: true
        },
        {
            name: 'Office Address',
            selector: 'OfficeAddress',
            sortable: true,
            wrap: true
        },
        {
            name: 'MCO Provider',
            selector: 'MCO_Provider',
            sortable: true,
            wrap: true
        },
        {
          name: 'Action',
            sortable: true,
           cell: (row, j) => (
                <div class="inline-flex">
                    {sessionStorage.getItem("RoleInfo") != "FosterHome" ?


                        <Link to="#" onClick={() => requestEditChildren(j)}

                            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                        >
                            <img src={mail} alt="tag"></img>
                        </Link>
                        : ''}


                </div >

            ),



        },


    ];


    return (
        <div className="main">
            <Header />
            <div className="main-content" >
                <HeaderTopDetail />
                <div className="pagewithsidebar">
                    <div className="container">
                        <div className="pagewithsidebar-inner">
                            <CurrentChildrenSideBar />
                            <div className="tab-detail pl">
                                <div className="tab-detail-top childprofile-tabs">
                                    <div className="children-section">
                                        <img src={childreninner} alt="" />
                                        <div className="pagesubtitle">
                                            Children
                                        </div>
                                    </div>
                                    {<Link to="#" onClick={showAddDiv} className="btn-big"><img src={plus} style={{"height": "18px"}} alt="#"/>Request Addition</Link>}

                                </div>
                                <div className="children-tab">

                                    {childrenData.map(child => {
                                        return <span className={activeId === child.ClientID ? "active" : ""} onClick={() => handleCurrentChildGlobal(child.ClientID)}>{child.ClientName}</span>
                                    })}


                                </div>

                                {isLoading ? (<LoadSpinner />) : ''}
                                <div className="tab-detail-bottom children-data">
                                    <form className="children-doc-form">
                                        {updateDiv ? <div className="req-update-textarea">
                                            <label><span><b>Request Update for</b>  -  Children
                                                <b> Child: {sessionStorage.getItem("current_child")}  </b>
                                                {/* ClientName: {selectedDataForEdit.ClientName ? selectedDataForEdit.ClientName : ''} */}
                                                {/* ClientID: {selectedDataForEdit.ClientID? selectedDataForEdit.ClientID :''} */}
                                                <br></br>
                                                <br></br>
                                                <b> Description for Update:</b>
                                            </span></label>

                                            <textarea onChange={e => setUpdateData({ ...updateData, description: e.target.value })}></textarea>
                                            <div className="req-update-action">
                                                <Link to="#" onClick={hideUpdateDiv} className="close">close</Link>
                                                <Link to="#" onClick={onupdateClick} className="normal">Submit</Link>
                                            </div>
                                        </div> : ''}
                                        {AddDiv ? <div className="req-update-textarea">
                                            <label><span><b>Request Addition for</b>  -  Children  <br></br>
                                                <br></br>
                                                <b> Description for Additon:</b></span></label>
                                            <textarea onChange={e => setUpdateData({ ...updateData, description: e.target.value })}></textarea>
                                            <div className="req-update-action">
                                                <Link to="#" onClick={hideAddDiv} className="close">close</Link>
                                                <Link to="#" onClick={OnAddClick} className="normal">Submit</Link>
                                            </div>
                                        </div> : ''}
                                        {/* { 
                                        <table>
                                            <tr>
                                                <th>Name</th>
                                                <th>Gender</th>
                                                <th>Age</th>
                                                <th>DOB</th>
                                                <th>Race</th>
                                                <th>Case Manager</th>
                                                <th>Office Phone Number</th>
                                                <th>Office Address</th>
                                                <th>MCO Provider</th>
                                            </tr>
                                            <tr>
                                                <td>Elliott, Easton</td>
                                                <td>Male</td>
                                                <td>1</td>
                                                <td>02/10/2020</td>
                                                <td>Caucasian</td>
                                                <td>Niemeyer, Megan</td>
                                                <td>(316)284-2477</td>
                                                <td>423 SE 10th Street, Newton, KS. 67114</td>
                                                <td>United HealthCare</td>
                                            </tr>
                                        </table>} */}

                                        <DataTable columns={columns} data={data} pagination={false} />

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    );
}




export default Children;
