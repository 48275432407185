import Footer from "../../Footer";
import Header from "../../Header";
import Preferenceinner from "../../../images/assets/myinfo/Preferenceinner.svg";
import download from "../../../images/assets/myinfo/download.svg";
import edit from "../../../images/assets/myinfo/edit.svg";
import print from "../../../images/assets/myinfo/print.svg";

// import acmanage from '../../images/assets/images/ManageInfo.svg';
import SideBar from "../../sidebar/sidebar";
import "../Preference/Prefrence.scss";
import HeaderTopDetail from "../../CurrentChildren/HeaderTopDetail/HeaderTopDetail";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { post } from "../../../utils/common";
import DataTable from "react-data-table-component";
import mail from "../../../images/mail.png";
import LoadSpinner from "../../Loader/LoadSpinner";
import plus from '../../../images/assets/cm/plus.svg';

function Preference() {
  const [updateDiv, setShowUpdateDiv] = useState(false);

  const [PreferenceByProviderId, setPreferenceByProviderId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const showUpdateDiv = () => {
    setShowUpdateDiv(true);
  };

  const hideUpdateDiv = () => {
    setShowUpdateDiv(false);
  };

  const getPreferenceByProviderId = async () => {
    const id = sessionStorage.getItem("providerSessionId");
    let PreferenceData = await post(
      "/api/preference/getpreferencebyproviderid",
      { id }
    );
    console.log(PreferenceData.data.data);
    setPreferenceByProviderId(PreferenceData.data.data);

    console.log(PreferenceByProviderId);
  };

  const [updateData, setUpdateData] = useState([]);

  const onupdateClick = async () => {
    // setIsLoading(true);
    let username = sessionStorage.getItem("username");
    setShowUpdateDiv(false);
    setUpdateData([]);

    await post("/api/preference/update-preference", { username, updateData });
    alert("Request Update  Emailed Successfully !");
    // window.location.reload(false)
    // alert("Submitted Successfully");
    setIsLoading(false);
    window.location.reload(false);
  };

  useEffect(() => {
    // fetchData()
    getPreferenceByProviderId();
  }, []);

  const columns = [
    {
      name: "Age Range",
      selector: (row) => `${row.YoungestAge} to ${row.OldestAge}`,

      sortable: true,
      wrap: true,
      //    style: { 'whiteSpace': 'unset' }
    },
    // {

    //     name: 'Oldest Age',
    //     selector: 'OldestAge',
    //     // cell: (row, j) => (row.Race ? row.Race : 'NULL'),
    //     sortable: true,
    //     wrap:true

    // },
    {
      name: "Race",
      selector: "Race",
      cell: (row, j) => (row.Race ? "true" : "false"),
      sortable: true,
      wrap: true,
    },
    {
      name: "Gender Group",
      selector: "GenderGroup",
      sortable: true,
      wrap: true,
    },

    {
      name: "Capacity",
      selector: "Capacity",
      sortable: true,
      wrap: true,
    },

    {
      name: "Age is Flexible",
      selector: "AgeIsFlexible",
      cell: (row, j) => (row.AgeIsFlexible ? "true" : "false"),
      sortable: true,
      wrap: true,
    },
    {
      name: "Race is Flexible",
      selector: "RaceIsFlexible",
      cell: (row, j) => (row.RaceIsFlexible ? "true" : "false"),
      sortable: true,
      wrap: true,
    },
    {
      name: "Gender is Flexible",
      selector: "GenderIsFlexible",
      cell: (row, j) => (row.GenderIsFlexible ? "true" : "false"),
      sortable: true,
      wrap: true,
    },

    {
      name: "ER Respite only",
      selector: "RespiteOnly",
      cell: (row, j) => (row.RespiteOnly ? "true" : "false"),

      sortable: true,
      wrap: true,
    },
    {
      name: "Will take Respite",
      selector: "WillTakeRespite",
      cell: (row, j) => (row.WillTakeRespite ? "true" : "false"),
      sortable: true,
      wrap: true,
    },
    // {

    //     name: 'On Hold Capacity',
    //     selector: 'Capacity',
    //     sortable: true,
    // },
  ];

  return (
    <div className="main">
      <Header />

      <div className="main-content">
        <HeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <SideBar />
              <div className="tab-detail">
                <div className="tab-detail-top">
                  <div className="m-profile">
                    <img src={Preferenceinner} alt="" />
                    <div className="pagesubtitle">
                      Preferences
                      <p>
                        Below are the preferences you are willing and able to
                        care for.
                      </p>
                    </div>
                  </div>

                  <Link to="#" onClick={showUpdateDiv} className="request-btn"><img src={plus} style={{"height": "18px"}} alt="#"/>
                    Request Update
                  </Link>
                </div>
                {isLoading ? <LoadSpinner /> : ""}
                <div className="tab-detail-bottom">
                  <form className="attach-doc-form">
                    {updateDiv ? (
                      <div className="req-update-textarea">
                        <label>
                          <span>
                            <b>Request update for</b> - Preferences <br></br>
                            <br></br>
                            <b> Description for Update:</b>{" "}
                          </span>
                        </label>
                        <textarea
                          onChange={(e) =>
                            setUpdateData({
                              ...updateData,
                              description: e.target.value,
                            })
                          }
                        ></textarea>
                        <div className="req-update-action">
                          <Link
                            to="#"
                            onClick={hideUpdateDiv}
                            className="close"
                          >
                            close
                          </Link>
                          <Link
                            to="#"
                            onClick={onupdateClick}
                            className="normal"
                          >
                            Submit
                          </Link>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* { 
                                        <table>
                                            <tr>
                                                <th>Youngest Age</th>
                                                <th>Oldest Age</th>
                                                <th>Race</th>
                                                <th>Gender Group</th>
                                                <th>Capacity</th>
                                                <th>Age is Flexible</th>
                                                <th>Race is Flexible</th>
                                                <th>Gender is Flexible</th>
                                                <th>ER Respite only</th>
                                                <th>Will take Respite</th>
                                                <th>On Hold Capacity</th>

                                                


                                            
                                            </tr>
                                            <tr>
                                                <td>12</td>
                                                <td>50</td>
                                                <td>32</td>
                                                <td>white</td>
                                                <td>male</td>
                                                <td>ER Respite</td>
                                                <td>yes</td>
                                                <td>yes</td>
                                                <td>yes</td>
                                                <td>yes</td>
                                               <td>yes</td>
                                                
                                                <td className="table-act"></td>
                                            </tr>
                                            <tr>
                                            <td>12</td>
                                                <td>50</td>
                                                <td>32</td>
                                                <td>white</td>
                                                <td>male</td>
                                                <td>ER Respite</td>
                                                <td>yes</td>
                                                <td>yes</td>
                                                <td>yes</td>
                                                <td>yes</td>
                                                
                                                
                                                <td>yes</td>
                                                
                                                <td className="table-act"></td>
                                            </tr>
                                            <tr>
                                            <td>12</td>
                                                <td>50</td>
                                                <td>32</td>
                                                <td>white</td>
                                                <td>male</td>
                                                <td>ER Respite</td>
                                                <td>yes</td>
                                                <td>yes</td>
                                                <td>yes</td>
                                                <td>yes</td>
                                                <td>yes</td>
                                                
                                                <td className="table-act"></td>
                                            </tr>
                                            <tr>
                                            <td>12</td>
                                                <td>50</td>
                                                <td>32</td>
                                                <td>white</td>
                                                <td>male</td>
                                                <td>ER Respite</td>
                                                <td>yes</td>
                                                <td>yes</td>
                                                <td>yes</td>
                                                <td>yes</td>
                                                <td>yes</td>
                                                
                                                <td className="table-act"></td>
                                            </tr>
                                       


                                        </table> } */}

                    <DataTable
                      columns={columns}
                      data={PreferenceByProviderId}
                      pagination={true}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Preference;
