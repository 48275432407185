import "../../Forms/CriticalUnusualIncidentForm/CriticalUnusualIncidentForm.scss";
import { Link } from "react-router-dom";
import { useState } from "react";
import { post } from "../../../utils/common";
import { useHistory } from "react-router";
import download from "../../../images/assets/myinfo/download.svg";

import mrinner from "../../../images/assets/formimages/mrinner.svg";

import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { useEffect } from "react";
import DataTable from "react-data-table-component";

const customStyles = {
  headCells: {
    style: {
      // fontSize: '20px',
      // fontWeight: '500',
      // textTransform: 'uppercase',
      // paddingLeft: '0 8px',
      // backGroundColor:'green'
    },
  },
};
const ReimbursementFormHistoryView = () => {
  const [data, setData] = useState([]);
  const clickedForDownload = async () => {
    const lodesformdata = JSON.parse(sessionStorage.getItem("rehistorydata"));

    console.log(lodesformdata);
    setData(lodesformdata);
  };

  console.log("dsd", data);
  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();
  const ReHistoryReportColumns = [
    // {
    //   name: "Payment Due Date",
    //   selector: "Payment_Due_Date",
    //   sortable: true,
    //   wrap: true,
    // },
    // {
    //   name: "ProviderLocation",
    //   selector: "Provider_Location",
    //   sortable: true,
    //   wrap: true,
    // },

    {
      name: "Child",
      selector: "Child",
      sortable: true,
      wrap: true,
    },
    {
      name: "Type of Service",
      selector: "Type_of_Service",
      sortable: true,
      wrap: true,
    },
    {
      name: "Notes",
      selector: "Notes",
      sortable: true,
      wrap: true,
    },
    {
      name: "Begin Date",
      selector: "Begin_Date",
      sortable: true,
      wrap: true,
    },

    {
      name: "End Date",
      selector: "End_Date",
      sortable: true,
      wrap: true,
    },
    {
      name: "Units",
      selector: "Units",
      sortable: true,
      wrap: true,
    },
    {
      name: "Rate",
      selector: "Rate",
      sortable: true,
      wrap: true,
    },

    {
      name: "Amount",
      selector: "Amount",
      sortable: true,
      wrap: true,
    },
  ];

  const sample_callback = (
    namef // Callback
  ) => {
    var keyword = namef.toString();
    var trackname = keyword.toString().split("\r\n");
    //  var name =    "<p>some text</p><br /><br /><p>another text</p>"
    var name = trackname[0] + "<p>" + trackname[1] + "</p>" + trackname[2];
    return name;
  };

  useEffect(() => {
    clickedForDownload();
  }, []);

  const contents = data.forEach((item) => {
    // change the title and location key based on your API
    console.log("item", item.Amount);
    return (
      <tr>
        <td>{item.Amount}</td>
        <td>{item.Payment_Due_Date}</td>
      </tr>
    );
  });
  return (
    <div className="main">
      <div className="main-content">
        <h4
          className="title"
          style={{
            "text-align": "center",
            "font-size": "1.45em",
            "font-weight": "bold",
            "margin-top": "50px",
          }}
        >
          Saint Francis Ministries, Inc
        </h4>
        <h5
          className="payment_date increasedFont"
          style={{
            "text-align": "right",
            "margin-right": "25px",
            "font-size": "1em",
            "font-weight": "500",
            "margin-top": "30px",
          }}
        >
          Payment Date {data[0] ? data[0].Payment_Due_Date : ""}
        </h5>
        <br></br>
        <p
          className="payment_date increasedFont"
          style={{
            "text-align": "left",
            width: "250px",
            "margin-left": "30px",
            "font-size": "1em",
            "font-weight": "500",
            "margin-top": "-20px",
          }}
        >
          <br></br>{" "}
          <span
            dangerouslySetInnerHTML={{
              __html: sample_callback(data[0] ? data[0].Provider_Location : ""),
            }}
          />
        </p>

        <div className="container-fluid">
          <div
            className="tab-detail Critical"
            style={{ width: "95%", margin: "0px 30px" }}
          >
            <table cellPadding="0" cellSpacing="0">
              <thead>
                <tr>
                  <th className="increasedFont"
                    style={{
                      border: "1px solid grey",
                      "border-collapse": "collapse",
                      background: "#3075B6",
                      color: "white",
                      "font-size": "1em",
                    }}
                  >
                    Child
                  </th>
                  <th className="increasedFont"
                    style={{
                      border: "1px solid grey",
                      "border-collapse": "collapse",
                      background: "#3075B6",
                      color: "white",
                      "font-size": "1em",
                    }}
                  >
                    Type of Service
                  </th>
                  <th className="increasedFont"
                    style={{
                      border: "1px solid grey",
                      "border-collapse": "collapse",
                      background: "#3075B6",
                      color: "white",
                      "font-size": "1em",
                    }}
                  >
                    Notes
                  </th>
                  <th className="increasedFont"
                    style={{
                      border: "1px solid grey",
                      "border-collapse": "collapse",
                      background: "#3075B6",
                      color: "white",
                      "font-size": "1em",
                    }}
                  >
                    Begin Date
                  </th>
                  <th className="increasedFont"
                    style={{
                      border: "1px solid grey",
                      "border-collapse": "collapse",
                      background: "#3075B6",
                      color: "white",
                      "font-size": "1em",
                    }}
                  >
                    End Date
                  </th>
                  <th
                    style={{
                      border: "1px solid grey",
                      "border-collapse": "collapse",
                      background: "#3075B6",
                      color: "white",
                      "font-size": "1em",
                    }}
                  >
                    Units
                  </th>
                  <th
                    style={{
                      border: "1px solid grey",
                      "border-collapse": "collapse",
                      background: "#3075B6",
                      color: "white",
                      "font-size": "1em",
                    }}
                  >
                    Rate
                  </th>
                  <th
                    style={{
                      border: "1px solid grey",
                      "border-collapse": "collapse",
                      background: "#3075B6",
                      color: "white",
                      "font-size": "1em",
                      "text-align": "right",
                    }}
                  >
                    Amount
                  </th>
                </tr>
              </thead>

              <tbody>
                {data.map((dataOutput) => (
                  <tr>
                    <td className="increasedFont">{dataOutput.Child}</td>
                    <td className="increasedFont">{dataOutput.Type_of_Service}</td>
                    <td className="increasedFont">{dataOutput.Notes}</td>
                    <td className="increasedFont">{dataOutput.Begin_Date}</td>
                    <td className="increasedFont">{dataOutput.End_Date}</td>
                    <td className="increasedFont">{dataOutput.Units}</td>
                    <td className="increasedFont">${dataOutput.Rate}</td>
                    <td className="increasedFont" style={{ "text-align": "right" }}>
                      ${Number(dataOutput.Amount).toFixed(2)}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="increasedFont">
                    Total : $
                    {Number(
                      data.reduce((a, v) => (a = a + v.Amount), 0)
                    ).toFixed(2)}
                  </td>{" "}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <div
            style={{
              "text-align": "left",
              width: "50%",
              "margin-left": "30px",
              bottom: "30px",
              position: "absolute",
            }}
            class="row"
          >
            <div class="row">
              Please review your payments for accuracy.
              <br />
              If there is a discrepancy, contact your case worker
              <br />
              immediately.
            </div>
            <div class="row" style={{ margin: "15px 0px" }}>
              Thank you
            </div>
            <div class="row">
              <span class="text-left">
                Report Generated on {currDate} {currTime}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReimbursementFormHistoryView;
