import Footer from '../../Footer';
import Header from '../../../frontendPages/Header';
import childprofileinner from '../../../images/assets/currentChildren/childprofileinner.svg'
import '../../CurrentChildren/CourtDates/CourtDates.scss'
import HeaderTopDetail from '../HeaderTopDetail/HeaderTopDetail';
import CurrentChildrenSideBar from '../CurrentChildrenSidebar/CurrentChildrenSidebar';
import { useState, useEffect } from 'react';
import edit from '../../../images/assets/myinfo/edit.svg';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import LoadSpinner from '../../Loader/LoadSpinner';
import { post } from '../../../utils/common';
import mail from '../../../images/mail.png';
import plus from '../../../images/assets/cm/plus.svg';




function ChildProfile() {

    const [data, setData] = useState([]);
    const [selectedDataForEdit, setSelectedDataForEdit] = useState([])





    const [childrenData, setChildrenData] = useState([]);
    const [selectedChildrenData, setselectedChildrenData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const fetchChildData = async () => {
        const id = sessionStorage.getItem('providerSessionId');
        let data = await post('/api/currentchildren/index', { id });
        // setChildrenData(data.data.data);
        setChildrenData(data.data.data);
        //load default first child data 

        let children_id = data.data.data[0].ClientID;
        setActiveId(children_id)
        let provider_id = id

        let childid = children_id;
        let providerid = provider_id
        let detailData = await post('/api/childprofile/index', { providerid, childid });
        console.log("detailData", detailData)

        setData(detailData.data.data);
    };





    // const fetchData = async () => {

    //     const childid = sessionStorage.getItem("childrenId")
    //     const providerid = sessionStorage.getItem('providerSessionId');

    //     let data = await post('/api/childprofile/index', { providerid, childid });
    //     if (!data.ok) {

    //         return;
    //     }
    //     data = await data.json();
    //     console.log(data);
    //     setData(data.data.data);
    // };






    const [activeId, setActiveId] = useState();

    const handleCurrentChildProfileGlobal = async (children_id) => {
       

        setActiveId(children_id)
        setIsLoading(true);
        const childid = children_id;

        setAddChildDiv(false)
        setShowUpdateDiv(false)

        // sessionStorage.setItem("childrenId", children_id)
        // setTimeout(10);
        const providerid = sessionStorage.getItem('providerSessionId');

        let provider_id = providerid;

        let data = await post('/api/childprofile/index', { providerid, childid });
        setData(data.data.data)
        // setData(data.data.data);

        // children_id = sessionStorage.getItem("childrenId");
    //   let childid = children_id;
    //     let providerid = provider_id
        let detaildata = await post('/api/currentchildren/children-detail', { provider_id, children_id });
        sessionStorage.setItem("current_child", detaildata.data.data.length > 0 ? detaildata.data.data[0].ClientName : "Null");
        // setData(detaildata.data.data)
        setIsLoading(false);
      
    }
    console.log(data);

    const hideAddChildDiv = () => {
        setAddChildDiv(false)
        setShowUpdateDiv(false)
    }

    const [addChildDiv, setAddChildDiv] = useState(false)

    const onChildAdditionSubmit = async () => {
       
       // setIsLoading(true)

        let username = sessionStorage.getItem('username');
        setShowUpdateDiv(false)
        setAddChildDiv(false)
        setUpdateData([])
        await post('/api/childprofile/update-childprofile-addition', { username, updateData });
        alert('Request Addition  Emailed Successfully !');
        // window.location.reload(false)
      //  setShowUpdateDiv(false)
       // setAddChildDiv(false)
        setIsLoading(false)
    }



    const [updateDiv, setShowUpdateDiv] = useState(false);
    const showUpdateDiv = () => {
        setShowUpdateDiv(false)
        setAddChildDiv(true)

    }


    const hideUpdateDiv = () => {
        setShowUpdateDiv(false)
    }

    const requestEditChildProfile = (rowData) => {
        setShowUpdateDiv(true)
        setAddChildDiv(false)

        setUpdateData(data[rowData])
        setSelectedDataForEdit(data[rowData])
        // console.log(LicenseDataForEdit)
    }


    const [updateData, setUpdateData] = useState([])

    const onupdateClick = async () => {
       
       // setIsLoading(true)
    


        let username = sessionStorage.getItem('username');
        setShowUpdateDiv(false)
        setAddChildDiv(false)
        setUpdateData([])
       

        await post('/api/childprofile/update-childprofile', { username, updateData });
        alert('Request Update  Emailed Successfully !');
       // setShowUpdateDiv(false)
        setIsLoading(false)
      //  setAddChildDiv(false)
        //  window.location.reload(false)
        // alert("Submitted Successfully");
        // window.location.reload(false)
    }

    console.log("child", childrenData)


    useEffect(() => {
        // fetchData();
        fetchChildData()
    }, []);

  

    const columns = [

        {
            name: 'Condition',
            selector: 'Condition',
            // cell: (row, j) => (row.Condition ? row.Condition : 'Not Found'),
            sortable: true,
            wrap: true
        },
        {
            name: 'Begin Date',
            selector: 'Begin_Date',
            cell: (row, j) => row.Begin_Date ? (row.Begin_Date).slice(0, 10) : "NULL",
            // cell: (row, j) => (new Date(row.BeginDate ? row.BeginDate : 'Not Found').toLocaleDateString()),
            sortable: true,
            wrap: true
        },
        {
            name: 'Notification Date',
            selector: 'Notification_Date',
            cell: (row, j) => row.Begin_Date ? (row.Begin_Date).slice(0, 10) : "NULL",


            //   cell: (row, j) => (new Date(row.NotificationDate ? row.NotificationDate : 'Not Found').toLocaleDateString()),
            sortable: true,
            wrap: true
        },
        {
            name: 'Date of Last Occurence',
            selector: 'Date_of_Last_Occurrence',
            cell: (row, j) => row.Begin_Date ? (row.Begin_Date).slice(0, 10) : "NULL",

            // cell: (row, j) => (new Date(row.LastOccurrence ? row.LastOccurrence : 'Not Found').toLocaleDateString()),
            sortable: true,
            wrap: true
        },
        {
            name: 'EndDate',
            selector: 'End_Date',

            // cell: (row, j) => (new Date(row.EndDate).toLocaleDateString()),
            sortable: true,
            wrap: true
        },
        {
            name: 'Frequency & Risk Explanation',
            selector: 'Frequency_Risk_Explanation',
            //cell: (row, j) => (row.FrequencyType + ' & ' + row.Risk),
            sortable: true,
            wrap: true
        },
        {
            name: 'Action',
            sortable: true,
            cell: (row, j) => (
                <div class="inline-flex">

                    <Link to="#" onClick={() => requestEditChildProfile(j)}

                        className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                    >
                        <img src={mail} alt="tag"></img>
                    </Link>

                </div >
            ),
        },



    ];




    return (


        <div className="main">
            <Header />
            <div className="main-content" >
                <HeaderTopDetail />
                <div className="pagewithsidebar">
                    <div className="container">
                        <div className="pagewithsidebar-inner">
                            <CurrentChildrenSideBar />
                            <div className="tab-detail">
                                <div className="tab-detail-top childprofile-tabs">
                                    <div className="children-section">
                                        <img src={childprofileinner} alt="" />
                                        <div className="pagesubtitle">
                                            Child’s Profile
                                        </div>
                                    </div>
                                    <Link to="#" onClick={showUpdateDiv}><img src={plus} style={{"height": "18px"}} alt="#"/>Request Addition</Link>
                                </div>
                                <div className="children-tab">
                                    {childrenData.map(child => {
                                        return <span className={activeId === child.ClientID ? "active" : ""} onClick={() => handleCurrentChildProfileGlobal(child.ClientID)}>{child.ClientName}</span>
                                    })}
                                    {/* <span className="active">Easton Elliott</span> | <span>Easton Easton</span> | <span>Anna Litchfield</span> */}
                                </div>
                                {isLoading ? (<LoadSpinner />) : ''}

                                <div className="tab-detail-bottom children-data">
                                    <form className="children-doc-form">
                                        {addChildDiv ? <div className="req-update-textarea">
                                            <label><span><b>Request Addition For</b>- Child's profile
                                                <br></br>
                                                <br></br>
                                                <b> Description for Additon:</b></span></label>
                                            <textarea onChange={e => setUpdateData({ ...updateData, description: e.target.value })}></textarea>
                                            <div className="req-update-action">
                                                <Link to="#" onClick={hideAddChildDiv} className="close">close</Link>
                                                <Link to="#" onClick={onChildAdditionSubmit} className="normal">Submit</Link>
                                            </div>
                                        </div> : ''}

                                        {updateDiv ? <div className="req-update-textarea">
                                            <label><span><b>Request update for</b> - Child's profile |
                                                <b> Child: {sessionStorage.getItem("current_child")} | </b>
                                                Condition: {selectedDataForEdit.Condition ? selectedDataForEdit.Condition : ''}    |
                                                Begin Date: {selectedDataForEdit.Begin_Date ? (selectedDataForEdit.Begin_Date).slice(0, 10) : ''} |
                                                Notification Date: {selectedDataForEdit.Notification_Date ? (selectedDataForEdit.Notification_Date).slice(0, 10) : ''}
                                                <br></br>
                                                <br></br>
                                                <b>Description For Update:</b>

                                            </span></label>
                                            <textarea onChange={e => setUpdateData({ ...updateData, description: e.target.value })}></textarea>
                                            <div className="req-update-action">
                                                <Link to="#" onClick={hideUpdateDiv} className="close">close</Link>
                                                <Link to="#" onClick={onupdateClick} className="normal">Submit</Link>
                                            </div>
                                        </div> : ''}


                                        {/* <table>
                                            <tr>
                                                <th>Condition</th>
                                                <th className="date-th">Begin Date</th>
                                                <th className="noti-th">Notification Date</th>
                                                <th className="noti-th">Date of Last Occurence</th>
                                                <th className="time-th">End Date</th>
                                                <th>Frequency & Risk Explanation</th>
                                            </tr>
                                            <tr>
                                                <td>Aggression, physical</td>
                                                <td>02/10/2017</td>
                                                <td>mm/dd/yyyy</td>
                                                <td>mm/dd/yyyy</td>
                                                <td>mm/dd/yyyy</td>
                                                <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since read more...</td>
                                            </tr>
                                        </table>
                                         */}

                                        <DataTable columns={columns} data={data} pagination={true} width="20%" style={{ flex: 3 }} />

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}




export default ChildProfile;
