import Footer from "../../Footer";
import Header from "../../../frontendPages/Header";
import CurrentChildrenSideBar from "../CurrentChildrenSidebar/CurrentChildrenSidebar";
import HeaderTopDetail from "../HeaderTopDetail/HeaderTopDetail";
import childcaseinner from "../../../images/assets/currentChildren/childcaseinner.svg";
import search from "../../../images/assets/currentChildren/search.svg";
import download from "../../../images/assets/currentChildren/download.svg";
import "../../CurrentChildren/CaseTeam/CaseTeam.scss";
import { post } from "../../../utils/common";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import edit from "../../../images/assets/myinfo/edit.svg";
import LoadSpinner from "../../Loader/LoadSpinner";
import mail from "../../../images/mail.png";

function CaseTeam() {
  const [data, setData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [childrenData, setChildrenData] = useState([]);
  const [selectedChildrenData, setselectedChildrenData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDataForEdit, setSelectedDataForEdit] = useState([]);
  const fetchChildData = async () => {
    const id = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/currentchildren/index", { id });
    setChildrenData(data.data.data);
    //load default first child data
    let children_id = data.data.data[0].ClientID;
    setActiveId(children_id);
    let provider_id = id;
    let detailData = await post("/api/currentchildren/caseteam", {
      provider_id,
      children_id,
    });
    console.log("detailData", detailData);

    setData(detailData.data.data);
  };

  const [activeId, setActiveId] = useState();

  const handleCurrentChildGlobal = async (children_id) => {
    setShowUpdateDiv(false);
    setupdateDivWithEmailMember(false);
    setUpdateData([]);

    setActiveId(children_id);

    setIsLoading(true);
    sessionStorage.setItem("childrenId", children_id);
    // setTimeout(10);
    const provider_id = sessionStorage.getItem("providerSessionId");

    let data = await post("/api/currentchildren/caseteam", {
      provider_id,
      children_id,
    });
    // setselectedChildrenData(data.data.data);
    setIsLoading(false);
    setData(data.data.data);

    children_id = sessionStorage.getItem("childrenId");
    let detaildata = await post("/api/currentchildren/children-detail", {
      provider_id,
      children_id,
    });
    sessionStorage.setItem("current_child", detaildata.data.data[0].ClientName);
    //common code end
  };

  const [attachDocument, setAttachDocument] = useState([]);
  const [updateDiv, setShowUpdateDiv] = useState(false);

  const hideUpdateDiv = () => {
    setShowUpdateDiv(false);
    setupdateDivWithEmailMember(false);
    // setShowAddDiv(false)
  };
  const hideUpdateDivTeam = () => {
    setupdateDivWithEmailMember(false);
  };

  const onupdateClick = async () => {
    setupdateDivWithEmailMember(false);

    //setIsLoading(true);
    let username = sessionStorage.getItem("username");
    setShowUpdateDiv(false);
    setupdateDivWithEmailMember(false);
    setUpdateData([]);

    await post("/api/currentchildren/update-email-caseteam", {
      username,
      updateData,
    });
    alert("Request Update  Emailed Successfully !");
    // window.location.reload(false)
    setIsLoading(false);
    // alert("Submitted Successfully");
    //  window.location.reload(false)
  };
  const onupdateClickList = async () => {
    //setShowUpdateDiv(false)
    // setupdateDivWithEmailMember(false)
    // setIsLoading(true);
    let username = sessionStorage.getItem("username");
    setShowUpdateDiv(false);
    setupdateDivWithEmailMember(false);
    setUpdateData([]);
    await post("/api/currentchildren/update-email-caseteam-list", {
      username,
      updateData,
    });
    alert("Request Update  Emailed Successfully !");
    // window.location.reload(false)
    setIsLoading(false);
    // alert("Submitted Successfully");
    // window.location.reload(false)
  };

  const [updateDivWithEmailMember, setupdateDivWithEmailMember] =
    useState(false);

  const requestEditCaseTeam = (rowData) => {
    setShowUpdateDiv(true);
    setupdateDivWithEmailMember(false);
    setUpdateData(data[rowData]);
    setSelectedDataForEdit(data[rowData]);

    // setShowUpdateDiv(false)
    // console.log(LicenseDataForEdit)
  };
  const requestEditCaseTeamlist = (rowData) => {
    setupdateDivWithEmailMember(true);
    setShowUpdateDiv(false);
    setUpdateData(data[rowData]);
    setSelectedDataForEdit(data[rowData]);
    if (data[rowData].EmailSend == "") {
      alert("Email Not Found");
      setupdateDivWithEmailMember(false);
    }
    // setShowUpdateDiv(false)
    // console.log(LicenseDataForEdit)
  };

  useEffect(() => {
    // fetchData();
    fetchChildData();
  }, []);

  const columns = [
    {
      name: "Person Type",
      selector: "Person_Type",
      sortable: true,
    },
    {
      name: "Name",
      selector: "Name",
      sortable: true,
    },
    {
      name: "Begin Date",
      selector: "Begin_Date",
      cell: (row, j) => row.Begin_Date.slice(0, 10),

      // cell: (row, j) => ((new Date().getUTCFullYear() - new Date(row.DOB).getUTCFullYear())),
      sortable: true,
    },
    {
      name: "End Date",
      selector: "End_Date",
      cell: (row, j) => (row.End_Date ? row.End_Date.slice(0, 10) : ""),
      sortable: true,
    },
    {
      name: "Phone",
      selector: "Work_Phone",
      sortable: true,
    },

    {
      name: "Action",
      sortable: true,
      cell: (row, j) => (
        <div class="inline-flex">
          <Link
            to="#"
            onClick={() => requestEditCaseTeam(j)}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={mail} alt="tag"></img>
          </Link>
          <br></br>&nbsp;
          <Link
            to="#"
            onClick={() => requestEditCaseTeamlist(j)}
            className="normal btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <i class="fa fa-envelope"></i>
            {/* <img src={edit} alt="tag"></img> */}
          </Link>

          {/* //  */}
        </div>
      ),
    },
  ];

  return (
    <div className="main">
      <Header />
      <div className="main-content">
        <HeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <CurrentChildrenSideBar />
              <div className="tab-detail">
                <div className="tab-detail-top children-tabs">
                  <div className="children-section">
                    <img src={childcaseinner} alt="" />
                    <div className="pagesubtitle">Case Team</div>
                  </div>
                  <div className="children-tab">
                    {/* <span className="active">Easton Elliott</span> | <span>Easton Easton</span> | <span>Anna Litchfield</span> */}
                    {childrenData.map((child) => {
                      return (
                        <span
                          className={
                            activeId === child.ClientID ? "active" : ""
                          }
                          onClick={() =>
                            handleCurrentChildGlobal(child.ClientID)
                          }
                        >
                          {child.ClientName}
                        </span>
                      );
                    })}
                  </div>
                </div>
                {isLoading ? <LoadSpinner /> : ""}

                <div className="tab-detail-bottom children-attachment">
                  {updateDivWithEmailMember ? (
                    <div className="req-update-textarea">
                      Email Team Member:{selectedDataForEdit.Name}
                      <br></br>
                      {/* <label><span><b>Request Update for</b>-CaseTeam (Description for Update) |
                                            <b> Child: {sessionStorage.getItem("current_child")} | </b>
                                                Name: {selectedDataForEdit.Person_Type ? selectedDataForEdit.Person_Type : ''}    |
                                                Person Type: {selectedDataForEdit.Name ? selectedDataForEdit.Name : ''}    |
                                                Begin Date: {selectedDataForEdit.Begin_Date ? (selectedDataForEdit.Begin_Date).slice(0,10): ''}  */}
                      {/* Details: {selectedDataForEdit.Details? selectedDataForEdit.Details :''} */}
                      {/* Status: {selectedDataForEdit.Status? selectedDataForEdit.Status: ''} */}
                      {/* </span></label> */}
                      <textarea
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            description: e.target.value,
                          })
                        }
                      ></textarea>
                      <div className="req-update-action">
                        <Link
                          to="#"
                          onClick={hideUpdateDivTeam}
                          className="close"
                        >
                          close
                        </Link>
                        <Link to="#" onClick={onupdateClick} className="normal">
                          Submit
                        </Link>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {updateDiv ? (
                    <div className="req-update-textarea">
                      {/* Email Team Member:{selectedDataForEdit.Name}
                                        <br></br> */}
                      <label>
                        <span>
                          <b>Request Update for</b>-CaseTeam |
                          <b>
                            {" "}
                            Child: {sessionStorage.getItem(
                              "current_child"
                            )} |{" "}
                          </b>
                          Name:{" "}
                          {selectedDataForEdit.Name
                            ? selectedDataForEdit.Name
                            : ""}{" "}
                          | Person Type:{" "}
                          {selectedDataForEdit.Person_Type
                            ? selectedDataForEdit.Person_Type
                            : ""}{" "}
                          | Begin Date:{" "}
                          {selectedDataForEdit.Begin_Date
                            ? selectedDataForEdit.Begin_Date.slice(0, 10)
                            : ""}
                          <br></br>
                          <br></br>
                          <b> Description for Update:</b>
                          {/* Details: {selectedDataForEdit.Details? selectedDataForEdit.Details :''} */}
                          {/* Status: {selectedDataForEdit.Status? selectedDataForEdit.Status: ''} */}
                        </span>
                      </label>
                      <textarea
                        onChange={(e) =>
                          setUpdateData({
                            ...updateData,
                            description: e.target.value,
                          })
                        }
                      ></textarea>
                      <div className="req-update-action">
                        <Link to="#" onClick={hideUpdateDiv} className="close">
                          close
                        </Link>
                        <Link
                          to="#"
                          onClick={onupdateClickList}
                          className="normal"
                        >
                          Submit
                        </Link>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* <form className="child-case-form">
                                       

                                        <div className="form-col">
                                            <h3>Case Manager</h3>
                                            <div class="ot-field">
                                                <label class="form-label">Name:</label>
                                                <input type="text" placeholder="Last Name,First Name" readOnly></input>
                                            </div>
                                            <div class="ot-field">
                                                <label class="form-label">Phone:</label>
                                                <input type="text"  placeholder="000000000" readOnly></input>
                                            </div>
                                        </div>
                                        <div className="form-col">
                                            <h3>Family Support Worker</h3>
                                            <div class="ot-field">
                                                <label class="form-label">Name:</label>
                                                <input type="text" placeholder="Last Name,First Name"  readOnly></input>
                                            </div>
                                            <div class="ot-field">
                                                <label class="form-label">Phone:</label>
                                                <input type="text" placeholder="000000000"  readOnly></input>
                                            </div>
                                        </div>
                                        <div className="form-col">
                                            <h3>Supervisor</h3>
                                            <div class="ot-field">
                                                <label class="form-label">Name:</label>
                                                <input type="text" placeholder="Last Name,First Name"  readOnly></input>
                                            </div>
                                            <div class="ot-field">
                                                <label class="form-label">Phone:</label>
                                                <input type="text" placeholder="000000000"  readOnly></input>


                                            </div>
                                            
                                        </div>



                                    </form> */}
                  <DataTable columns={columns} data={data} pagination={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CaseTeam;
