import Footer from '../../Footer';
import Header from '../../../frontendPages/Header';
import ContentManagementSideBar from '../ContentManagementSideBar/ContentManagementSideBar';
import mtinner from '../../../images/assets/cm/mtinner.svg';
import ContentHeaderTopDetail from '../ContentHeaderTopDetail/ContentHeaderTopDetail';
import "../AdoptionContact/AdoptionContact.scss"
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { post } from '../../../utils/common';
import LoadSpinner from '../../Loader/LoadSpinner';




function ManageTraining() {


    const [showtTraning, setTrainingDivison] = useState(true);

    const [showCertificate, setCertificateDivision] = useState(false);

    const [showModule, setShowModuleDivision] = useState(false)

    const [showVersion, setShowVersionDivision] = useState(false)

    const [showZQuestion, setShowZQuestion] = useState(false)

    const [showAnswer, setShowAnswerDivision] = useState(false)

    const [showAllowed, setShowAllowedGroupDiv] = useState(false)

    const [showDownloadCEUButton, setshowDownloadCEUButton] = useState(true)

    const [isLoading, setIsLoading] = useState(false);



    const showTrainingDiv = () => {
        setTrainingDivison(true)
        setCertificateDivision(false)
        setShowModuleDivision(false)
        setShowVersionDivision(false)
        setShowZQuestion(false)
        setShowAnswerDivision(false)
        setShowAllowedGroupDiv(false)




    }

    const showCertificateDiv = () => {
        setTrainingDivison(false)
        setCertificateDivision(true)
        setShowModuleDivision(false)
        setShowVersionDivision(false)
        setShowZQuestion(false)
        setShowAnswerDivision(false)
        setShowAllowedGroupDiv(false)
    }
    const showModuleDiv = () => {
        setTrainingDivison(false)
        setCertificateDivision(false)
        setShowModuleDivision(true)
        setShowVersionDivision(false)
        setShowZQuestion(false)
        setShowAnswerDivision(false)
        setShowAllowedGroupDiv(false)
    }
    const showVersionDiv = () => {
        setTrainingDivison(false)
        setCertificateDivision(false)
        setShowModuleDivision(false)
        setShowVersionDivision(true)
        setShowZQuestion(false)
        setShowAnswerDivision(false)
        setShowAllowedGroupDiv(false)
    }

    const showzQuestionDiv = () => {

        setTrainingDivison(false)
        setCertificateDivision(false)
        setShowModuleDivision(false)
        setShowVersionDivision(false)
        setShowZQuestion(true)
        setShowAnswerDivision(false)
        setShowAllowedGroupDiv(false)

    }

    const showAnswerDiv = () => {
        setTrainingDivison(false)
        setCertificateDivision(false)
        setShowModuleDivision(false)
        setShowVersionDivision(false)
        setShowZQuestion(false)
        setShowAnswerDivision(true)
        setShowAllowedGroupDiv(false)
    }

    const showAllowedGroupDiv = () => {
        setTrainingDivison(false)
        setCertificateDivision(false)
        setShowModuleDivision(false)
        setShowVersionDivision(false)
        setShowZQuestion(false)
        setShowAnswerDivision(false)
        setShowAllowedGroupDiv(true)
    }


    const removeDownloadButtonandHandle = () => {
        setshowDownloadCEUButton(false)

    }

    const cancelAction = () => {
        window.location.reload(false)
    }

    const AddCertificate = () => {
        alert("Added");
        window.location.reload(false);
    }

    const AddAllowedGroup = () => {
        alert("Added");
        window.location.reload(false);
    }

    const AddAnswer = () => {
        alert("Added");
        window.location.reload(false);
    }

    const AddQuestion = () => {
        alert("Added");
        window.location.reload(false);
    }

    const AddVersion = () => {
        alert("Added");
        window.location.reload(false);
    }

    const AddModule = () => {
        alert("Added");
        window.location.reload(false);
    }

    const AddTraining = () => {
        alert("Added");
        window.location.reload(false);
    }



    const [CourseData, setCourseData] = useState([])
    const [CertificateData, setCertificateData] = useState([])
    const [ModuleDropdownData, setModuleDropdownData] = useState([])
    const [VersionDropdownData, setVersionDropdownData] = useState([])
    const [QuestionDropdownData, setQuestionDropdownData] = useState([])
    const [AnswerDropdownData, setAnswerDropdownData] = useState([])
    // const [ModuleDropdownData, setDropdownModuleData] = useState([])

    const fetchData = async () => {
        setIsLoading(true)

        let data = await post('/api/training/getcourseandcertificate');

        console.log(data)

        // if (!data.ok) {
        //     return;
        // }
        // data = await data.json();
        console.log("d", data);
        setCertificateData(data.data.CertificateData);
        setCourseData(data.data.CourseData)
        setModuleDropdownData(data.data.ModuleDropdownData)
        setVersionDropdownData(data.data.VersionDropdownData)
        setQuestionDropdownData(data.data.QuestionDropdownData)
        setAnswerDropdownData(data.data.AnswerDropdownData)
        setIsLoading(false)
    };
    const [ModuleData, setModuleData] = useState([])
    const [TraningData, setTraningData] = useState([])
    const [VersionData, setVersionData] = useState([])
    const [QuestionData, setQuestionData] = useState([])
    const [AnswerData, setAnswerData] = useState([])
    const [TraningAdminData, setTraningAdminData] = useState([])
    const fetchModuleData = async () => {
        setIsLoading(true)

        let data = await post('/api/training/gettraninigmoduledata');

        console.log(data)

        // if (!data.ok) {
        //     return;
        // }
        data = await data.json();
        console.log("d", data);

        setModuleData(data.data.ModuleData);
        setTraningData(data.data.TraningData)
        setVersionData(data.data.VersionData)
        setQuestionData(data.data.QuestionData)
        setAnswerData(data.data.AnswerData)
        setTraningAdminData(data.data.TraningAdminData)
        setIsLoading(false)
    };



    useEffect(() => {
        fetchData();
        fetchModuleData();
    }, []);



    console.log("fdfd", CourseData)



    return (

        <div className="main">
            <Header />
            <div className="main-content" >
                <ContentHeaderTopDetail />
                <div className="pagewithsidebar">
                    <div className="container">
                        <div className="pagewithsidebar-inner">
                            <ContentManagementSideBar />
                            <div className="tab-detail">
                                <div className="tab-detail-top children-tabs">
                                    <div className="children-section">
                                        <img src={mtinner} alt="#" />
                                        <div className="pagesubtitle">
                                            Manage Training
                                        </div>
                                    </div>
                                    <div className="mc-tabs">
                                        <form action="/action_page.php">
                                            <div className="manage-corousel-tab">
                                                <span className={showtTraning ? "active" : "#"} onClick={showTrainingDiv}>Training Courses</span> |
                                                <span className={showModule ? "active" : "#"} onClick={showModuleDiv}>Modules</span> |
                                                <span className={showVersion ? "active" : "#"} onClick={showVersionDiv}> Version</span> |
                                                <span className={showZQuestion ? "active" : "#"} onClick={showzQuestionDiv}>Questions</span> |
                                                <span className={showAnswer ? "active" : "#"} onClick={showAnswerDiv}>Answers</span> |
                                                <span className={showAllowed ? "active" : "#"} onClick={showAllowedGroupDiv}>Allowed Groups</span> |
                                                <span className={showCertificate ? "active" : "#"} onClick={showCertificateDiv}>Certificates</span>
                                            </div>
                                        </form>
                                    </div>
                                    {isLoading ? <LoadSpinner /> : ""}
                                </div>

                                <div className="tab-detail-bottom children-data">
                                    {showtTraning ?
                                        <div className="training-courses">
                                            <form className="children-doc-form manage-parent-form">
                                                <div className="form-row mb-30 gap-20 ai-fe">
                                                    <div className="one-half">
                                                        <label>Course Title:</label>
                                                        <select className="mb-0" name="cars" id="cars">
                                                            {CourseData.map(course => {
                                                                return <option value={course.FamilyMattersTrainingID}>{course.Course}</option>

                                                            })}

                                                        </select>
                                                    </div>
                                                    <div className="one-fourth d-flex gap-20">
                                                        <Link to="#" onClick={removeDownloadButtonandHandle} className="normal">Add Training</Link>
                                                        <Link to="#" onClick={cancelAction} className="close">Cancel</Link>
                                                    </div>
                                                </div>
                                                <div className="form-grey mt-25">
                                                    <h6 className="small-text">Editing an Existing Record</h6>
                                                    <div className="form-row ai-c">
                                                        <div className="one-third">
                                                            <label>Title:</label>
                                                            <input type="text" />
                                                        </div>

                                                        <div className="one-third">
                                                            <label>CEU’s:</label>
                                                            <input type="text" />
                                                        </div>
                                                        {/* disabledCursor class greyed out and disable  */}
                                                        {showDownloadCEUButton ?
                                                            <div>
                                                                <Link to="#" onClick={(event) => event.preventDefault()} className="normal">Download Course Guide</Link>
                                                            </div>
                                                            : ''}

                                                    </div>
                                                    <div className="form-row ai-c">
                                                        <div className="one-half">
                                                            <label>File:</label>
                                                            <input type="file" />
                                                        </div>
                                                    </div>
                                                    {/* //old api key jphrniw6bc9b2ihcnrwlbfu4invez6q9vuagtsq4blooa29q */}
                                                    <div className="req-update-textarea pl-0 pr-0 ">
                                                        <label>Description:</label>
                                                        {/* <textarea></textarea> */}
                                                        <Editor apiKey="43knvham046kn3i9brlvbog2h3aahv3yb590n0p7tcmhu9kh"

                                                            initialValue={"  "

                                                            }
                                                            // value="<p>Initial content</p>"
                                                            init={{
                                                                selector: 'textarea',
                                                                // plugins: 'link image table',
                                                                contextmenu: 'link image imagetools table spellchecker lists',
                                                                height: 500,
                                                                menubar: true,
                                                                plugins: [
                                                                    'advlist autolink lists link image',
                                                                    'charmap print preview anchor help',
                                                                    'searchreplace visualblocks code',
                                                                    'insertdatetime media table paste wordcount',
                                                                    'link image imagetools table spellchecker lists'
                                                                ],
                                                                toolbar:
                                                                    'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help'
                                                            }}

                                                        />
                                                        <div className="form-row mt-25">
                                                            <div className="one-third">
                                                                <label>Certificate:</label>
                                                                <select className="mb-0" name="cars" id="cars">
                                                                    {CertificateData.map(certificate => {
                                                                        return <option value={certificate.RecordID}>{certificate.Title}</option>

                                                                    })}
                                                                </select>
                                                            </div>
                                                            <label>Training Certificate with the Vice President of Clinical Services signature.</label>
                                                        </div>
                                                        <div className="req-update-action">
                                                            <Link to="#" onClick={AddTraining} className="normal">Update Training Course</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        : ''}

                                    {/* ==================  Modules tab ====================*/}
                                    {showModule ?
                                        <div className="Modules">
                                            <form className="children-doc-form manage-parent-form">
                                                <div className="form-row mb-30 gap-20 ai-fe">
                                                    <div className="one-half">
                                                        <label>Course Title:</label>
                                                        <select className="mb-0" name="cars" id="cars">
                                                            {CourseData.map(course => {
                                                                return <option value={course.FamilyMattersTrainingID}>{course.Course}</option>

                                                            })}

                                                        </select>
                                                    </div>
                                                    <div className="one-fourth d-flex gap-20">
                                                        <Link to="#" className="normal">Add Training</Link>
                                                        <Link to="#" onClick={cancelAction} className="close">Cancel</Link>
                                                    </div>
                                                </div>
                                                <div className="form-grey mt-25">
                                                    <h6 className="small-text">Sort the Modules:</h6>
                                                    <div className="req-update-textarea pl-0 pr-0 ">
                                                        <textarea></textarea>
                                                        <br></br>


                                                        <div className="form-row ai-c">
                                                            <div className="full-input">
                                                                <label>Title:</label>
                                                                <input type="text" />
                                                            </div>
                                                        </div>
                                                        <Editor apiKey="43knvham046kn3i9brlvbog2h3aahv3yb590n0p7tcmhu9kh"
                                                            initialValue=""
                                                            init={{
                                                                selector: 'textarea',
                                                                // plugins: 'link image table',
                                                                contextmenu: 'link image imagetools table spellchecker lists',
                                                                height: 500,
                                                                menubar: true,
                                                                plugins: [
                                                                    'advlist autolink lists link image',
                                                                    'charmap print preview anchor help',
                                                                    'searchreplace visualblocks code',
                                                                    'insertdatetime media table paste wordcount',
                                                                    'link image imagetools table spellchecker lists'
                                                                ],
                                                                toolbar:
                                                                    'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help'
                                                            }}

                                                        />

                                                    </div>

                                                    <div className="req-update-textarea pl-0 pr-0 ">
                                                        <label>Instruction:</label>
                                                        <textarea></textarea>
                                                        <label className="mt-25">Reading:</label>
                                                        <Editor apiKey="43knvham046kn3i9brlvbog2h3aahv3yb590n0p7tcmhu9kh"
                                                            initialValue="<p>Initial content</p>"
                                                            init={{
                                                                selector: 'textarea',
                                                                // plugins: 'link image table',
                                                                contextmenu: 'link image imagetools table spellchecker lists',
                                                                height: 500,
                                                                menubar: true,
                                                                plugins: [
                                                                    'advlist autolink lists link image',
                                                                    'charmap print preview anchor help',
                                                                    'searchreplace visualblocks code',
                                                                    'insertdatetime media table paste wordcount',
                                                                    'link image imagetools table spellchecker lists'
                                                                ],
                                                                toolbar:
                                                                    'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help'
                                                            }}

                                                        />
                                                        {/* <textarea></textarea> */}
                                                        <div className="req-update-action">
                                                            <Link to="#" onClick={AddModule} className="normal">Update Module</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        : ''}

                                    {/* ==================  version tab ====================*/}
                                    {showVersion ?
                                        <div className="Version">
                                            <form className="children-doc-form manage-parent-form">
                                                <div className="form-row mb-30 gap-20 ai-fe">
                                                    <div className="one-half">
                                                        <label>Course Title:</label>
                                                        <select className="mb-0" name="cars" id="cars">
                                                            {CourseData.map(course => {
                                                                return <option value={course.FamilyMattersTrainingID}>{course.Course}</option>

                                                            })}

                                                        </select>
                                                    </div>
                                                    <div className="one-fourth d-flex gap-20">
                                                        <Link to="#" className="normal">Add Training</Link>
                                                        <Link to="#" onClick={cancelAction} className="close">Cancel</Link>
                                                    </div>
                                                </div>
                                                <div className="form-grey mt-25">
                                                    {/* <h6 className="small-text">Editing an Existing Record</h6> */}
                                                    <div className="form-row ai-c">
                                                        <div className="one-third">
                                                            <label>Title:</label>
                                                            <input type="text" />
                                                        </div>
                                                        <div className="one-third">
                                                            <label>CEU’s:</label>
                                                            <input type="text" />
                                                        </div>
                                                        <Link to="#" onClick={(event) => event.preventDefault()} className="normal">Download Course Guide</Link>
                                                    </div>
                                                    <div className="checkbox-design">
                                                        <span>
                                                            <input type="checkbox" name="Post Test" value="Post Test" />
                                                            <label for="Post Test"> Post Test</label>
                                                        </span>
                                                        <span>
                                                            <input type="checkbox" name="Practice Test" value="Practice Test" />
                                                            <label for="Practice Test">Practice Test</label>
                                                        </span>
                                                        <span>
                                                            <input type="checkbox" name="Pre-Test" value="Pre-Test" />
                                                            <label for="Pre-Test">Pre-Test</label>
                                                        </span>
                                                    </div>
                                                    <div className="form-row mt-25">
                                                        <div className="one-fourth">
                                                            <label>Begin Date:</label>
                                                            <input type="date" />
                                                        </div>
                                                        <div className="one-fourth">
                                                            <label>End Date:</label>
                                                            <input type="date" />
                                                        </div>
                                                    </div>
                                                    <div className="req-update-textarea pl-0 pr-0 ">
                                                        <label>Description:</label>
                                                        <textarea></textarea>
                                                        <label className="mt-25">Directions:</label>
                                                        <textarea></textarea>
                                                        <div className="req-update-action">
                                                            <Link to="#" onClick={AddVersion} className="normal">Update Version</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        : ''}
                                    {/* ==================  Question tab ====================*/}

                                    {showZQuestion ?
                                        <div className="question">
                                            <form className="children-doc-form manage-parent-form">
                                                <div className="form-row mb-30 gap-20 ai-fe">
                                                    <div className="one-half">
                                                        <label>Course Title:</label>
                                                        <select className="mb-0" name="cars" id="cars">
                                                            {CourseData.map(course => {
                                                                return <option value={course.FamilyMattersTrainingID}>{course.Course}</option>

                                                            })}

                                                        </select>
                                                    </div>
                                                    <div className="one-fourth d-flex gap-20">
                                                        <Link to="#" className="normal">Add Training</Link>
                                                        <Link to="#" onClick={cancelAction} className="close">Cancel</Link>
                                                    </div>
                                                </div>
                                                <div className="form-grey mt-25">
                                                    <h6 className="small-text">Sort the modules</h6>
                                                    <div className="req-update-textarea pt-0 pl-0 pr-0 ">
                                                        <textarea></textarea>
                                                    </div>
                                                    <div className="form-row ai-c">
                                                        <div className="full-input">
                                                            <label>Question:</label>
                                                            <input type="text" />
                                                        </div>
                                                        <div className="full-input">
                                                            <label>Clarification:</label>
                                                            <input type="text" />
                                                        </div>
                                                    </div>
                                                    <div className="form-row ai-c">
                                                        <div className="one-fourth">
                                                            <label>Begin Date:</label>
                                                            <input type="date" />
                                                        </div>
                                                        <div className="one-fourth">
                                                            <label>End Date:</label>
                                                            <input type="date" />
                                                        </div>
                                                    </div>
                                                    <div className="req-update-textarea pl-0 pr-0 ">
                                                        <div className="req-update-action">
                                                            <Link to="#" onClick={AddQuestion} className="normal">Update Question</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                        : ''}
                                    {/* ==================  Answer tab ====================*/}
                                    {showAnswer ?
                                        <div className="question">
                                            <form className="children-doc-form manage-parent-form">
                                                <div className="form-row mb-30 gap-20 ai-fe">
                                                    <div className="one-half">
                                                        <label>Course Title:</label>
                                                        <select className="mb-0" name="cars" id="cars">
                                                            {CourseData.map(course => {
                                                                return <option value={course.FamilyMattersTrainingID}>{course.Course}</option>

                                                            })}

                                                        </select>
                                                    </div>
                                                    <div className="one-fourth d-flex gap-20">
                                                        <Link to="#" className="normal">Add Training</Link>
                                                        <Link to="#" onClick={cancelAction} className="close">Cancel</Link>
                                                    </div>
                                                </div>
                                                <div className="form-grey mt-25">
                                                    <h6 className="small-text">Answer Choice:</h6>

                                                    <div className="form-row ai-c">
                                                        <div className="one-fourth">
                                                            <select className="mb-0" name="cars" id="cars">
                                                                <option value="volvo">Select</option>
                                                                {/* <option value="saab">Saab</option>
                                                                <option value="mercedes">Mercedes</option>
                                                                <option value="audi">Audi</option> */}
                                                            </select>
                                                        </div>
                                                        <div className="one-half d-flex gap-20">
                                                            <Link to="#" className="normal">Add Answer</Link>
                                                            <Link to="#" onClick={cancelAction} className="close">Cancel</Link>
                                                        </div>
                                                    </div>
                                                    <label className="mt-25">Short Answer :</label>
                                                    <div className="checkbox-design ">
                                                        <span>
                                                            <input type="checkbox" name="Kansas Foster Family" value="Kansas Foster Family" />
                                                            <label for="Kansas Foster Family">True</label>
                                                        </span>
                                                        <span>
                                                            <input type="checkbox" name="Kansas Kinship Family" value="Kansas Kinship Family" />
                                                            <label for="Kansas Kinship Family">False</label>
                                                        </span>
                                                        <span className="q-result">Correct Answer</span>
                                                    </div>
                                                    <div className="req-update-textarea pl-0 pr-0 ">
                                                        <label>Note :</label>
                                                        <textarea></textarea>
                                                        <div className="req-update-action">
                                                            <Link to="#" onClick={AddAnswer} className="normal">Update Answer</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        : ''}

                                    {/* ==================  Answer tab ====================*/}
                                    {showAllowed ?
                                        <div className="allowed-groups">
                                            <form className="children-doc-form manage-parent-form">
                                                <div className="form-row mb-30 gap-20 ai-fe">
                                                    <div className="one-half">
                                                        <label>Course Title:</label>
                                                        <select className="mb-0" name="cars" id="cars">
                                                            {CourseData.map(course => {
                                                                return <option value={course.FamilyMattersTrainingID}>{course.Course}</option>

                                                            })}

                                                        </select>
                                                    </div>
                                                    <div className="one-fourth d-flex gap-20">
                                                        <Link to="#" className="normal">Add Training</Link>
                                                        <Link to="#" onClick={cancelAction} className="close">Cancel</Link>
                                                    </div>
                                                </div>
                                                <div className="form-grey mt-25">
                                                    <h6 className="small-text">Set the training groups</h6>
                                                    <div className="form-row mt-25 note-text">
                                                        <p>Please check all the groups that you want to give access to this training.</p>
                                                        <ul>
                                                            <li>Foster Home - Kansas Foster Homes</li>
                                                            <li>Kinship Home - Kansas Kinship Homes</li>
                                                            <li>Therapeutic Home - Oklahoma Therapeutic Homes</li>
                                                            <li>Prospective Foster Home - Kansas Prospective Homes</li>
                                                        </ul>
                                                    </div>
                                                    <div className="checkbox-design fr-w">
                                                        <span>
                                                            <input type="checkbox" name="Foster Home" value="Foster Home" />
                                                            <label for="Foster Home"> Foster Home</label>
                                                        </span>
                                                        <span>
                                                            <input type="checkbox" name="Kinship Home" value="Kinship Home" />
                                                            <label for="Kinship Home">Kinship Home</label>
                                                        </span>
                                                        <span>
                                                            <input type="checkbox" name="Prospective Foster Home" value="Prospective Foster Home" />
                                                            <label for="Prospective Foster Home">Prospective Foster Home</label>
                                                        </span>
                                                        <span>
                                                            <input type="checkbox" name="Nebraska Foster Home" value="Nebraska Foster Home" />
                                                            <label for="Nebraska Foster Home">Nebraska  Home</label>
                                                        </span>
                                                        <span>
                                                            <input type="checkbox" name="Kansas Foster Family" value="Kansas Foster Family" />
                                                            <label for="Kansas Foster Family">Kansas Foster Home</label>
                                                        </span>

                                                        {/* Foster home, Kinship home, 
                                                        Prospective foster home, Nebraska home, 
                                                        Kansas foster home, Kansas kinship home,
                                                         Inquiry home FCH KS, Nebraska Foster home, 
                                                         Nebraska kinship home */}
                                                        <span>
                                                            <input type="checkbox" name="Kansas Kinship Family" value="Kansas Kinship Family" />
                                                            <label for="Kansas Kinship Family">Kansas Kinship Home</label>
                                                        </span>
                                                        {/* <span>
                                                            <input type="checkbox" name="Home" value="Home" />
                                                            <label for="Home">Home</label>
                                                        </span> */}
                                                        <span>
                                                            <input type="checkbox" name="Inquiry Home FCH KS" value="Inquiry Home FCH KS" />
                                                            <label for="Inquiry Home FCH KS">Inquiry Home FCH KS</label>
                                                        </span>
                                                        <span>
                                                            <input type="checkbox" name="Nebraska Kinship" value="Nebraska Kinship" />
                                                            <label for="Nebraska Kinship">Nebraska Foster Home</label>
                                                        </span>
                                                        <span>
                                                            <input type="checkbox" name="Nebraska Kinship" value="Nebraska Kinship" />
                                                            <label for="Nebraska Kinship">Nebraska kinship home</label>
                                                        </span>
                                                    </div>
                                                    <div className="form-row mt-25">
                                                        <div className="one-fourth">
                                                            <label>Begin Date:</label>
                                                            <input type="date" />
                                                        </div>
                                                        <div className="one-fourth">
                                                            <label>End Date:</label>
                                                            <input type="date" />
                                                        </div>
                                                    </div>
                                                    <div className="req-update-textarea pl-0 pr-0 ">
                                                        <div className="req-update-action">
                                                            <Link to="#" onClick={cancelAction} className="close">Cancel Changes</Link>
                                                            <Link to="#" onClick={AddAllowedGroup} className="normal">Update Allowed Groups</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        : ''}
                                    {/* ==================  Certificates tab ====================*/}
                                    {showCertificate ?
                                        <div className="allowed-groups">
                                            <form className="children-doc-form manage-parent-form">
                                                <div className="form-row mb-30 gap-20 ai-fe">
                                                    <div className="one-half">
                                                        <label>Course Title:</label>
                                                        <select className="mb-0" name="cars" id="cars">
                                                            {CourseData.map(course => {
                                                                return <option value={course.FamilyMattersTrainingID}>{course.Course}</option>

                                                            })}

                                                        </select>
                                                    </div>
                                                    <div className="one-fourth d-flex gap-20">
                                                        <Link to="#" onClick={AddCertificate} className="normal">Add Training</Link>
                                                        <Link to="#" onClick={cancelAction} className="close">Cancel</Link>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        : ''}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}




export default ManageTraining;
