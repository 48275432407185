
import Footer from '../../Footer';
import Header from '../../../frontendPages/Header';
import ContentManagementSideBar from '../ContentManagementSideBar/ContentManagementSideBar';
import mninner from '../../../images/assets/cm/mninner.svg';
import ContentHeaderTopDetail from '../ContentHeaderTopDetail/ContentHeaderTopDetail';
import "../AdoptionContact/AdoptionContact.scss"
import editimage from '../../../images/assets/cm/edit.svg';
import contactimage from '../../../images/assets/cm/contact.svg';
import trashimage from '../../../images/assets/cm/trash.svg';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import LoadSpinner from '../../Loader/LoadSpinner';



function ManageNewsLetter() {

   const [data, setData] = useState([]);

   const [webNewsLetterDataById, setwebNewsLetterDataById] = useState([])

   const [webnewsLetterTypeData, setWebNewsLetterTypeData] = useState([])
   const [isLoading, setIsLoading] = useState(false);




   const fetchData = async () => {
      setIsLoading(true)

      let data = await fetch('/api/managenewsletter/index');
      if (!data.ok) {

         return;
      }
      data = await data.json();
      console.log(data);
      setData(data.data);
      setIsLoading(false)
   };


   const fetchAllWebNewsLetterTypeData = async () => {
      setIsLoading(true)

      let data = await fetch('/api/managenewsletter/getwebnewslettertype');
      if (!data.ok) {

         return;
      }
      let webnewslettertype = await data.json();
      console.log("type",data);
      setWebNewsLetterTypeData(webnewslettertype.data);
      setIsLoading(false)
   };


   const submitUpdate = () => {
      setIsLoading(true);
      alert("Submitted ");
      window.location.reload(false)
      setIsLoading(false)

  }

   useState(() => {
      fetchData();
      fetchAllWebNewsLetterTypeData()
   }, []);

   const history = useHistory();






   const columns = [
      {
         name: 'WebNewsletterID',
         selector: 'WebNewsletterID',
         sortable: true,
      },
      {
         name: 'WebNewsletterTypeID',
         selector: 'WebNewsletterTypeID',
         sortable: true,
      },
      {
         name: 'WebNewsletterEdition',
         selector: 'WebNewsletterEdition',
         sortable: true,
      },
      {
         name: 'WebNewsletterPath',
         selector: 'WebNewsletterPath',
         sortable: true,
      },
      {
         name: 'WebNewsletterPath',
         selector: 'WebNewsletterPath',
         sortable: true,
      },


      {
         name: 'Action',
         sortable: true,
         cell: (row, j) => (
            <div class="inline-flex">
               <button onClick={() => history.push(`/contentmanage/adoptioncontact/add?id=${encodeURIComponent(row.AdoptionContactID)}`)}>
                  <Link to="/add``"><img src={editimage} alt="" /></Link>
               </button>
               &nbsp;&nbsp;
               <button>
                  <Link to="#"><img src={contactimage} alt="" /></Link>
               </button>
               &nbsp;&nbsp;
               <button >
                  <Link to="#"><img src={trashimage} alt="" /></Link>
               </button>
            </div>
         ),
      },
   ];
   return (

      <div className="main">
         <Header />
         <div className="main-content" >
            <ContentHeaderTopDetail />
            <div className="pagewithsidebar">
               <div className="container">
                  <div className="pagewithsidebar-inner">
                     <ContentManagementSideBar />
                     <div className="tab-detail">
                        <div className="tab-detail-top children-tabs">
                           <div className="children-section">
                              <img src={mninner} alt="" />
                              <div className="pagesubtitle">
                                 Manage Newsletters
                              </div>
                           </div>
                           {isLoading ? <LoadSpinner /> : ""}
                        </div>
                        <div className="tab-detail-bottom children-data">
                           <form className="children-doc-form manage-news-form">
                              <div className="form-row mb-30 gap-20 ai-fe">
                                 <div className="one-half">
                                    <label>Newsletter Edition:</label>
                                    <select className="mb-0" name="cars" id="cars">

                                       {data.map(d => (
                                          <option value={d.WebNewsletterID}>{d.WebNewsletterEdition}</option>
                                       ))}


                                    </select>
                                 </div>
                                 <div className="one-fourth d-flex gap-20">
                                    <Link to="#" className="normal">Select</Link>
                                    <Link to="#" className="grey-btn">Add Newsletter</Link>
                                 </div>
                              </div>

                              <div className="form-grey mt-25">
                                 <div className="form-row">
                                    <div className="one-fourth">
                                       <label>Newsletter Type:</label>
                                       <select className="mb-0" name="cars" id="cars">
                                       <option >Select</option>
                                       {webnewsLetterTypeData.map(wtd => (
                                          <option value={wtd.WebNewsletterTypeID}>{wtd.WebNewsletterType}</option>
                                       ))}
                                       </select>
                                    </div>
                                    <div className="full-input">
                                       <label>Newsletter Edition:</label>
                                       <input type="text" />
                                    </div>
                                 </div>
                                 <div className="form-row ai-fe">
                                    <div className="one-fourth">
                                       <label>Picture:</label>
                                       <input type="file" />
                                    </div>
                                    <div className="one-fourth">
                                       <label>Currently selected:</label>
                                       <span className="d-flex">
                                          <input type="checkbox" name="vehicle1" value="Bike" />
                                          <label>Is Current</label>
                                       </span>
                                    </div>
                                 </div>
                                 <div className="req-update-textarea pl-0 pr-0 ">
                                    <div className="req-update-action">
                                       <Link to="#" onClick={submitUpdate} className="normal">Submit</Link>
                                    </div>
                                 </div>
                              </div>
                           </form>
                        </div>
                        <DataTable columns={columns} data={data} pagination={true} />

                     </div>
                  </div>
               </div>
            </div>
         </div>
         <Footer />
      </div>
   );
}




export default ManageNewsLetter;
