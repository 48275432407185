import Footer from "../../Footer";
import Header from "../../Header";
import CriticalUnusualIncident from "../CriticalUnusualIncident/CriticalUnusualincident.scss";
import Myinfoinner from "../../../images/assets/myinfo/Myinfoinner.svg";
import download from "../../../images/assets/myinfo/download.svg";
import search from "../../../images/assets/myinfo/search.svg";
import edit from "../../../images/assets/myinfo/edit.svg";
import plus from '../../../images/assets/cm/plus.svg';

// import acmanage from '../../images/assets/images/ManageInfo.svg';
import SideBar from "../../sidebar/sidebar";
import HeaderTopDetail from "../../CurrentChildren/HeaderTopDetail/HeaderTopDetail";
import { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { post } from "../../../utils/common";
import CriticalUnusualIncidentFormDownload from "../../downloadableForms/CriticalUnusualIncidentDownload/CriticalUnusualIncidentFormDownload";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "../../downloadableForms/CriticalUnusualIncidentDownload/CriticalUnusualIncidentFormDownload";
import CriticalUnusualIncidentFormPrint from "../../downloadableForms/CriticalUnusualIncidentDownload/CriticalUnusualIncidentPrint";
import mail from "../../../images/mail.png";

import html2canvas from "html2canvas";
import ReactDOMServer from "react-dom/server";
import jsPDF from "jspdf";
import MilageRembursementFormDownload from "../../downloadableForms/MilageRembursementForm/MilageRembursementFormDownload";
import LoadSpinner from "../../Loader/LoadSpinner";
import logoplacement from "../../../images/logo-placement.jpg";
const doc = new jsPDF();
const CIFORMPDF = <MilageRembursementFormDownload />;

function CriticalUnusualincident() {
  const inputRef = useRef(null);

  const [updateData, setUpdateData] = useState([]);

  const [updateDiv, setShowUpdateDiv] = useState(false);

  const [selectedDataForEdit, setSelectedDataForEdit] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const hideUpdateDivision = () => {
    setShowUpdateDiv(false);
  };

  const showUpdateDiv = () => {
    setShowUpdateDiv(true);
  };

  const hideCriticalIncidentType = () => {
    if (IncidentTypeDataFromString.length == 0) {
      return true;
    } else {
      return false;
    }
  };

  const onCriticalForm = () =>{
    localStorage.setItem('fromButtonClick', true);
    window.location.href = "/form/criticalunusualincidentform";
    
  }

  const onSubmitButtonClick = async () => {
    //setIsLoading(true);
    let username = sessionStorage.getItem("username");
    setShowUpdateDiv(false);
    setUpdateData([]);
    await post("/api/unusualincident/send-mail", { username, updateData });
    alert("Request Update  Emailed Successfully !");
    setIsLoading(false);
    window.location.reload(false);
  };

  const requestEditCriticalUnusualIncident = (rowData) => {
    setShowUpdateDiv(true);
    setUpdateData(data[rowData]);
    setSelectedDataForEdit(data[rowData]);
  };

  const [data, setData] = useState([]);
  const fetchData = async () => {
    const id = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/unusualincident/myinformationindex", { id });
    console.log(data);
    setData(data.data.data);
    // for(let i=0; i< data.data.data.length ; i++){
    //   if(data.data.data[i].Incident_Type == null){
    //     data.data.data.splice(i,1);
    //     i--;
    //   }
    // }
  };

  const [dataForDownload, setDataForDownload] = useState([]);

  let admindata = [];
  let prcdata = [];
  let sfaverbaldata=[];
  let sfawrittendata =[];
  let policedata=[];
  const clickedForDownload = async (row) => {
    const providerid = sessionStorage.getItem("providerSessionId");
   
    const UnusualIncident_FosterParentID =
      data[row].UnusualIncident_FosterParentID;

    let dataIA = await post("/api/unusualincident/getcuiform", {
      providerid,
      UnusualIncident_FosterParentID,
    });

    console.log(dataIA.data.data);
    admindata = dataIA.data.data[0].Administration!==null?dataIA.data.data[0].Administration.split("<br>"):'';
    dataIA.data.data[0].AdminContact =admindata[0].includes("undefined")? '': admindata[0];
    dataIA.data.data[0].AdminDate =admindata[1].includes("undefined")? '': admindata[1];
    dataIA.data.data[0].AdminTime =admindata[2].includes("undefined")? '': admindata[2];
    dataIA.data.data[0].AdminEmail =admindata[3].includes("undefined")? '': admindata[3];
    dataIA.data.data[0].AdminBywhom =admindata[4].includes("undefined")? '': admindata[4];

    prcdata = dataIA.data.data[0].ProtectionReportingCenter!==null?dataIA.data.data[0].ProtectionReportingCenter.split("<br>"):'';
    dataIA.data.data[0].ReportingCenterContact =prcdata[0].includes("undefined")? '': prcdata[0];
    dataIA.data.data[0].ReportingCenterByDate =prcdata[1].includes("undefined")? '': prcdata[1];
    dataIA.data.data[0].ReportingCenterTime =prcdata[2].includes("undefined")? '': prcdata[2];
    dataIA.data.data[0].ReportingCenterEmail =prcdata[3].includes("undefined")? '': prcdata[3];
    dataIA.data.data[0].ReportingCenterByWhom =prcdata[4].includes("undefined")? '': prcdata[4];

    sfaverbaldata = dataIA.data.data[0].SaintFrancisAgencyVerbalNotificationImmediately !==null?dataIA.data.data[0].SaintFrancisAgencyVerbalNotificationImmediately.split("<br>"):'';
    dataIA.data.data[0].SFAVerbalNotificationContact =sfaverbaldata[0].includes("undefined")? '': sfaverbaldata[0];
    dataIA.data.data[0].SFAVerbalNotificationDate =sfaverbaldata[1].includes("undefined")? '': sfaverbaldata[1];
    dataIA.data.data[0].SFAVerbalNotificationTime =sfaverbaldata[2].includes("undefined")? '': sfaverbaldata[2];
    dataIA.data.data[0].SFAVerbalNotificationEmail =sfaverbaldata[3].includes("undefined")? '': sfaverbaldata[3];
    dataIA.data.data[0].SFAVerbalNotificationByWhom =sfaverbaldata[4].includes("undefined")? '': sfaverbaldata[4];

    sfawrittendata = dataIA.data.data[0].SaintFrancisAgencyWrittenNotification!==null?dataIA.data.data[0].SaintFrancisAgencyWrittenNotification.split("<br>"):'';
    dataIA.data.data[0].SFAWrittenNotificationContact =sfawrittendata[0].includes("undefined")? '': sfawrittendata[0];
    dataIA.data.data[0].SFAWrittenNotificationDate =sfawrittendata[1].includes("undefined")? '': sfawrittendata[1];
    dataIA.data.data[0].SFAWrittenNotificationTime =sfawrittendata[2].includes("undefined")? '': sfawrittendata[2];
    dataIA.data.data[0].SFAWrittenNotificationEmail =sfawrittendata[3].includes("undefined")? '': sfawrittendata[3];
    dataIA.data.data[0].SFAWrittenNotificationByWhom =sfawrittendata[4].includes("undefined")? '': sfawrittendata[4];

    policedata = dataIA.data.data[0].Police_Sheriff!==null?dataIA.data.data[0].Police_Sheriff.split("<br>"):'';
    dataIA.data.data[0].Police_SheriffContact =policedata[0].includes("undefined")? '': policedata[0];
    dataIA.data.data[0].Police_SheriffDate =policedata[1].includes("undefined")? '': policedata[1];
    dataIA.data.data[0].Police_SheriffTime =policedata[2].includes("undefined")? '': policedata[2];
    dataIA.data.data[0].Police_SheriffEmail =policedata[3].includes("undefined")? '': policedata[3];
    dataIA.data.data[0].Police_SheriffByWhom =policedata[4].includes("undefined")? '': policedata[4];


    sessionStorage.setItem("cuiformdata", JSON.stringify(dataIA.data.data[0]));
    window.open("/print/criticalunusualform", "PRINT", "height=800,width=1200");
  };

  const [IncidentTypeDataFromString, setIncidentTypeDataFromString] = useState(
    []
  );


  const [PdfData, setPdfData] = useState([]);
  const DownLoadPdf = async (row) => {
    const providerid = sessionStorage.getItem("providerSessionId");

    const UnusualIncident_FosterParentID =
      data[row].UnusualIncident_FosterParentID;

    let dataIA = await post("/api/unusualincident/getcuiform", {
      providerid,
      UnusualIncident_FosterParentID,
    });
    admindata = dataIA.data.data[0].Administration!==null?dataIA.data.data[0].Administration.split("<br>"):'';
    dataIA.data.data[0].AdminContact =admindata[0].includes("undefined")? '': admindata[0];
    dataIA.data.data[0].AdminDate =admindata[1].includes("undefined")? '': admindata[1];
    dataIA.data.data[0].AdminTime =admindata[2].includes("undefined")? '': admindata[2];
    dataIA.data.data[0].AdminEmail =admindata[3].includes("undefined")? '': admindata[3];
    dataIA.data.data[0].AdminBywhom =admindata[4].includes("undefined")? '': admindata[4];

    prcdata = dataIA.data.data[0].ProtectionReportingCenter!==null?dataIA.data.data[0].ProtectionReportingCenter.split("<br>"):'';
    dataIA.data.data[0].ReportingCenterContact =prcdata[0].includes("undefined")? '': prcdata[0];
    dataIA.data.data[0].ReportingCenterByDate =prcdata[1].includes("undefined")? '': prcdata[1];
    dataIA.data.data[0].ReportingCenterTime =prcdata[2].includes("undefined")? '': prcdata[2];
    dataIA.data.data[0].ReportingCenterEmail =prcdata[3].includes("undefined")? '': prcdata[3];
    dataIA.data.data[0].ReportingCenterByWhom =prcdata[4].includes("undefined")? '': prcdata[4];


    sfaverbaldata = dataIA.data.data[0].SaintFrancisAgencyVerbalNotificationImmediately !==null?dataIA.data.data[0].SaintFrancisAgencyVerbalNotificationImmediately.split("<br>"):'';
    dataIA.data.data[0].SFAVerbalNotificationContact =sfaverbaldata[0].includes("undefined")? '': sfaverbaldata[0];
    dataIA.data.data[0].SFAVerbalNotificationDate =sfaverbaldata[1].includes("undefined")? '': sfaverbaldata[1];
    dataIA.data.data[0].SFAVerbalNotificationTime =sfaverbaldata[2].includes("undefined")? '': sfaverbaldata[2];
    dataIA.data.data[0].SFAVerbalNotificationEmail =sfaverbaldata[3].includes("undefined")? '': sfaverbaldata[3];
    dataIA.data.data[0].SFAVerbalNotificationByWhom =sfaverbaldata[4].includes("undefined")? '': sfaverbaldata[4];


    sfawrittendata = dataIA.data.data[0].SaintFrancisAgencyWrittenNotification!==null?dataIA.data.data[0].SaintFrancisAgencyWrittenNotification.split("<br>"):'';
    dataIA.data.data[0].SFAWrittenNotificationContact =sfawrittendata[0].includes("undefined")? '': sfawrittendata[0];
    dataIA.data.data[0].SFAWrittenNotificationDate =sfawrittendata[1].includes("undefined")? '': sfawrittendata[1];
    dataIA.data.data[0].SFAWrittenNotificationTime =sfawrittendata[2].includes("undefined")? '': sfawrittendata[2];
    dataIA.data.data[0].SFAWrittenNotificationEmail =sfawrittendata[3].includes("undefined")? '': sfawrittendata[3];
    dataIA.data.data[0].SFAWrittenNotificationByWhom =sfawrittendata[4].includes("undefined")? '': sfawrittendata[4];

    policedata = dataIA.data.data[0].Police_Sheriff!==null?dataIA.data.data[0].Police_Sheriff.split("<br>"):'';
    dataIA.data.data[0].Police_SheriffContact =policedata[0].includes("undefined")? '': policedata[0];
    dataIA.data.data[0].Police_SheriffDate =policedata[1].includes("undefined")? '': policedata[1];
    dataIA.data.data[0].Police_SheriffTime =policedata[2].includes("undefined")? '': policedata[2];
    dataIA.data.data[0].Police_SheriffEmail =policedata[3].includes("undefined")? '': policedata[3];
    dataIA.data.data[0].Police_SheriffByWhom =policedata[4].includes("undefined")? '': policedata[4];

    setPdfData(dataIA.data.data[0]);

    let string = dataIA.data.data[0].Incident_Type;
    if (string != null) {
      let arrayofIncident = string.split("\r\n");

      console.log("arrayofIncident", arrayofIncident);

      setIncidentTypeDataFromString(arrayofIncident);
    }
    // sessionStorage.setItem("cuiformdata", JSON.stringify(dataIA.data.data[0]));

    html2canvas(inputRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg");
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF("p", "mm");
      var position = 0;

      doc.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save("download.pdf");
    });
  };

  useEffect(() => {
    fetchData();
  }, [PdfData]);

  console.log(updateData);

  const columns = [
    {
      name: "Date",
      selector: "Incident_Date",
      cell: (row, j) => row.Incident_Date.substr(0, 9),
      sortable: true,
      wrap: true,
    },

    {
      name: "Time",
      selector: "Incident_Date",
      cell: (row, j) => row.Incident_Date.substr(9, 15),
      sortable: true,
      wrap: true,
    },
    {
      name: "Client Name",
      selector: "Client_Name",
      sortable: true,
      wrap: true,
    },

    {
      name: "Incident Type",
      selector: "Incident_Type",
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      selector: "Status",
      sortable: true,
      wrap: true,
    },


    {
      name: "Action",
      sortable: true,
      cell: (row, j) => (
        <div className="inline-flex three-buttons">
          <Link
            to="#"
            onClick={() => clickedForDownload(j)}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={search} alt="tag"></img>
          </Link>
          &nbsp;
          <Link
            type="button"
            onClick={() => requestEditCriticalUnusualIncident(j)}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={mail} alt="tag"></img>
          </Link>
          &nbsp;
          <Link
            onClick={() => DownLoadPdf(j)}
            //onClick={viewForm}

            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
            to="#"
          >
            <img src={download} alt="tag"></img>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div className="main">
      <Header />
      <div className="main-content">
        <HeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <SideBar />
              <div className="tab-detail">
                <div className="tab-detail-top">
                  <div className="m-profile">
                    <img src={Myinfoinner} alt="" />

                    <div className="pagesubtitle">
                      Critical/Unusual Incidents
                      <p>
                        Below is a record of critical/unusual incident reports
                        you have completed.
                      </p>
                    </div>
                  </div>
                  { <Link href="#" onClick={onCriticalForm} className="request-btn"> <img src={plus} style={{"height": "18px"}} alt="#"/> Add New Form</Link> }
                </div>
                <div id="divToPrint" ref={inputRef} class="divprint">
                  {/* <CriticalUnusualIncidentFormPrint /> */}
                  <div className="main">
                    <div className="main-content">
                      <div className="pagewithsidebar">
                        <div className="container">
                          <div className="pagewithsidebar-inner">
                            <div className="tab-detail Critical pagealignpad">
                              <div className="pdfheader">
                                <div
                                  style={{
                                    "text-align": "center",
                                    "margin-left": "40px",
                                    position: "relative",
                                  }}
                                >
                                  <img
                                    className="footer-logoo"
                                    src={logoplacement}
                                    alt=""
                                  />
                                </div>
                                <h4
                                  className="title"
                                  style={{
                                    "text-align": "center",
                                    "font-size": "1.45em",
                                    "font-weight": "bold",
                                    "margin-top": "20px",
                                    "margin-bottom": "50px",
                                  }}
                                >
                                  Saint Francis Ministries, Inc <br></br>
                                  Critical/Unusual Incident
                                </h4>
                              </div>
                              <div>
                                <form>
                                  <div className="select-incident">
                                    <div className="form-row">
                                      <div className="label9 labelpadtop">
                                        <label className="increasedFont">
                                          Incident :
                                        </label>
                                      </div>
                                      <div className="label23">
                                        <input
                                          type="text"
                                          id="birthday"
                                          name="birthday"
                                          className="increasedFont"
                                          onChange={(e) =>
                                            setPdfData({
                                              ...PdfData,
                                              IncidentDescription:
                                                e.target.value,
                                            })
                                          }
                                          value={PdfData.IncidentDescription}
                                        />
                                      </div>
                                      {/* <Link to="#" className="blue-btn">
                                        Select
                                      </Link>
                                      <Link to="#" className="red-btn">
                                        Delete
                                      </Link> */}
                                    </div>
                                  </div>
                                  {/* {data.Incident_Date} */}

                                  <div className="form-row mt-25">
                                    <div className="label9 labelpadtop">
                                      <label
                                        for="appt"
                                        className="increasedFont"
                                      >
                                        Child:
                                      </label>
                                    </div>
                                    <div className="label23">
                                      <input
                                        type="text"
                                        id="birthday"
                                        name="birthday"
                                        className="increasedFont"
                                        onChange={(e) =>
                                          setPdfData({
                                            ...PdfData,
                                            Client_Name: e.target.value,
                                          })
                                        }
                                        value={PdfData.Client_Name}
                                      />{" "}
                                    </div>
                                    <div className="label7"></div>
                                    <div className="label12 labelpadtop">
                                      <label
                                        for="birthday"
                                        className="increasedFont"
                                      >
                                        Date of Birth:
                                      </label>
                                    </div>
                                    <div className="label23">
                                      <input
                                        type="text"
                                        className="increasedFont"
                                        onChange={(e) =>
                                          setPdfData({
                                            ...PdfData,
                                            DOB: e.target.value,
                                          })
                                        }
                                        value={PdfData.DOB}
                                      />
                                    </div>
                                  </div>
                                  <div className="g-background">
                                    <div className="form-row mt-25">
                                      <div className="label20">
                                        <label
                                          for="birthday"
                                          className="increasedFont"
                                        >
                                          Date of Incident:
                                        </label>
                                        <input
                                          type="text"
                                          id="birthday"
                                          name="birthday"
                                          className="increasedFont"
                                          onChange={(e) =>
                                            setPdfData({
                                              ...PdfData,
                                              Incident_Date: e.target.value,
                                            })
                                          }
                                          value={PdfData.Incident_Date}
                                        />
                                        <small>
                                          *Future date will not be allowed.
                                        </small>
                                      </div>
                                      <div className="label5"></div>
                                      <div className="label20">
                                        <label
                                          for="appt"
                                          className="increasedFont"
                                        >
                                          Time of Incident:
                                        </label>
                                        <input
                                          type="text"
                                          id="appt"
                                          name="appt"
                                          className="increasedFont"
                                          onChange={(e) =>
                                            setPdfData({
                                              ...PdfData,
                                              Incident_Date: e.target.value,
                                            })
                                          }
                                          value={PdfData.Incident_Date}
                                        />
                                      </div>
                                      <div className="label10"></div>
                                      <div className="label20">
                                        <label
                                          for="birthday"
                                          className="increasedFont"
                                        >
                                          Date of Discovery:
                                        </label>
                                        <input
                                          type="text"
                                          id="birthday"
                                          name="birthday"
                                          className="increasedFont"
                                          onChange={(e) =>
                                            setPdfData({
                                              ...PdfData,
                                              Discovery_Date: e.target.value,
                                            })
                                          }
                                          value={PdfData.Discovery_Date}
                                        />
                                        <small>
                                          *Future date will not be allowed.
                                        </small>
                                      </div>
                                      <div className="label5"></div>
                                      <div className="label20">
                                        <label
                                          for="appt"
                                          className="increasedFont"
                                        >
                                          Time of Discovery:
                                        </label>
                                        <input
                                          type="text"
                                          id="appt"
                                          name="appt"
                                          className="increasedFont"
                                          onChange={(e) =>
                                            setPdfData({
                                              ...PdfData,
                                              Discovery_Date: e.target.value,
                                            })
                                          }
                                          value={PdfData.Discovery_Date}
                                        />
                                      </div>
                                    </div>

                                    <div className="form-row mt-30">
                                      <div className="form-container">
                                        <div className="formrowalign">
                                          <label className="label50 labelpadtop increasedFont">
                                            Name(s) of Foster Parent(s):
                                          </label>
                                          <input
                                            className="label50 increasedFont"
                                            type="text"
                                            value={PdfData.NameOfFosterParent == "undefined"? '': PdfData.NameOfFosterParent}
                                          ></input>
                                        </div>
                                        <div className="formrowalign">
                                          <label className="label50 labelpadtop2 increasedFont">
                                            Identify Saint Francis Office
                                            Location(s):
                                          </label>
                                          <input
                                            className="label50 increasedFont"
                                            type="text"
                                            value={
                                              PdfData.IdentifySaintFrancisOfficeLocation == "undefined"? '': PdfData.IdentifySaintFrancisOfficeLocation}
                                          ></input>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form-row mt-30">
                                    <div className="formrowalign2">
                                      <label className="rowalign label25 increasedFont">
                                        What agency placed child in the home:
                                      </label>
                                      <span className="rowalign label12">
                                        <input
                                          type="radio"
                                          name="agency_name"
                                          className="increasedFont"
                                          id="SaintFrancis"
                                          value={"SaintFrancis"}
                                          checked={
                                            PdfData.SaintFrancis ? true : false
                                          }
                                          // value={PdfData.SaintFrancis?true}
                                        />
                                        <label
                                          for="SaintFrancis"
                                          className="increasedFont"
                                        >
                                          Saint Francis
                                        </label>
                                      </span>
                                      <span className="rowalign label7">
                                        <input
                                          type="radio"
                                          className="increasedFont"
                                          name="agency_name"
                                          id="JJA"
                                          value={"JJA"}
                                          checked={PdfData.JJA ? true : false}
                                        />
                                        <label
                                          for="JJA"
                                          className="increasedFont"
                                        >
                                          JJA
                                        </label>
                                      </span>
                                      <span className="rowalign label7">
                                        <input
                                          type="radio"
                                          className="increasedFont"
                                          name="agency_name"
                                          id="KVC"
                                          value={"KVC"}
                                          checked={PdfData.KVC ? true : false}
                                        />
                                        <label
                                          for="KVC"
                                          className="increasedFont"
                                        >
                                          KVC
                                        </label>
                                      </span>
                                      <span className="rowalign label7">
                                        <input
                                          type="radio"
                                          className="increasedFont"
                                          name="agency_name"
                                          id="TFI"
                                          value={"TFI"}
                                          checked={PdfData.TFI ? true : false}
                                        />
                                        <label
                                          for="TFI"
                                          className="increasedFont"
                                        >
                                          TFI
                                        </label>
                                      </span>
                                      <span className="rowalign label7">
                                        <input
                                          type="radio"
                                          className="increasedFont"
                                          name="agency_name"
                                          id="COC"
                                          value={"COC"}
                                          checked={PdfData.COC ? true : false}
                                        />
                                        <label
                                          for="COC"
                                          className="increasedFont"
                                        >
                                          COC
                                        </label>
                                      </span>
                                      <span className="rowalign label7">
                                        <input
                                          type="radio"
                                          className="increasedFont"
                                          name="agency_name"
                                          id="DCF"
                                          value={"DCF"}
                                          checked={PdfData.DCF ? true : false}
                                        />
                                        <label
                                          for="DCF"
                                          className="increasedFont"
                                        >
                                          DCF
                                        </label>
                                      </span>
                                      <div>
                                        <span className="rowalign">
                                          <input
                                            type="radio"
                                            className="increasedFont"
                                            name="agency_name"
                                            id="Other"
                                            value={"Other"}
                                            checked={
                                              PdfData.Other ? true : false
                                            }
                                          />
                                          <label
                                            for="Other"
                                            className="increasedFont"
                                          >
                                            Other
                                          </label>
                                        </span>
                                        <span className="rowalign">
                                          <p className="identifytext increasedFont">
                                            {" "}
                                            (Identify):
                                          </p>
                                          <input
                                            className="input_text increasedFont"
                                            id="Identify"
                                            type="text"
                                            value={PdfData.othertextfield == "undefined"? '': PdfData.othertextfield}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  {/* <div className="g-background">
                                    <div className="form-row ">
                                      <div className="form-container">
                                        <div className="formrowalign  ">
                                          <label
                                            for="appt"
                                            className="label40 labelpadtop"
                                          >
                                            Child:
                                          </label>
                                          <input
                                            className="label50"
                                            type="text"
                                            id="birthday"
                                            name="birthday"
                                            onChange={(e) =>
                                              setPdfData({
                                                ...PdfData,
                                                Client_Name: e.target.value,
                                              })
                                            }
                                            value={PdfData.Client_Name}
                                          />{" "}
                                          {/* <select> 
                                           <option value="0">Select</option>

                                            </select> *
                                          <div className="label10"></div>
                                        </div>
                                        <div className="formrowalign ">
                                          <label
                                            for="appt"
                                            className="label50 labelpadtop"
                                          >
                                            Person Reporting:
                                          </label>
                                          <input
                                            className="label50"
                                            type="text"
                                            id="birthday"
                                            name="birthday"
                                            onChange={(e) =>
                                              setPdfData({
                                                ...PdfData,
                                                Provider_Member: e.target.value,
                                              })
                                            }
                                            value={PdfData.Provider_Member}
                                          />

                                          {/* <select>
                                                        <option value="0">Select</option>

                                                    </select> *
                                        </div>
                                      </div>
                                      <div className="form-container">
                                        <div className="formrowalign  ">
                                          <label
                                            for="appt"
                                            className="label40 labelpadtop"
                                          >
                                            Extent of Child Injury:
                                          </label>
                                          <input
                                            className="label50"
                                            type="text"
                                            id="birthday"
                                            name="birthday"
                                            onChange={(e) =>
                                              setPdfData({
                                                ...PdfData,
                                                ChildInjury: e.target.value,
                                              })
                                            }
                                            value={PdfData.ChildInjury}
                                          />

                                          {/* <select>
                                                        <option value="0">Select</option>

                                                    </select> *
                                          <div className="label10"></div>
                                        </div>
                                        <div className="formrowalign ">
                                          <label
                                            for="appt"
                                            className="label50 labelpadtop"
                                          >
                                            Extent of Foster Parent Injury:
                                          </label>
                                          <input
                                            className="label50"
                                            type="text"
                                            id="birthday"
                                            name="birthday"
                                            onChange={(e) =>
                                              setPdfData({
                                                ...PdfData,
                                                ParentInjury: e.target.value,
                                              })
                                            }
                                            value={PdfData.ParentInjury}
                                          />

                                          {/* <select>
                                                        <option value="0">Select</option>

                                                    </select> *
                                        </div>
                                      </div>
                                      <div className="note">
                                        <b>Help</b>
                                        <ol>
                                          <li>
                                            None - No observable or predictable
                                            injury will arise from the incident.
                                          </li>
                                          <li>Low - No pain or mild pain.</li>
                                          <li>
                                            Moderate - Discomforting or
                                            distressing pain (treatment may
                                            include doctor visit).
                                          </li>
                                          <li>
                                            Severe - Intense, severe, unbearable
                                            pain (treatment may include
                                            emergency room visit).
                                          </li>
                                        </ol>
                                      </div>
                                    </div>
                                  </div> */}

                                  <div className="incident-type form-row">
                                    <div className="label15 ">
                                      <h6 className="increasedFont">
                                        Type of Incident
                                      </h6>
                                    </div>
                                    <div className="label75">
                                      <ul>
                                        {IncidentTypeDataFromString.length>0?IncidentTypeDataFromString.slice(
                                          0,
                                          IncidentTypeDataFromString.length - 1
                                        ).map((incData, index) => (
                                          <li>
                                            <input
                                              type="checkbox"
                                              id="vehicle1"
                                              name="vehicle1"
                                            />
                                            <label
                                              for="vehicle1"
                                              className="increasedFont"
                                            >
                                              {" "}
                                              {incData}{" "}
                                            </label>
                                          </li>
                                        )):''}
                                      </ul>
                                    </div>
                                  </div>

                                  <div className="form-row v-pass mt-25">
                                    <p className="headtext">
                                      Did incident take place while on home
                                      visit/pass?:
                                    </p>
                                    {/* {PdfData.HomeVisitIncident === "Yes" ? ( */}
                                    <span>
                                      <input
                                        className="increasedFont"
                                        type="radio"
                                        id="Yes"
                                        name="fav_language"
                                        value="Yes"
                                        checked={
                                          PdfData.HomeVisitIncident === "Yes"
                                            ? true
                                            : false
                                        }
                                      />
                                      {/* onChange={e => setAddFormData({ ...AddFormData, IsHomeVisitIncident: e.target.checked })} type="radio" id="Yes" name="fav_language" value="Yes" /> */}
                                      <label
                                        for="Yes"
                                        className="increasedFont"
                                      >
                                        Yes
                                      </label>
                                    </span>
                                    {/* ) : ( */}
                                    <span>
                                      <input
                                        className="increasedFont"
                                        type="radio"
                                        id="No"
                                        name="fav_language"
                                        value="No"
                                        checked={
                                          PdfData.HomeVisitIncident === "No"
                                            ? true
                                            : false
                                        }
                                      />
                                      {/* onChange={e => setAddFormData({ ...AddFormData, IsHomeVisitIncident: !e.target.checked })} name="fav_language" value="No" /> */}
                                      <label for="No" className="increasedFont">
                                        No
                                      </label>
                                    </span>
                                    {/* )} */}
                                  </div>

                                  <div>
                                    <div className="add-cmt">
                                      <label className="headtext increasedFont">
                                        Describe Incident:
                                        <span className="italictext">
                                          (if more room is needed, pleases
                                          attach additional sheets)
                                        </span>
                                      </label>
                                      <p className="textborder">
                                        <p
                                          className="textpad increasedFont"
                                          contenteditable="true"
                                        >
                                          {PdfData.IncidentDescription == "undefined"? '': PdfData.IncidentDescription}
                                        </p>
                                      </p>
                                    </div>
                                    <div className="actiontextarea1">
                                      <label className="headtext label17">
                                        Immediate Action Taken:
                                      </label>
                                      <p className="textborder1">
                                        <p
                                          contenteditable="true"
                                          className="increasedFont"
                                        >
                                          {PdfData.ImmediateActionTaken == "undefined"? '': PdfData.ImmediateActionTaken}
                                        </p>
                                      </p>
                                    </div>
                                  </div>

                                  <div className="form-row mt-25">
                                    <div className="formrowalign2">
                                      <label className="rowalign label15 headtext increasedFont">
                                        Extent of Child Injury:
                                      </label>
                                      <span className="rowalign label10">
                                        <input
                                          type="radio"
                                          name="child_injury"
                                          id="None"
                                        />
                                        <label
                                          for="None"
                                          className="increasedFont"
                                        >
                                          {PdfData.ChildInjury}
                                        </label>
                                      </span>
                                      {/* <span className="rowalign label10">
                                        <input type="radio" name="child_injury" id="Low" />
                                        <label for="Low">Low</label>
                                      </span>
                                      <span className="rowalign label12">
                                        <input type="radio" name="child_injury" id="Moderate" />
                                        <label for="Moderate">Moderate</label>
                                      </span>
                                      <span className="rowalign label10">
                                        <input type="radio" name="child_injury" id="Severe" />
                                        <label for="Severe">Severe</label>
                                      </span> */}
                                    </div>
                                  </div>
                                  <div className="form-row mt-10">
                                    <div className="formrowalign2">
                                      <label className="rowalign label15 headtext increasedFont">
                                        Foster Parent Injury:
                                      </label>
                                      <span className="rowalign label10">
                                        <input
                                          type="radio"
                                          className="increasedFont"
                                          name="parent_injury"
                                          id="None"
                                        />
                                        <label
                                          for="None"
                                          className="increasedFont"
                                        >
                                          {PdfData.ParentInjury}
                                        </label>
                                      </span>
                                      {/* <span className="rowalign label10">
                                        <input type="radio" name="parent_injury" id="Low" />
                                        <label for="Low">Low</label>
                                      </span>
                                      <span className="rowalign label12">
                                        <input type="radio" name="parent_injury" id="Moderate" />
                                        <label for="Moderate">Moderate</label>
                                      </span>
                                      <span className="rowalign label10">
                                        <input type="radio" name="parent_injury" id="Severe" />
                                        <label for="Severe">Severe</label>
                                      </span> */}
                                    </div>
                                  </div>

                                  <div className="add-cmt2 mt-25">
                                    <label className="headtext increasedFont">
                                      All adults present or responsible for the
                                      client during the incident:
                                    </label>
                                    <p className="textborder">
                                      <p className="textpad increasedFont">
                                        {PdfData.AdultsInvolved == "undefined"? '': PdfData.ImmediateActionTaken}
                                      </p>
                                    </p>
                                  </div>

                                  <div className="form-row mt-25">
                                    <div className="label15 labelpadtop">
                                      <label
                                        for="appt"
                                        className="increasedFont"
                                      >
                                        Person Reporting:
                                      </label>
                                    </div>
                                    <div className="label23">
                                      <input
                                        className="increasedFont"
                                        type="text"
                                        id="birthday"
                                        name="birthday"
                                        onChange={(e) =>
                                          setPdfData({
                                            ...PdfData,
                                            Provider_Member: e.target.value,
                                          })
                                        }
                                        value={PdfData.Provider_Member}
                                      />

                                      {/* <select>
                                                        <option value="0">Select</option>

                                                    </select> */}
                                    </div>
                                    <div className="label7"></div>
                                    <div className="label15 labelpadtop">
                                      <label
                                        for="birthday"
                                        className="increasedFont"
                                      >
                                        Date Completed:
                                      </label>
                                    </div>
                                    <div className="label23">
                                      <input
                                        type="text"
                                       // className="increasedFont"
                                        onChange={(e) =>
                                          setPdfData({
                                            ...PdfData,
                                            CompletedDate: e.target.value,
                                          })
                                        }
                                        value={PdfData.CompletedDate}
                                      />
                                     
                                    </div>
                                  </div>

                                  <div className="mt-25 notificationalign">
                                    <div className="textfont">
                                      Send to foster care worker. Original
                                      Placed in Child’s file.
                                    </div>
                                    <div className="headtext">
                                      <p>Notification</p>
                                    </div>
                                    <table>
                                      <tr>
                                        <th>Contact (If Applicable)</th>
                                        <th>Contact Name</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <th>Verbal/Email</th>
                                        <th>By whom?</th>
                                      </tr>
                                      <tr className="tableinputbg increasedFont important!">
                                        <td>Administration</td>
                                        <td>
                                          {" "}
                                          <input type="text" value={PdfData.AdminContact}/>
                                        </td>
                                        <td>
                                          {" "}
                                          <input type="text" value={PdfData.AdminDate} />
                                        </td>
                                        <td>
                                          {" "}
                                          <input type="text" value={PdfData.AdminTime} />
                                        </td>
                                        <td>
                                          {" "}
                                          <input type="text"  value={PdfData.AdminEmail}/>
                                        </td>
                                        <td>
                                          {" "}
                                          <input type="text" value={PdfData.AdminBywhom} />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          Protection Reporting Center (when
                                          applicable)
                                        </td>
                                        <td>
                                          {" "}
                                          <input type="text" value={PdfData.ReportingCenterContact} />
                                        </td>
                                        <td>
                                          {" "}
                                          <input type="text" value={PdfData.ReportingCenterByDate}/>
                                        </td>
                                        <td>
                                          {" "}
                                          <input type="text" value={PdfData.ReportingCenterTime}/>
                                        </td>
                                        <td>
                                          {" "}
                                          <input type="text" value={PdfData.ReportingCenterEmail}/>
                                        </td>
                                        <td>
                                          {" "}
                                          <input type="text" value={PdfData.ReportingCenterByWhom}/>
                                        </td>
                                      </tr>
                                      <tr className="tableinputbg">
                                        <td>
                                          Saint Francis Agency verbal
                                          notification immediately
                                        </td>
                                        <td>
                                          {" "}
                                          <input type="text" value={PdfData.SFAVerbalNotificationContact}/>
                                        </td>
                                        <td>
                                          {" "}
                                          <input type="text" value={PdfData.SFAVerbalNotificationDate}/>
                                        </td>
                                        <td>
                                          {" "}
                                          <input type="text" value={PdfData.SFAVerbalNotificationTime}/>
                                        </td>
                                        <td>
                                          {" "}
                                          <input type="text" value={PdfData.SFAVerbalNotificationEmail}/>
                                        </td>
                                        <td>
                                          {" "}
                                          <input type="text" value={PdfData.SFAVerbalNotificationByWhom}/>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          Saint Francis Agency written
                                          notification within 24 hours
                                        </td>
                                        <td>
                                          {" "}
                                          <input type="text"  value={PdfData.SFAWrittenNotificationContact}/>
                                        </td>
                                        <td>
                                          {" "}
                                          <input type="text"  value={PdfData.SFAWrittenNotificationDate}/>
                                        </td>
                                        <td>
                                          {" "}
                                          <input type="text"  value={PdfData.SFAWrittenNotificationTime}/>
                                        </td>
                                        <td>
                                          {" "}
                                          <input type="text"  value={PdfData.SFAWrittenNotificationEmail}/>
                                        </td>
                                        <td>
                                          {" "}
                                          <input type="text"  value={PdfData.SFAWrittenNotificationByWhom}/>
                                        </td>
                                      </tr>
                                      <tr className="tableinputbg">
                                        <td>Police/Sheriff</td>
                                        <td>
                                          {" "}
                                          <input type="text" value={PdfData.Police_SheriffContact} />
                                        </td>
                                        <td>
                                          {" "}
                                          <input type="text" value={PdfData.Police_SheriffDate}/>
                                        </td>
                                        <td>
                                          {" "}
                                          <input type="text" value={PdfData.Police_SheriffTime}/>
                                        </td>
                                        <td>
                                          {" "}
                                          <input type="text" value={PdfData.Police_SheriffEmail}/>
                                        </td>
                                        <td>
                                          {" "}
                                          <input type="text" value={PdfData.Police_SheriffByWhom} />
                                        </td>
                                      </tr>
                                    </table>
                                  </div>

                                  <div className="action mt-25 ">
                                    <p
                                      style={{
                                        "margin-top": "350px",
                                      }}
                                    >
                                      STEPS OF ACTION
                                    </p>
                                    <a className="increasedFont">
                                      When you observe or are part of a
                                      Critical/Unusual Incident, proceed through
                                      the following steps:
                                    </a>
                                    <ol>
                                      <li className="increasedFont">
                                        Take immediate action as needed (i.e.
                                        safety, emergency procedures, first
                                        aid).
                                      </li>
                                      <li className="increasedFont">
                                        Decide if emergent assistance from
                                        anyone is needed.
                                        <ul className="increasedFont">
                                          <li>
                                            If emergent assistance is needed,
                                            contact the appropriate people (i.e.
                                            police, fire department, etc.)
                                          </li>
                                          <li className="increasedFont">
                                            For verbal notification to Saint
                                            Francis, the emergency number is
                                            1-888-732-4673
                                          </li>
                                        </ul>
                                      </li>
                                      <li className="increasedFont">
                                        Complete the Critical/Unusual Incident
                                        report.
                                      </li>
                                      <li className="increasedFont">
                                        Send Critical/Unusual Incident form to
                                        your foster care worker by email or mail
                                        within 24 hours.
                                      </li>
                                      <li className="increasedFont">
                                        Allegations of abuse and neglect must be
                                        reported immediately.
                                      </li>
                                    </ol>
                                  </div>

                                  {/* <div className="add-cmt">
                                    <label>Describe Incident:</label>
                                    <p className="textborder">
                                      <p
                                        className="textpad"
                                        contenteditable="true"
                                      >
                                        {PdfData.IncidentDescription}
                                      </p>
                                    </p> */}
                                  {/* <textarea
                                      contenteditable="true"
                                      value={PdfData.IncidentDescription}
                                    ></textarea> */}
                                  {/* onChange={e => setAddFormData({ ...AddFormData, IncidentDescription: e.target.value })}></textarea> */}
                                  {/* <label className="mt-25">
                                      Immediate Action Taken:
                                    </label>
                                    <p className="textborder">
                                      <p
                                        className="textpad"
                                        contenteditable="true"
                                      >
                                        {PdfData.ImmediateActionTaken}
                                      </p>
                                    </p> */}

                                  {/* <textarea
                                      contenteditable="true"
                                      value={PdfData.ImmediateActionTaken}
                                    >
                                      {" "}
                                    </textarea> */}
                                  {/* onChange={e => setAddFormData({ ...AddFormData, ImmediateActionTaken: e.target.value })}> </textarea> */}
                                  {/* <label className="mt-25">
                                      All Adults Present for Child Incident:
                                    </label>
                                    <p className="textborder">
                                      <p className="textpad">
                                        {PdfData.AdultsInvolved}
                                      </p>
                                    </p> */}
                                  {/* <textarea
                                      value={PdfData.AdultsInvolved}
                                    ></textarea> */}
                                  {/* onChange={e => setAddFormData({ ...AddFormData, AdultsInvolved: e.target.value })}></textarea> */}
                                  {/* </div> */}
                                  {/* <div className="action-btn">
                                            <Link to="#" onClick={closeClicked} className="red-btn">Close</Link>
                                            <Link to="#" onClick={onSubmitButtonClick} className="blue-btn">Submit</Link>
                                        </div> */}
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <Footer /> */}
                  </div>
                </div>
                {isLoading ? <LoadSpinner /> : ""}

                <div className="tab-detail-bottom">
                  <form className="attach-doc-form">
                    {updateDiv ? (
                      <div className="req-update-textarea">
                        <label>
                          <span>
                            <b>Request Update for</b> - Critical Unusual
                            Incident | UnusualIncident_FosterParentID:
                            {selectedDataForEdit.UnusualIncident_FosterParentID
                              ? selectedDataForEdit.UnusualIncident_FosterParentID
                              : ""}
                            | Incident Date:{" "}
                            {selectedDataForEdit.Incident_Date
                              ? selectedDataForEdit.Incident_Date
                              : ""}{" "}
                            | Client Name :
                            {selectedDataForEdit.Client_Name
                              ? selectedDataForEdit.Client_Name
                              : ""}{" "}
                            <br></br>
                            <br></br>
                            <b> Description for Update:</b>
                          </span>
                        </label>
                        <textarea
                          onChange={(e) =>
                            setUpdateData({
                              ...updateData,
                              description: e.target.value,
                            })
                          }
                        ></textarea>
                        <div className="req-update-action">
                          <Link
                            to="#"
                            onClick={hideUpdateDivision}
                            className="close"
                          >
                            close
                          </Link>
                          <Link
                            to="#"
                            onClick={onSubmitButtonClick}
                            className="normal"
                          >
                            Submit
                          </Link>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <br></br>

                    {/* <table>
                                            <tr>
                                                <th>Date</th>
                                                <th>Time</th>
                                                <th>Client Name</th>
                                                <th>Incident Type</th>
                                                <th></th>
                                            </tr>
                                            <tr>
                                                <td>Alfreds Futterkiste</td>
                                                <td>Maria Anders</td>
                                                <td>Germany</td>
                                                <td>Germany</td>
                                                <td className="table-act"><Link to=""><img src={search} alt="" /></Link><Link to=""><img src={download} alt="" /></Link></td>
                                            </tr>
                                            <tr>
                                                <td>Alfreds Futterkiste</td>
                                                <td>Maria Anders</td>
                                                <td>Germany</td>
                                                <td>Germany</td>
                                                <td className="table-act"><Link to=""><img src={search} alt="" /></Link><Link to=""><img src={download} alt="" /></Link></td>
                                            </tr>
                                            <tr>
                                                <td>Alfreds Futterkiste</td>
                                                <td>Maria Anders</td>
                                                <td>Germany</td>
                                                <td>Germany</td>
                                                <td className="table-act"><Link to=""><img src={search} alt="" /></Link><Link to=""><img src={download} alt="" /></Link></td>
                                            </tr>
                                            <tr>
                                                <td>Alfreds Futterkiste</td>
                                                <td>Maria Anders</td>
                                                <td>Germany</td>
                                                <td>Germany</td>
                                                <td className="table-act"><Link to=""><img src={search} alt="" /></Link><Link to=""><img src={download} alt="" /></Link></td>
                                            </tr>

                                        </table> */}

                    <DataTable
                      columns={columns}
                      data={data}
                      pagination={true}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CriticalUnusualincident;