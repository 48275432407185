import Footer from '../Footer';
import Header from '../Header';
import '../myhousehold/myhousehold.scss'
import editicon from '../../images/assets/myinfo/edit.svg';
import minfoinner from '../../images/assets/myinfo/minfoinner.svg';
import mychild from '../../images/assets/myinfo/mychild.svg';

import SideBar from '../sidebar/sidebar';
import HeaderTopDetail from '../CurrentChildren/HeaderTopDetail/HeaderTopDetail';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { post } from '../../utils/common';
import DataTable from 'react-data-table-component';
import mail from '../../images/mail.png';
import LoadSpinner from '../Loader/LoadSpinner';
import plus from '../../images/assets/cm/plus.svg';



function Myhousehold() {


    const [showHouseHold, setHouseHold] = useState(true);
    const [showChildren, setShowChildren] = useState(false);
    const [showPet, setShowPet] = useState(false);


    const [updateAdult, setshowUpdateAdultSection] = useState(false);
    const [AddAdult, setshowAddAdultDiv] = useState(false);

    const [updateChild, setshowUpdateChildSection] = useState(false)
    const [AddChild, setshowAddChildDiv] = useState(false)

    const [updatePet, setshowUpdatePetSection] = useState(false)
    const [AddPet, setshowAddPetDiv] = useState(false)



    const [updateAdultData, setUpdateAdultData] = useState([]);


   const showAddAdultDiv = () =>{
    // setHouseHold(true)
    setshowAddAdultDiv(true)
    setshowUpdateChildSection(false)
    setshowUpdateAdultSection(false);
    setshowUpdateChildSection(false)
    setshowUpdatePetSection(false)
    }

    const showUpdateAdultDiv = () => {
        setshowAddAdultDiv(false)
        setshowUpdateChildSection(false)
        setshowUpdateAdultSection(true);
        setshowUpdateChildSection(false)
        setshowUpdatePetSection(false)

    }
    const hideUpdateAdultDiv = () => {
        setshowUpdateAdultSection(false);
        setshowAddAdultDiv(false);
    }

    const showUpdateChildDiv = () => {
        setshowUpdateAdultSection(false);
        setshowUpdateChildSection(true);
        setshowUpdatePetSection(false)
        setshowAddChildDiv(false)
        // setshowAddChildDiv


    }

    const showAddChildDiv = () => {
        setshowUpdateAdultSection(false);
        setshowUpdateChildSection(false);
        setshowUpdatePetSection(false)
        setshowAddChildDiv(true)


    }
    const hideUpdateChildDiv = () => {
        setshowUpdateChildSection(false)
        setshowAddChildDiv(false)

    }
    const hideadditionChildDiv = () => {
        setshowUpdateChildSection(false)

    }


    const showUpdatePetDiv = () => {
        setAdditionPet(false)
        setshowUpdateAdultSection(false);
        setshowUpdateChildSection(false)
        setshowUpdatePetSection(true)


    }
    const hideUpdatePetDiv = () => {
        setshowUpdatePetSection(false);
    }



    const showHouseHoldDiv = () => {
        setHouseHold(true)
        setShowPet(false)
        setShowChildren(false)
        setshowUpdateAdultSection(false)
        setshowAddAdultDiv(false)
        setshowAddPetDiv(false)

    }

    const showChildrenDiv = () => {
        setHouseHold(false)
        setShowPet(false)
        setShowChildren(true)
        setshowUpdateAdultSection(false)
        setshowUpdateChildSection(false)
        setshowAddChildDiv(false)
        setshowAddPetDiv(false)
        setshowUpdatePetSection(false)

    }

    const showPetDiv = () => {

        setHouseHold(false)
        setShowPet(true)
        setAdditionPet(false)
        setShowChildren(false)
        setshowUpdateAdultSection(false)
        setshowAddAdultDiv(false)
        setshowAddPetDiv(false)
        setshowUpdatePetSection(false)
        setshowAddPetDiv(false)

    }
    const [isLoading, setIsLoading] = useState(false);


    const [petData, setPetData] = useState([]);
    const [adultAtHomeData, setAdultAtHomeData] = useState([])
    const [ProviderMemberChildData, setproviderMemberChildData] = useState([])

    const [adultAtHomeDataEdit, setadultAtHomeDataEdit] = useState([])
    const [petDataEdit, setpetDataEdit] = useState([])
    const [ProviderMemberChildDataEdit, setProviderMemberChildDataEdit] = useState([])

    const requestChildrenEdit = (j) => {
        setshowAddChildDiv(false)
        setshowAddPetDiv(false)
        setshowUpdatePetSection(false)
        setAddChild(false)
        setshowUpdateChildSection(false)
        setProviderMemberChildDataEdit(ProviderMemberChildData[j])
        setshowUpdateChildSection(true)
        console.log(ProviderMemberChildDataEdit)
    }
    const requestAdultEdit = (j) => {
        setshowAddAdultDiv(false)
        
        setadultAtHomeDataEdit(adultAtHomeData[j])
        setshowUpdateAdultSection(true)
        console.log(adultAtHomeDataEdit)
    }


    const requestPetEdit = (j) => {
        setAdditionPet(false)
        setpetDataEdit(petData[j])
        setshowAddChildDiv(false)
        setshowUpdateChildSection(false)
        setshowUpdatePetSection(true)
        console.log(petDataEdit)

    }

    

    const [additionPet, setAdditionPet] = useState(false);

    const [addChild, setAddChild] = useState(false);

    // const onPetAdditionSubmit = () => {

    //     console.log("Submit Here")
    // }


    const showAdditionPetDiv = () => {
        setAdditionPet(true)
        setshowUpdatePetSection(false)
    }

    const HideAdditionPetDiv = () => {
        setAdditionPet(false)
    }
    const HideAdditionChildDiv = () => {
        setshowUpdateChildSection(false)


    }

    console.log(updateAdultData);


    const onAdultUpdateSubmitadition = async (adult_name) => {

        let username = sessionStorage.getItem('username')
       // setIsLoading(true);
        // console.log(adult_name);
        // setUpdateAdultData({ ...updateAdultData, adult_name: adultAtHomeDataEdit.ProviderAdultName })
        // setUpdateAdultData({ ...updateAdultData, provider_name: adultAtHomeDataEdit.ProviderAdultName})

        console.log(updateAdultData)
        setshowAddAdultDiv(false)
        setshowAddChildDiv(false)
        setshowAddPetDiv(false)
        setshowUpdateAdultSection(false)
        setshowUpdateChildSection(false)
        setshowUpdatePetSection(false)
        setAdultAtHomeData(false)
        setUpdateAdultData([])
        await post('/api/myhousehold/update-email-household-adult-addition', { username, updateAdultData });
        alert('Request Addition  Emailed Successfully !');
        //setshowAddAdultDiv(false)

        setIsLoading(false);
         window.location.reload(false)
    }
    const onAdultUpdateSubmitupdate = async (adult_name) => {
       // setIsLoading(true);
        let username = sessionStorage.getItem('username')

        // console.log(adult_name);
        // setUpdateAdultData({ ...updateAdultData, adult_name: adultAtHomeDataEdit.ProviderAdultName })
        // setUpdateAdultData({ ...updateAdultData, provider_name: adultAtHomeDataEdit.ProviderAdultName})

        console.log(updateAdultData)
        setshowAddAdultDiv(false)
        setshowAddChildDiv(false)
        setshowAddPetDiv(false)
        setshowUpdateAdultSection(false)
        setshowUpdateChildSection(false)
        setshowUpdatePetSection(false)
      
        setUpdateAdultData([])
        await post('/api/myhousehold/update-email-household-adult-update', { username, updateAdultData });
        alert('Request Update  Emailed Successfully !');
        setIsLoading(false);
        //setshowUpdateAdultSection(false)
         window.location.reload(false)
    }

    const onChildAdditionSubmit = async (adult_name) => {
       // setIsLoading(true);
        // setUpdateAdultData({ ...updateAdultData, provider_name: sessionStorage.getItem('username')})

        // setUpdateAdultData({ ...updateAdultData, adult_name: adult_name })
        // console.log(updateAdultData)
         let username = sessionStorage.getItem('username');
        setshowAddAdultDiv(false)
        setshowAddChildDiv(false)
        setshowAddPetDiv(false)
        setshowUpdateAdultSection(false)
        setshowUpdateChildSection(false)
        setshowUpdatePetSection(false)
        setUpdateAdultData([])
        await post('/api/myhousehold/update-email-household-child-addition', { username, updateAdultData });
        alert('Request Addition  Emailed Successfully !');
        // setIsLoading(false);
        // setshowAddChildDiv(false)
        // setshowUpdateChildSection(false)
        // window.location.reload(false)
    }

    const onChildUpdateSubmit = async (adult_name) => {
      //  setIsLoading(true);
        // setUpdateAdultData({ ...updateAdultData, provider_name: sessionStorage.getItem('username')})

        // setUpdateAdultData({ ...updateAdultData, adult_name: adult_name })
        // console.log(updateAdultData)
         let username = sessionStorage.getItem('username');
        setshowAddAdultDiv(false)
        setshowAddChildDiv(false)
        setshowAddPetDiv(false)
        setshowUpdateAdultSection(false)
        setshowUpdateChildSection(false)
        setshowUpdatePetSection(false)
        setUpdateAdultData([])
        await post('/api/myhousehold/update-email-household-child-update', { username, updateAdultData });
        alert('Request Update  Emailed Successfully !');
        
        // setIsLoading(false);
        // setshowUpdateChildSection(false);
        // setshowAddChildDiv(false)
        // window.location.reload(false)
    }
    const onPetAdditionSubmit = async (adult_name) => {
       // setIsLoading(true);
        // setUpdateAdultData({ ...updateAdultData, provider_name: sessionStorage.getItem('username')})

        // setUpdateAdultData({ ...updateAdultData, adult_name: adult_name })
        // console.log(updateAdultData)
        let username = sessionStorage.getItem('username');
        setshowAddAdultDiv(false)
        setshowAddChildDiv(false)
        setshowAddPetDiv(false)
        setshowUpdateAdultSection(false)
        setshowUpdateChildSection(false)
        setshowUpdatePetSection(false)
        setUpdateAdultData([])
        await post('/api/myhousehold/update-email-household-pet-addition', { username, updateAdultData });
        alert('Request Addition  Emailed Successfully !');
        // setshowAddPetDiv(false);
        // setshowUpdatePetSection(false);
        // setAdditionPet(false)
        // setIsLoading(false);


        // window.location.reload(false)
    }

    const onPetUpdateSubmitUpdate = async (adult_name) => {
        //setIsLoading(true);
        // setUpdateAdultData({ ...updateAdultData, provider_name: sessionStorage.getItem('username')})

        // setUpdateAdultData({ ...updateAdultData, adult_name: adult_name })
        // console.log(updateAdultData)
         let username = sessionStorage.getItem('username');
         setshowAddAdultDiv(false)
         setshowAddChildDiv(false)
         setshowAddPetDiv(false)
         setshowUpdateAdultSection(false)
         setshowUpdateChildSection(false)
         setshowUpdatePetSection(false)
         setAdditionPet(false)
         setUpdateAdultData([])
        await post('/api/myhousehold/update-email-household-pet-update', { username, updateAdultData });
        alert('Request Update  Emailed Successfully !');
        // setIsLoading(false);
        // setshowAddPetDiv(false)
        // setshowUpdatePetSection(false);
        // setAdditionPet(false)

        // window.location.reload(false)
    }


    const fetchData = async () => {
        let providerId = sessionStorage.getItem('providerSessionId');

        let alldataForHouseHold = await post('/api/myhousehold/getalldataforhousehold', { providerId });
        console.log(alldataForHouseHold.data)
        if (alldataForHouseHold.data.AdultData.length !== 0) {
            setAdultAtHomeData(alldataForHouseHold.data.AdultData)
        }
        if (alldataForHouseHold.data.PetData.length !== 0) {
            setPetData(alldataForHouseHold.data.PetData)
        }
        if (alldataForHouseHold.data.providerMemberChildData.length !== 0) {
            setproviderMemberChildData(alldataForHouseHold.data.providerMemberChildData)
        }

    };




    useEffect(() => {
        fetchData()

    }, []);

    const columns = [

        {


            name: 'Name',
            selector: 'Name',
            sortable: true,
            wrap: true
        },

        {


            name: 'Age',
            selector: 'Age',
            // cell: (row, j) => ((new Date().getUTCFullYear() - new Date(row.DOB).getUTCFullYear())),

            sortable: true,
            wrap: true
        },
        {


            name: 'Type',
            selector: 'Type',
            sortable: true,
            wrap: true
        },
        {


            name: 'Begin Date',
            selector: 'Begin Date',
            // cell: (row, j) => (new Date(row.BeginDate).toLocaleDateString()),
            sortable: true,
            wrap: true
        },
        {


            name: 'End Date',
            selector: 'End Date',
            // cell: (row, j) => (new Date(row.EndDate).toLocaleDateString()),
            sortable: true,
            wrap: true
        },
        {


            name: 'Primary Contact',
            selector: 'Primary Contact',
            // cell: (row, j) => (row.PrimaryContact ? 'TRUE' : 'FALSE'),

            sortable: true,
            wrap: true
        },

        {


            name: 'Work Phone',
            selector: 'Work Phone',
            // cell: (row, j) => (row.CellPh ? row.CellPh : 'Null'),

            sortable: true,
            wrap: true
        },

        {


            name: 'Cell Phone',
            selector: 'Cell Phone',
            // cell: (row, j) => (row.CellPh ? row.CellPh : 'Null'),

            sortable: true,
            wrap: true
        },


        // {
        //     name: 'Action',
        //     show: false,
        //     sortable: true,
        //     cell: (row, j) => (
        //         <div class="inline-flex">
        //             {sessionStorage.getItem("RoleInfo") != "FosterHome" ?
        //                 <button onClick={() => requestAdultEdit(j)}

        //                     class="text-pink-500 bg-transparent border border-solid border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        //                     type="button"
        //                 >
        //                     <img src={mail}></img>
        //                 </button>
        //                 : ''}

        //         </div>
        //     ),
        // },
    ];

    const childrencolumns = [

        {


            name: 'Name',
            selector: 'Name',
            sortable: true,
        },

        {


            name: 'Gender',
            selector: 'Gender',
            sortable: true,
        },
        {


            name: 'Type',
            selector: 'Type',
            sortable: true,
        },
        {


            name: 'Age',
            selector: 'Age',
            sortable: true,
        },
        {


            name: 'BeginDate',
            selector: 'Begin Date',
            // cell: (row, j) => (new Date(row.BeginDate).toLocaleDateString()),
            sortable: true,
        },
        {


            name: 'EndDate',
            selector: 'End Date',
            // cell: (row, j) => (new Date(row.EndDate).toLocaleDateString()),
            sortable: true,
        },


        {
            name: 'Action',
            sortable: true,
            cell: (row, j) => (
                <div class="inline-flex">
                    <Link onClick={() => requestChildrenEdit(j)}

                        class="text-pink-500 bg-transparent border border-solid border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        to="#"
                    >
                        <img src={mail}></img>
                    </Link>

                </div>
            ),
        },
    ];
    const petcolumns = [

        {


            name: 'Pet',
            selector: 'Pet',
            sortable: true,
        },

        {


            name: 'Quantity',
            selector: 'Quantity',
            sortable: true,
        },

        {
            name: 'Action',
            sortable: true,
            cell: (row, j) => (
                <div class="inline-flex">
                    <Link onClick={() => requestPetEdit(j)}

                        class="text-pink-500 bg-transparent border border-solid border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        to="#"
                    >
                        <img src={mail}></img>
                    </Link>

                </div>
            ),
        },
    ];

    return (
        <div className="main">

            <Header />

            <div className="main-content" >
                <HeaderTopDetail />
                <div className="pagewithsidebar">
                    <div className="container">
                        <div className="pagewithsidebar-inner">


                            <SideBar />
                            <div className="tab-detail pl">
                                {showHouseHold ? <div className="tab-detail-top">
                                    <div className="m-profile">
                                        <img src={minfoinner} alt="" />
                                        My Household - Adult in Home
                                    </div>
                                    <Link to="#" onClick={showAddAdultDiv} className="request-btn"><img src={plus} style={{"height": "18px"}} alt="#"/>request  addition</Link>
                                </div>
                                    : ''}
                                {isLoading ? (<LoadSpinner />) : ''}
                                {showChildren ? <div className="tab-detail-top">
                                    <div className="m-profile">
                                        <img src={mychild} alt="" />
                                        My Household - Children (Permanent)
                                    </div>
                                    <Link to="#" onClick={showAddChildDiv} className="request-btn"><img src={plus} style={{"height": "18px"}} alt="#"/>request addition</Link>
                                </div>
                                    : ''}
                                {isLoading ? (<LoadSpinner />) : ''}
                                {showPet ? <div className="tab-detail-top">
                                    <div className="m-profile">
                                        <img src={minfoinner} alt="" />
                                        My Household - Pets
                                    </div>
                                    <Link to="#" onClick={showAdditionPetDiv} className="request-btn"><img src={plus} style={{"height": "18px"}} alt="#"/>request Addition</Link>
                                </div>
                                    : ''}
                                {/* {isLoading ? (<LoadSpinner />) : ''} */}


                                <div className="tab-detail-bottom">
                                    <form className="myprofile-form myhouse-hold">
                                        <div className="tabui">
                                            <ul>
                                                <li><Link to="#" className={showHouseHold ? 'active' : ''} onClick={showHouseHoldDiv}>My Household</Link></li>
                                                <li><Link to="#" className={showChildren ? 'active' : ''} onClick={showChildrenDiv}>Children (Permanent)</Link></li>
                                                <li><Link to="#" className={showPet ? 'active' : ''} onClick={showPetDiv}>Pets</Link></li>
                                            </ul>
                                        </div>
                                        <div className="form-tabs">
                                            {showHouseHold ?
                                                <div className="form-tab-1 m-bottom">
                                                    {AddAdult ? <div className="req-update-textarea">
                                                        <label><span><b>Request Addition </b> -  Adults in home
                                                            {/* |   Name: {adultAtHomeDataEdit.Name ? adultAtHomeDataEdit.Name : 'NULL'}    |
                                                            Age: {adultAtHomeDataEdit.Age}    |    Type: {adultAtHomeDataEdit.Type ? adultAtHomeDataEdit.Type :
                                                                'NULL'} */}
                                                                <br></br>
                                                                <br></br>
                                                                <b> Description for Addition:</b>
                                                        </span></label>
                                                        <textarea onChange={e => setUpdateAdultData({ ...updateAdultData, description: e.target.value })}></textarea>
                                                        <div className="req-update-action">
                                                            <Link to="#" onClick={hideUpdateAdultDiv} className="close">close</Link>
                                                            <Link to="#" onClick={() => onAdultUpdateSubmitadition(adultAtHomeDataEdit.ProviderAdultName)} className="normal">Submit</Link>
                                                        </div>
                                                    </div> : ''}





                                                </div>
                                                : ''}


                                            {showHouseHold ?
                                                <div className="form-tab-1 m-bottom">
                                                    {updateAdult ? <div className="req-update-textarea">
                                                        <label><span><b>Request Update </b> -  Adults in home 
                                                            |   Name: {adultAtHomeDataEdit.Name ? adultAtHomeDataEdit.Name : 'NULL'}    |
                                                            Age:   {adultAtHomeDataEdit.Age ? adultAtHomeDataEdit.Age :
                                                                'NULL'}
                                                            <br></br>
                                                            <br></br>
                                                            <b> Description for Update:</b></span></label>
                                                        <textarea onChange={e => setUpdateAdultData({ ...updateAdultData, description: e.target.value })}></textarea>
                                                        <div className="req-update-action">
                                                            <Link to="#" onClick={hideUpdateAdultDiv} className="close">close</Link>
                                                            <Link to="#" onClick={() => onAdultUpdateSubmitupdate(adultAtHomeDataEdit.ProviderAdultName)} className="normal">Submit</Link>
                                                        </div>
                                                    </div> : ''}
                                                    <div className="house-hold-table">

                                                        <DataTable columns={columns} data={adultAtHomeData} pagination={true} />

                                                    </div>
                                                </div> : ''}



                                            {showChildren ?

                                                <div className="form-tab-2 m-bottom">
                                                    {updateChild ? <div className="req-update-textarea">
                                                        <label><span><b>Request Update </b> -  Children   |
                                                            Name:{ProviderMemberChildDataEdit.Name ? ProviderMemberChildDataEdit.Name : 'Null'}   |
                                                            Age: {ProviderMemberChildDataEdit.Age ? ProviderMemberChildDataEdit.Age : 'Null'}  <br></br><br></br>
                                                            <b> Description for Update:</b></span></label>
                                                        <textarea onChange={e => setUpdateAdultData({ ...updateAdultData, description: e.target.value })}></textarea>
                                                        <div className="req-update-action">
                                                            <Link to="#" onClick={hideUpdateChildDiv} className="close">close</Link>
                                                            <Link to="#" onClick={() => onChildUpdateSubmit(ProviderMemberChildDataEdit.ProviderChildName)} className="normal">Submit</Link>
                                                        </div>

                                                    </div> : ''}

                                                    {AddChild ? <div className="req-update-textarea">
                                                        <label><span><b>Request Addition </b> -  Children   
                                                            {/* Name:{ProviderMemberChildDataEdit.Name ? ProviderMemberChildDataEdit.Name : 'Null'}   |
                                                            Age: {ProviderMemberChildDataEdit.Age ? ProviderMemberChildDataEdit.Age : 'Null'} */}
                                                             <br></br>
                                                             <br></br>
                                                            <b> Description for Addition:</b>

                                                        </span></label>
                                                        <textarea onChange={e => setUpdateAdultData({ ...updateAdultData, description: e.target.value })}></textarea>
                                                        <div className="req-update-action">
                                                            <Link to="#" onClick={hideUpdateChildDiv} className="close">close</Link>
                                                            <Link to="#" onClick={() => onChildAdditionSubmit(ProviderMemberChildDataEdit.ProviderChildName)} className="normal">Submit</Link>
                                                        </div>
                                                    </div> : ''}






                                                    <div className="house-hold-table">
                                                        {/* <table>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Gender</th>
                                                                <th>Type</th>
                                                                <th>Age</th>
                                                                <th>Begin Date</th>
                                                                <th>End Date</th>
                                                                <th width="40px"></th>
                                                            </tr>
                                                            <tr>
                                                                <td>Stovall, Rebbaca</td>
                                                                <td>Male</td>
                                                                <td>NA</td>
                                                                <td>08</td>
                                                                <td>mm/dd/yyyy</td>
                                                                <td>mm/dd/yyyy</td>
                                                                <td className="table-act"><Link to=""><img src={editicon} alt="" /></Link></td>
                                                            </tr>
                                                        </table> */}

                                                        <DataTable columns={childrencolumns} data={ProviderMemberChildData} pagination={true} />

                                                    </div>

                                                </div>
                                                : ''}
                                            {showPet ?
                                                <div>
                                                    {/* //update */}
                                                    <div className="form-tab-3 m-bottom">
                                                        {updatePet ? <div className="req-update-textarea">
                                                            <label><span><b>Request Update </b> -  Pets  |

                                                                Pet:{petDataEdit.Pet ? petDataEdit.Pet : 'Null'}   |
                                                                Quantity: {petDataEdit.Quantity ? petDataEdit.Quantity : 'Null'}
                                                                <br></br>
                                                                <br></br>
                                                                <b> Description for Update:</b></span></label>
                                                            <textarea onChange={e => setUpdateAdultData({ ...updateAdultData, description: e.target.value })} ></textarea>
                                                            <div className="req-update-action">
                                                                <Link to="#" onClick={hideUpdatePetDiv} className="close">close</Link>
                                                                <Link to="#" onClick={() => onPetUpdateSubmitUpdate(petDataEdit.Pet)} className="normal">Submit</Link>
                                                            </div>
                                                        </div> : ''}

                                                        {AddPet ? <div className="req-update-textarea">
                                                            <label><span><b>Request Addition </b> -  Pets  

                                                                {/* Pet:{petDataEdit.Pet ? petDataEdit.Pet : 'Null'}   |
                                                            Quantity: {petDataEdit.Quantity ? petDataEdit.Quantity : 'Null'} */}
                                                             <br></br>
                                                             <br></br>
                                                            <b> Description for Addition:</b>
                                                            </span></label>
                                                            <textarea onChange={e => setUpdateAdultData({ ...updateAdultData, description: e.target.value })} ></textarea>
                                                            <div className="req-update-action">
                                                                <Link to="#" onClick={hideUpdatePetDiv} className="close">close</Link>
                                                                <Link to="#" onClick={() => onPetUpdateSubmitUpdate(petDataEdit.Pet)} className="normal">Submit</Link>
                                                            </div>
                                                        </div> : ''}





                                                    </div>


                                                    {/* //addition div */}

                                                    <div className="form-tab-3 m-bottom">
                                                        {additionPet ? <div className="req-update-textarea">
                                                            <label><span><b>Request Addition </b> -  Pets 
                                                            <br></br>
                                                            <br></br>
                                                            <b> Description for Addition:</b></span></label>
                                                            <textarea onChange={e => setUpdateAdultData({ ...updateAdultData, description: e.target.value })}></textarea>
                                                            <div className="req-update-action">
                                                                <Link to="#" onClick={HideAdditionPetDiv} className="close">close</Link>
                                                                <Link to="#" onClick={onPetAdditionSubmit} className="normal">Submit</Link>
                                                            </div>
                                                        </div> : ''}



                                                    </div>

                                                    <div className="house-hold-table">

                                                        {/* <table>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Gender</th>
                                                                <th>Type</th>
                                                                <th>Age</th>
                                                                <th>Begin Date</th>
                                                                <th>End Date</th>
                                                                <th width="40px"></th>
                                                            </tr>
                                                            <tr>
                                                                <td>Stovall, Rebbaca</td>
                                                                <td>Male</td>
                                                                <td>NA</td>
                                                                <td>08</td>
                                                                <td>mm/dd/yyyy</td>
                                                                <td>mm/dd/yyyy</td>
                                                                <td className="table-act"><Link to="#" onClick={showUpdatePetDiv}><img src={editicon} alt="" /></Link></td>
                                                            </tr>
                                                        </table> */}

                                                        <DataTable columns={petcolumns} data={petData} pagination={true} />


                                                    </div>


                                                </div>

                                                : ''}
                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}




export default Myhousehold;
