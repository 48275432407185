import Footer from "../../Footer";
import Header from "../../../frontendPages/Header";
import pdf from "../../../images/assets/pdf.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from 'axios';
import LoadSpinner from '../../Loader/LoadSpinner';

function ParentHandBook() {
  const [ParentDocumentData, setParentDocumentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchData = async () => {
    setIsLoading(true)
    let data = await fetch("/api/parentdocument/index");
    if (!data.ok) {
      return;
    }
    data = await data.json();
    console.log(data);
    setParentDocumentData(data.data);
    setIsLoading(false)
  };

  const removeRow = async (id) => {
    setIsLoading(true)
    console.log(id)
    // setIsLoading(true)
    const temp = [...ParentDocumentData];
    const primaryKey = id;
    temp.splice(id, 1);
    try {
        await axios.delete(`/api/parentdocument/delete/${encodeURIComponent(primaryKey)}`);
        setParentDocumentData(temp);
        window.location.reload(false);
    } catch (err) { }
    setIsLoading(false)
};


  const DowloadPdf = async (name) => {
    // setIsLoading(true)
    console.log(name);
    return await fetch("/api/parentdocument/files/" + name).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = name;
        a.click();
      });
    });
    // setIsLoading(false)
  };

  useEffect(() => {
    fetchData();
    // fetchParentDocumentDataType();
  }, []);
  return (
    <div className="main">
      <Header />
      <div className="parent-main">
        <div className="container">
          <h2>DOWNLOADABLE FOSTER PARENT HANDBOOK</h2>
          <p>
            A Programme That Can view pdf files is required.The Most common Free
            Programme is Acrobat Reader and can be downloaded from &nbsp;
            <Link
              to={{ pathname: "http.get.adobe.com/reader" }}
              replace
              target="_blank"
            >
              http.get.adobe.com/reader
            </Link>{" "}
          </p>
          <ul>
            {ParentDocumentData.filter(
              (pItem) => pItem.ParentDocumentTypeID == "1"
            ).map((pItem) => {
              return (
                <li className="download-item">
                  <h3>{pItem.Title} </h3>

                  <div
                    dangerouslySetInnerHTML={{ __html: pItem.Description }}
                  />
                  <Link to="#" onClick={() => DowloadPdf(pItem.FilePath)}>
                    <img src={pdf} alt="x" />
                    Download
                  </Link> 
                  <Link onClick={()=>removeRow(pItem.ParentDocumentsID)} to="#" >
                    Delete
                  </Link>
                  
                  <p>{pItem.FilePath}</p>
                </li>
              );
            })}
            {/* <li className="download-item">
                            <h3>Foster parent report to the court</h3>
                            <Link path="#"><img src={pdf} alt="x"/>Download</Link>
                            <p>Foster parent report to the court</p>
                        </li>
                        <li className="download-item">
                            <h3>Foster parent HandBook-Updated 2020 Decembers </h3>
                            <Link to="#"><img src={pdf} alt="x" />Download</Link>
                            <p>Foster parent HandBook-Updated 2020 Decembers</p>
                        </li>
                        */}
          </ul>
        </div>
        {isLoading ? <LoadSpinner /> : ""}
      </div>
      <Footer />
    </div>
  );
}
export default ParentHandBook;
