import Footer from '../../Footer';
import Header from '../../../frontendPages/Header';
import respused from '../../../images/assets/currentChildren/respused.svg';
import '../../CurrentChildren/ImmidiateAssesmentCurrentChildren/ImmidiateAssesmentCurrentChildren.scss'
import CurrentChildrenSideBar from '../CurrentChildrenSidebar/CurrentChildrenSidebar';
import HeaderTopDetail from '../HeaderTopDetail/HeaderTopDetail';
import edittwo from '../../../images/assets/currentChildren/edittwo.svg';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { post } from '../../../utils/common';
import DataTable from 'react-data-table-component';
import LoadSpinner from '../../Loader/LoadSpinner';
import { useEffect } from 'react';
import mail from '../../../images/mail.png';
import plus from '../../../images/assets/cm/plus.svg';



function RespiteUsed() {
    const [data, setData] = useState([]);
    const [selectedDataForEdit, setSelectedDataForEdit] = useState([])
    const [isLoading, setIsLoading] = useState(false);


    // const fetchData = async () => {
    //     const providerid = sessionStorage.getItem('providerSessionId');
    //     const childid = sessionStorage.getItem('childrenId');

    //     let data = await post('/api/respiteused/index', { childid, providerid });
    //     console.log(data)
    //     setData(data.data.data);
    // };

    const requestEdit = (rowData) => {
        setShowUpdateDiv(true)
        setShowAddDiv(false)
        setUpdateData(data[rowData])
        setSelectedDataForEdit(data[rowData])
        // console.log(selectedDataForEdit)
    }

    const [updateDiv, setShowUpdateDiv] = useState(false);
    const [AddDiv, setShowAddDiv] = useState(false);

    const showUpdateDiv = () => {
        setShowUpdateDiv(true)
        setShowAddDiv(false)
        
    }

    const hideUpdateDiv = () => {
        setShowUpdateDiv(false)
    }




    const showAddDiv = () => {
        setShowAddDiv(true)
        setShowUpdateDiv(false)
    }

    const hideAddDiv = () => {
        setShowAddDiv(false)
    }

    const [childrenData, setChildrenData] = useState([]);
    const fetchChildData = async () => {
        const id = sessionStorage.getItem('providerSessionId');
        let data = await post('/api/currentchildren/index', { id });
        setChildrenData(data.data.data);

        
        let children_id = data.data.data[0].ClientID;
        setActiveId(children_id)

        let provider_id = id
        let detailData = await post('/api/currentchildren/respite-used', { provider_id, children_id });
        console.log("detailData",detailData)

        setData(detailData.data.data);
    };


    const [activeId, setActiveId] = useState();

    const handleCurrentChildGlobalRespiteUsed = async (children_id) => {
      
        setIsLoading(true);
        setActiveId(children_id)
        setShowAddDiv(false)
        setShowUpdateDiv(false)
        sessionStorage.setItem("childrenId", children_id);
        const provider_id = sessionStorage.getItem('providerSessionId');
        let data = await post('/api/currentchildren/respite-used', { children_id, provider_id });
        

        let detaildata = await post('/api/currentchildren/children-detail', { provider_id, children_id });
        sessionStorage.setItem("current_child", detaildata.data.data.length > 0 ? detaildata.data.data[0].ClientName : "Null");


       // console.log("+++++++++++++++++++++",data.data.data)
        setData(data.data.data);
        setIsLoading(false);
        // window.location.reload(false)
    }
    

    const [updateData, setUpdateData] = useState([])

    const onUpdateClick = async () => {
      
        
        
        
       // setIsLoading(true);


        let username = sessionStorage.getItem('username');
        setShowUpdateDiv(false)
        setShowAddDiv(false)
        setUpdateData([])
        await post('/api/respiteused/update-respiteused', { username, updateData });
        alert('Request Update  Emailed Successfully !');
       // window.location.reload(false)
        // alert("Submitted Successfully");
        setIsLoading(false);
      //  window.location.reload(false)
    }


    const onAddDivsubmit = async () => {
       
        
        
        
        
      //  setIsLoading(true);


        let username = sessionStorage.getItem('username');
        setShowUpdateDiv(false)
        setShowAddDiv(false)
        setUpdateData([])
        await post('/api/respiteused/update-respiteused-addition', { username, updateData });
        alert('Request Addition  Emailed Successfully !');
        setIsLoading(false);
       // window.location.reload(false)
        // alert("Submitted Successfully");
       // window.location.reload(false)
    }





    useEffect(() => {
        // fetchData();
        fetchChildData()
    }, []);


    console.log("child", childrenData)

    const columns = [

        {


            name: 'Name Of Respite',
            selector: 'Name_Of_Respite',
            sortable: true,
        },
        {


            name: 'Level Of Care',
            selector: 'Level_of_Care',
            sortable: true,
        },
        {


            name: 'BeginDate',
            selector: 'Begin_Date',
            cell: (row, j) => row.Begin_Date?(row.Begin_Date).slice(0,10):'NULL',
            //cell: (row, j) => (new Date(row.BeginDate).toLocaleDateString()),
            sortable: true,
        },
        {


            name: 'EndDate',
            selector: 'End_Date',
            cell: (row, j) =>row.End_Date?(row.End_Date).slice(0,10):'NULL',
            //cell: (row, j) => (new Date(row.EndDate).toLocaleDateString()),
            sortable: true,
        },



        {
            name: 'Action',
            sortable: true,
            cell: (row, j) => (
                <div class="inline-flex">

                    <Link onClick={() => requestEdit(j)}

                        class="text-pink-500 bg-transparent border border-solid border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        to="#"
                    >
                        <img src={mail} alt="edit"></img>
                    </Link>

                </div>
            ),
        },
    ];


    return (

        <div className="main">
            <Header />
            <div className="main-content" >
                <HeaderTopDetail />
                <div className="pagewithsidebar">
                    <div className="container">
                        <div className="pagewithsidebar-inner">
                            <CurrentChildrenSideBar />
                            <div className="tab-detail">
                                <div className="tab-detail-top childprofile-tabs">
                                    <div className="children-section">
                                        <img src={respused} alt="" />
                                        <div className="pagesubtitle">
                                            Respites Used
                                        </div>
                                    </div>
                                    <Link to="#" onClick={showAddDiv} className="btn-big"><img src={plus} style={{"height": "18px"}} alt="#"/>Request Addition</Link>

                                </div>
                                <div className="children-tab">
                                    {childrenData.map(child => {
                                        return <span className={activeId === child.ClientID ? "active" : ""} onClick={() => handleCurrentChildGlobalRespiteUsed(child.ClientID)}>{child.ClientName}</span>
                                    })}
                                    {/* <span className="active">Easton Elliott</span> | <span>Easton Easton</span> | <span>Anna Litchfield</span> */}
                                </div>
                                {isLoading ? (<LoadSpinner />) : ''}
                                <div className="tab-detail-bottom children-data">
                                    <form className="children-doc-form">
                                        {/* <table>
                                            <tr>
                                                <th className="preventative-measure">Preventative Measure</th>
                                                <th>Level of Care</th>
                                                <th className="beign-date">Begin Date</th>
                                                <th className="beign-date">End Date</th>
                                                <th className="download-action"></th>
                                            </tr>
                                            <tr>
                                                <td>Leake, Catherine</td>
                                                <td>Respite Care</td>
                                                <td>12/18/2020</td>
                                                <td>01/04/2021</td>
                                                <td className="download-icon" align="right"><Link to="#"><img src={edittwo} alt="" /></Link></td>
                                            </tr>
                                            <tr>
                                                <td>Leake, Catherine</td>
                                                <td>Respite Care</td>
                                                <td>12/18/2020</td>
                                                <td>01/04/2021</td>
                                                <td className="download-icon" align="right"><Link to="#"><img src={edittwo} alt="" /></Link></td>
                                            </tr>
                                        </table> */}

                                        {updateDiv ? <div className="req-update-textarea">
                                            <label><span><b>Request Update for</b> -  Respite Used
                                                <b> Child: {sessionStorage.getItem("current_child")} | </b>
                                                Name of Respite: {selectedDataForEdit.Name_Of_Respite ? selectedDataForEdit.Name_Of_Respite : ''}    |
                                                Level of Care: {selectedDataForEdit.Level_of_Care ? selectedDataForEdit.Level_of_Care : ''} |
                                                Begin Date: {selectedDataForEdit.Begin_Date ? (selectedDataForEdit.Begin_Date).slice(0,10): ''}
                                                <br></br>
                                                <br></br>
                                           <b> Description for Update:</b></span></label>
                                            <textarea onChange={e => setUpdateData({ ...updateData, description: e.target.value })}> </textarea>
                                            <div className="req-update-action">
                                                <Link to="#" onClick={hideUpdateDiv} className="close">close</Link>
                                                <Link to="#" onClick={onUpdateClick} className="normal">Submit</Link>
                                            </div>
                                        </div>
                                            : ''}


                                        {AddDiv ? <div className="req-update-textarea">
                                            <label><span><b>Request Addition for</b> -  Respite Used
                                            <b> Child: {sessionStorage.getItem("current_child")}  </b>
                                            <br></br>
                                             <br></br>
                                           <b> Description for Addition:</b> </span></label>
                                            <textarea onChange={e => setUpdateData({ ...updateData, description: e.target.value })}></textarea>
                                            <div className="req-update-action">
                                                <Link to="#" onClick={hideAddDiv} className="close">close</Link>
                                                <Link to="#" onClick={onAddDivsubmit} className="normal">Submit</Link>
                                            </div>
                                        </div>
                                            : ''}

                                        <DataTable columns={columns} data={data} pagination={true} />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}




export default RespiteUsed;
