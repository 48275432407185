import Footer from "../../Footer";
import Header from "../../Header";
import "../CriticalUnusualIncidentForm/CriticalUnusualIncidentForm.scss";
import "../ImmidiateAssesmentForm/ImmidiateAssesmentForm.scss";
import pouinner from "../../../images/assets/formimages/pouinner.svg";
import HeaderTopDetail from "../../CurrentChildren/HeaderTopDetail/HeaderTopDetail";
import FormSideBar from "../FormSidebar/FormSidebar";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import axios from "axios";
import LoadSpinner from "../../Loader/LoadSpinner";
import trashimage from "../../../images/assets/cm/trash.svg";
import editimage from "../../../images/assets/cm/edit.svg";

import DataTable from "react-data-table-component";
import { post, put } from "../../../utils/common";

function ManageDownLodableForm() {
  const history = useHistory();

  const [dataToPopulate, setDataToPopulate] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const storeData = async (e) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", dataToPopulate.FileName);
    formData.append("downlodableformdata", JSON.stringify(dataToPopulate));

    await axios
      .post("/api/downlodableform/store", formData, {
        // receive two parameter endpoint url ,form data
      })
      .then((res) => {
        // then print response status
        console.log(res.statusText);
        window.alert("Document Uploaded Successfully ");
        window.location.reload(false);
      });

    setIsLoading(false);
    setDataToPopulate([]);
  };

  const handleClose = () => {
    history.push("/form/formindex");
  };
  console.log(dataToPopulate);

  const [FileData, setFileData] = useState([]);
  const fetchData = async () => {
    let data = await fetch("/api/downlodableform/index");
    data = await data.json();

    setFileData(data.data);
    // setAllFileData(data.data)
  };

  const [AddDiv, setAddDiv] = useState(true);

  const [EditDiv, setEditDiv] = useState(false);

  const [EditDivData, setEditDivData] = useState([]);

  const EditFormData = async (j) => {
    setEditDiv(true);
    setAddDiv(false);
    setEditDivData(FileData[j]);
    window.location.href="#top"
  };

  const closeEditDiv = () => {
    window.location.reload(false);
  };

  const submitEditedData = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", EditDivData.FileName);
    formData.append("downlodableformdata", JSON.stringify(EditDivData));

    let id = EditDivData.Downloadableformid;
    await axios
      .post(
        "/api/downlodableform/update/" + id,
        formData,
        {
          // receive two parameter endpoint url ,form data
        }
      )
      .then((res) => {
        // then print response status
        console.log(res.statusText);
        window.alert("Document Updated  Successfully ");
        window.location.reload(false);
      });

    setIsLoading(false);
    setDataToPopulate([]);
  };

  const deleteFormData = async (j) => {
    setIsLoading(true);
    const temp = [...FileData];
    let id = temp[j].Downloadableformid;
    temp.splice(j, 1);
    // let data = await post("/api/downlodableform/delete/"+id);
    // alert("Data Deleted Successfully");

    // const temp = [...data];
    // const primaryKey = temp[index].AdoptionContactID;
    // temp.splice(index, 1);
    try {
      let data = await post("/api/downlodableform/delete/" + id);

      alert("Data Deleted Successfully");
      setFileData(temp);
    } catch (err) {}

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      name: "ID",
      selector: "Downloadableformid",
      sortable: true,
      wrap:true
    },
    {
      name: "Title",
      selector: "Title",
      sortable: true,
      wrap:true

    },
    {
      name: "Date",
      selector: "Date",
      cell: (row, j) => row.Date.slice(0, 10),

      // cell: (row, j) => ((new Date().getUTCFullYear() - new Date(row.DOB).getUTCFullYear())),
      sortable: true,
      wrap:true

    },
    {
      name: "Filename",
      selector: "Filename",
      // cell: (row, j) => row.End_Date ? (row.End_Date).slice(0, 10) : '',
      sortable: true,
      wrap:true

    },
    {
      name: "Type",
      selector: "Type",
      sortable: true,
      wrap:true

    },

    {
      name: "Action",
      sortable: true,
      cell: (row, j) => (
        <div class="inline-flex">
          <Link
            to="#"
            onClick={() => EditFormData(j)}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={editimage} alt="tag"></img>
          </Link>
          &nbsp;
          <Link
            to="#"
            onClick={() => deleteFormData(j)}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={trashimage} alt="tag"></img>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div className="main">
      <Header />

      <div className="main-content">
        <HeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <FormSideBar />
              {isLoading ? <LoadSpinner /> : ""}
              <div className={`tab-detail Critical`}>
                <div className="tab-detail-top">
                  <div className="Critical-unusual">
                    <img src={pouinner} alt="" />
                  </div>
                </div>
                <div className="tab-detail-bottom" id="top">
                  {AddDiv ? (
                    <div className="form-with-sidebar">
                      <form>
                        <div className="form-row dandt mt-0">
                          <div className="one-half">
                            <label for="birthday">Title</label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setDataToPopulate({
                                  ...dataToPopulate,
                                  Title: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="one-half">
                            <label for="birthday">Description</label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setDataToPopulate({
                                  ...dataToPopulate,
                                  Description: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className="form-row dandt mt-0">
                          <div className="one-half">
                            <label htmlFor="birthday">File Upload</label>
                            <input
                              type="file"
                              onChange={(e) =>
                                setDataToPopulate({
                                  ...dataToPopulate,
                                  FileName: e.target.files[0],
                                })
                              }
                            />
                          </div>
                          <div className="one-half">
                            <label htmlFor="birthday">Date</label>
                            <input
                              type="date"
                              onChange={(e) =>
                                setDataToPopulate({
                                  ...dataToPopulate,
                                  Date: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="one-half">
                            <label htmlFor="birthday">For</label>
                            <select
                              onChange={(e) =>
                                setDataToPopulate({
                                  ...dataToPopulate,
                                  Type: e.target.value,
                                })
                              }
                            >
                              <option>Select</option>
                              <option value="ALL_FORM">All Forms</option>
                              <option value="DCF_FORM">DCF Forms</option>
                              <option value="SFM_FORM">SFM Forms</option>
                              <option value="INITIAL_LICENSING_FORM">
                                Initial Licensing Forms
                              </option>
                              <option value="ANNUAL_RENEWAL_LICENSING">
                                Annual Renewal Licensing Forms
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="req-update-textarea pt-0 mt-25 b-0">
                          <div className="req-update-action">
                            <Link
                              to="#"
                              className="close"
                              onClick={handleClose}
                            >
                              Close
                            </Link>
                            <Link to="#" className="normal" onClick={storeData}>
                              Submit
                            </Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  ) : (
                    ""
                  )}
                  {EditDiv ? (
                    <div className="form-with-sidebar">
                      <form>
                        <div className="form-row dandt mt-0">
                          <div className="one-half">
                            <label for="birthday">Title</label>
                            <input value={EditDivData.Title}
                              type="text"
                              onChange={(e) =>
                                setEditDivData({
                                  ...EditDivData,
                                  Title: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="one-half">
                            <label for="birthday">Description</label>
                            <input value={EditDivData.Description}
                              type="text"
                              onChange={(e) =>
                                setEditDivData({
                                  ...EditDivData,
                                  Description: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className="form-row dandt mt-0">
                          <div className="one-half">
                            <label htmlFor="birthday">File Upload</label>
                            <input
                              type="file"
                              onChange={(e) =>
                                setEditDivData({
                                  ...EditDivData,
                                  FileName: e.target.files[0],
                                })
                              }
                            />
                          </div>
                          <div className="one-half">
                            <label htmlFor="birthday">Date</label>
                            <input value={EditDivData.Date.substring(0,10)}
                              type="date"
                              onChange={(e) =>
                                setEditDivData({
                                  ...EditDivData,
                                  Date: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="one-half">
                            <label htmlFor="birthday">For</label>
                            <select
                              onChange={(e) =>
                                setEditDivData({
                                  ...EditDivData,
                                  Type: e.target.value,
                                })
                              }
                            >
                              <option selected>{EditDivData.Type}</option>
                              <option value="ALL_FORM">All Forms</option>
                              <option value="DCF_FORM">DCF Forms</option>
                              <option value="SFM_FORM">SFM Forms</option>
                              <option value="INITIAL_LICENSING_FORM">
                                Initial Licensing Forms
                              </option>
                              <option value="ANNUAL_RENEWAL_LICENSING">
                                Annual Renewal Licensing Forms
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="req-update-textarea pt-0 mt-25 b-0">
                          <div className="req-update-action">
                            <Link
                              to="#"
                              className="close"
                              onClick={handleClose}
                            >
                              Close
                            </Link>
                            <Link to="#" className="normal" onClick={submitEditedData}>
                              Submit
                            </Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  ) : (
                    ""
                  )}
                  <DataTable
                    columns={columns}
                    data={FileData}
                    pagination={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ManageDownLodableForm;
