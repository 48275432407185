import Footer from "../../Footer";
import Header from "../../Header";
import reimhistory from "../../../images/assets/myinfo/reimhistory.svg";
import download from "../../../images/assets/myinfo/download.svg";
import edit from "../../../images/assets/myinfo/edit.svg";
import print from "../../../images/assets/myinfo/print.svg";
// import acmanage from '../../images/assets/images/ManageInfo.svg';
import SideBar from "../../sidebar/sidebar";
import "../LicenseException/LicenseException.scss";
import HeaderTopDetail from "../../CurrentChildren/HeaderTopDetail/HeaderTopDetail";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { post } from "../../../utils/common";
import { Link } from "react-router-dom";
import Mail from "nodemailer/lib/mailer";
import mail from "../../../images/mail.png";
import LoadSpinner from "../../Loader/LoadSpinner";

function LicenseException() {
  const [showLicense, showLicenseDiv] = useState(true);
  const [showLicenseException, showLicenseExceptionDiv] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const showLicensedivision = () => {
    showLicenseExceptionDiv(false);
    setShowUpdateDiv(false);
    setShowExceptionupdateDiv(false);
    showLicenseDiv(true);
  };
  const showLicenseExceptiondivision = () => {
    showLicenseExceptionDiv(true);
    setShowUpdateDiv(false);
    setShowExceptionupdateDiv(false);
    showLicenseDiv(false);
  };

  const [updateDiv, setShowUpdateDiv] = useState(false);
  const [ExceptionupdateDiv, setShowExceptionupdateDiv] = useState(false);

  const showUpdateDiv = () => {
    setShowUpdateDiv(true);
  };

  const hideUpdateDiv = () => {
    setShowUpdateDiv(false);
    setShowExceptionupdateDiv(false);
  };

  const [LicenseData, setLicenseData] = useState([]);
  const [ExceptionData, setLicenseExceptionData] = useState([]);
  const [LicenseDataForEdit, setLicenseDataForEdit] = useState([]);
  const [LicenseExceptionDataForEdit, setLicenseExceptionDataForEdit] =
    useState([]);

  const getLicenseByProviderId = async () => {
    const id = sessionStorage.getItem("providerSessionId");
    let ProviderLicenseData = await post(
      "/api/license/getlicensebyproviderid",
      { id }
    );
    console.log(ProviderLicenseData.data.data);
    setLicenseData(ProviderLicenseData.data.data);
  };

  const getLicenseExceptionByProviderId = async () => {
    const id = sessionStorage.getItem("providerSessionId");
    let ProviderLicenseExceptionData = await post(
      "/api/license/getlicenseexceptionproviderid",
      { id }
    );
    console.log(ProviderLicenseExceptionData.data);
    setLicenseExceptionData(ProviderLicenseExceptionData.data.data);
    console.log(ExceptionData);
  };

  const [updateData, setUpdateData] = useState([]);
  const onupdateClickLicense = async () => {
    // setIsLoading(true)
    setShowUpdateDiv(false);
    setUpdateData([]);
    let username = sessionStorage.getItem("username");
    await post("/api/license/update-license", { username, updateData });
    alert("Request Update  Emailed Successfully !");
    //
    //  setIsLoading(false)

    //  window.location.reload(false)
    // alert("Submitted Successfully");
    // window.location.reload(false)
  };

  const onupdateClickLicenseexception = async () => {
    //setIsLoading(true)
    let username = sessionStorage.getItem("username");
    setShowExceptionupdateDiv(false);
    setUpdateData([]);
    await post("/api/license/update-licenseexception", {
      username,
      updateData,
    });
    alert("Request Update  Emailed Successfully !");

    setIsLoading(false);
    //  window.location.reload(false)
    // alert("Submitted Successfully");
    // window.location.reload(false)
  };

  const requestEditLicenseException = (rowData) => {
    setShowExceptionupdateDiv(true);
    setShowUpdateDiv(false);
    setLicenseExceptionDataForEdit(ExceptionData[rowData]);
    console.log(LicenseExceptionDataForEdit);
  };

  const requestEditLicense = (rowData) => {
    setShowUpdateDiv(true);
    setShowExceptionupdateDiv(false);

    setLicenseDataForEdit(LicenseData[rowData]);
    console.log(LicenseDataForEdit);
  };

  useState(() => {
    // fetchData()
    getLicenseByProviderId();
    getLicenseExceptionByProviderId();
  }, []);

  const LicenseColumns = [
    {
      name: "License",
      selector: "License",
      sortable: true,
      wrap:true
    },
    {
      name: "Status",
      selector: "Status",
      sortable: true,
      wrap:true

    },
    {
      name: "License Number",
      selector: "License_Number",
      sortable: true,
      wrap:true

    },
    {
      name: "Capacity",
      selector: "Capacity",
      sortable: true,
      wrap:true

    },

    // {

    //     name: 'Oldest',
    //     selector: 'OldestLicensed',
    //     sortable: true,
    // },
    // {

    //     name: 'Youngest',
    //     selector: 'YoungestLicensed',
    //     sortable: true,
    // },

    {
      name: "Begin Date",
      selector: "Begin_Date",
      // cell: (row, j) => (new Date(row.BeginDate).toLocaleDateString()),
      sortable: true,
      wrap:true

    },
    {
      name: "End Date",
      selector: "End_Date",
      // cell: (row, j) => (new Date(row.EndDate).toLocaleDateString()),
      sortable: true,
      wrap:true

    },

    // {
    //     name: 'Action',
    //     sortable: true,
    //     cell: (row, j) => (
    //         <div class="inline-flex three-buttons">
    //             <button onClick={() => requestEditLicense(j)} type="button"

    //                 className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
    //             >
    //                 <img src={edit}></img>
    //             </button>
    //             <button type="button"

    //                 className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
    //             >
    //                 <img src={download}></img>
    //             </button>
    //             <button type="button"

    //                 className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
    //             >
    //                 <img src={print}></img>
    //             </button>

    //         </div>
    //     ),
    // },
  ];

  const LicenseExceptioncolumns = [
    {
      name: "Date",
      selector: "Date",
      // cell: (row, j) => (new Date(row.Date).toLocaleDateString()),
      sortable: true,
      wrap:true

    },
    {
      name: "Child Name",
      selector: "Child_Name",
      sortable: true,
      wrap:true

    },
    {
      name: "Reason",
      selector: "Reason",
      sortable: true,
      wrap:true

    },
    {
      name: "DCF Appoval",
      selector: "DCF_Approval",
      sortable: true,
      wrap:true

    },
    {
      name: "SFM Status",
      selector: "SFCS_Status",
      sortable: true,
      wrap:true

    },

    {
      name: "Action",
      sortable: true,
      cell: (row, j) => (
        <div class="inline-flex three-buttons">
          <Link
            to="#"
            onClick={() => requestEditLicenseException(j)}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
          >
            <img src={mail}></img>
          </Link>
          {/* <button type="button"

                        className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                    >
                        <img src={download}></img>
                    </button>
                    <button type="button"

                        className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                    >
                        <img src={print}></img>
                    </button> */}
        </div>
      ),
    },
  ];

  return (
    <div className="main">
      <Header />

      <div className="main-content">
        <HeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <SideBar />
              <div className="tab-detail License pl">
                <div className="tab-detail-top">
                  <div className="License-top">
                    <img src={reimhistory} alt="" />
                    <div className="pagesubtitle">Licensing</div>
                  </div>
                </div>
                <div className="tab-detail-bottom">
                  <form className="License-form">
                    <div className="tabui">
                      <ul>
                        <li>
                          <Link
                            to="#"
                            onClick={showLicensedivision}
                            className={showLicense ? "active" : ""}
                          >
                            Licensing
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#"
                            className={showLicenseException ? "active" : ""}
                            onClick={showLicenseExceptiondivision}
                          >
                            License Exception
                          </Link>
                        </li>
                      </ul>
                    </div>
                    {/* //license  div  */}

                    {updateDiv ? (
                      <div className="req-update-textarea">
                        <label>
                          <span>
                            <b>Request Update for - </b>Licensing <br></br>
                            <br></br>
                            <b> Description for Update:</b>
                          </span>
                        </label>

                        {/* LicenseNo:{LicenseDataForEdit.LicenseNo ? LicenseDataForEdit.LicenseNo : 'null'} |  */}

                        <textarea
                          onChange={(e) =>
                            setUpdateData({
                              ...updateData,
                              description: e.target.value,
                            })
                          }
                        ></textarea>
                        <div className="req-update-action">
                          <Link
                            to="#"
                            onClick={hideUpdateDiv}
                            className="close"
                          >
                            close
                          </Link>
                          <Link
                            to="#"
                            onClick={onupdateClickLicense}
                            className="normal"
                          >
                            Submit
                          </Link>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {isLoading ? <LoadSpinner /> : ""}

                    {/* //exception div  */}
                    {ExceptionupdateDiv ? (
                      <div className="req-update-textarea">
                        <label>
                          <span>
                            <b>Request Update for - </b>License Exception |
                            Date:
                            {LicenseExceptionDataForEdit.Date
                              ? LicenseExceptionDataForEdit.Date
                              : "Null"}{" "}
                            | Reason:{" "}
                            {LicenseExceptionDataForEdit.Reason
                              ? LicenseExceptionDataForEdit.Reason
                              : "Null"}{" "}
                            | Child Name:{" "}
                            {LicenseExceptionDataForEdit.Child_Name
                              ? LicenseExceptionDataForEdit.Child_Name
                              : "Null"}
                            <br></br>
                            <br></br>
                            <b> Description for Update:</b>
                          </span>
                        </label>

                        {/* LicenseNo:{LicenseDataForEdit.LicenseNo ? LicenseDataForEdit.LicenseNo : 'null'} |  */}
                        <textarea
                          onChange={(e) =>
                            setUpdateData({
                              ...updateData,
                              description: e.target.value,
                            })
                          }
                        ></textarea>
                        <div className="req-update-action">
                          <Link
                            to="#"
                            onClick={hideUpdateDiv}
                            className="close"
                          >
                            close
                          </Link>
                          <Link
                            to="#"
                            onClick={onupdateClickLicenseexception}
                            className="normal"
                          >
                            Submit
                          </Link>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="form-tabs">
                      {showLicense ? (
                        <div className="form-tab-1 m-bottom">
                          <DataTable
                            columns={LicenseColumns}
                            data={LicenseData}
                            pagination={true}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      {showLicenseException ? (
                        <div className="form-tab-1 m-bottom">
                          <DataTable
                            columns={LicenseExceptioncolumns}
                            data={ExceptionData}
                            pagination={true}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LicenseException;
