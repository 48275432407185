import Footer from "../../Footer";
import Header from "../../../frontendPages/Header";
import alog from "../../../images/assets/currentChildren/alog.svg";
import "../../CurrentChildren/ImmidiateAssesmentCurrentChildren/ImmidiateAssesmentCurrentChildren.scss";
import CurrentChildrenSideBar from "../CurrentChildrenSidebar/CurrentChildrenSidebar";
import HeaderTopDetail from "../HeaderTopDetail/HeaderTopDetail";
import edittwo from "../../../images/assets/currentChildren/edittwo.svg";
import { post } from "../../../utils/common";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

function ActivityLog() {
  const [isLoading, setIsLoading] = useState(false);
  const [childrenData, setChildrenData] = useState([]);
  const [LoginActivity, setLoginActivity] = useState([]);

  const fetchData = async () => {
    const id = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/currentchildren/index", { id });
    setChildrenData(data.data.data);
    let children_id = data.data.data[0].ClientID;
    setActiveId(children_id);
    let provider_id = id;
    let detailData = await post("/api/currentchildren/activity-log", {
      provider_id,
      children_id,
    });
    console.log("detailData", detailData);

    setLoginActivity(detailData.data.data);
  };

  const [activeId, setActiveId] = useState();

  const handleCurrentChildGlobal = async (children_id) => {
    // setShowAddDiv(false);
    // setShowUpdateDiv(false);
    // setUpdateData([]);

    setActiveId(children_id);

    setIsLoading(true);
    sessionStorage.setItem("childrenId", children_id);
    // setTimeout(10);
    const provider_id = sessionStorage.getItem("providerSessionId");

    let data = await post("/api/currentchildren/activity-log", {
      provider_id,
      children_id,
    });
    setLoginActivity(data.data.data);

    //common code
    // children_id = sessionStorage.getItem("childrenId");
    let detaildata = await post("/api/currentchildren/children-detail", {
      provider_id,
      children_id,
    });
    // setAttachDocument(detaildata.data.data);
    sessionStorage.setItem(
      "current_child",
      detaildata.data.data.length > 0
        ? detaildata.data.data[0].ClientName
        : "Null"
    );
    // console.log("Data", data[0])
    //common code end

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    // getLoginActivity();
  }, []);

  const columns = [
    {
      name: "section",
      selector: "section",
      sortable: true,
    },
    {
      name: "Last Updated",
      selector: "LastUpdated",
      cell: (row, j) => (row.LastUpdated!==null ? row.LastUpdated.slice(0, 10) : ""),

      sortable: true,
    },
  ];

  return (
    <div className="main">
      <Header />
      <div className="main-content">
        <HeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <CurrentChildrenSideBar />
              <div className="tab-detail pl">
                <div className="tab-detail-top childprofile-tabs">
                  <div className="children-section">
                    <img src={alog} alt="" />
                    <div className="pagesubtitle">Activity Log</div>
                  </div>
                </div>
                <div className="children-tab">
                  {childrenData.map((child) => {
                    return (
                      <span
                        className={activeId === child.ClientID ? "active" : ""}
                        onClick={() => handleCurrentChildGlobal(child.ClientID)}
                      >
                        {child.ClientName}
                      </span>
                    );
                  })}
                </div>
                <div className="tab-detail-bottom children-data">
                  <form className="children-doc-form">
                    <h6 className="blue-color-heading">
                      Changes Since Last Logon
                    </h6>
                    {/* <table>
                      <tr>
                        <th>LOC Due Date:</th>
                        <th>Strengths:</th>
                        <th>Profile:</th>
                        <th>Medications:</th>
                        <th>Placement Referral:</th>
                        <th>Case Team:</th>
                        <th>Placement History:</th>
                        <th>Respite:</th>
                      </tr>
                      <tr>
                        <td>No change since last logon</td>
                        <td>No change since last logon</td>
                        <td>No change since last logon</td>
                        <td>No change since last logon</td>
                        <td>No change since last logon</td>
                        <td>No change since last logon</td>
                        <td>10/14/2020</td>
                        <td>04/27/2021</td>
                      </tr>
                      <tr>
                        <td>No change since last logon</td>
                        <td>No change since last logon</td>
                        <td>No change since last logon</td>
                        <td>No change since last logon</td>
                        <td>No change since last logon</td>
                        <td>No change since last logon</td>
                        <td>10/14/2020</td>
                        <td>04/27/2021</td>
                      </tr>
                    </table> */}
                    <DataTable
                      columns={columns}
                      data={LoginActivity}
                      pagination={false}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ActivityLog;
