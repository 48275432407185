import Footer from "../../Footer";
import Header from "../../Header";
import "../CriticalUnusualIncidentForm/CriticalUnusualIncidentForm.scss";
import Myinfoinner from "../../../images/assets/form/Myinfoinner.svg";
import HeaderTopDetail from "../../CurrentChildren/HeaderTopDetail/HeaderTopDetail";
import FormSideBar from "../FormSidebar/FormSidebar";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { post } from "../../../utils/common";
import { useHistory } from "react-router";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useRef } from "react";
import axios from 'axios';


import LoadSpinner from "../../Loader/LoadSpinner";

function CriticalUnusualIncidentForm() {
  const history = useHistory();
  const inputRef = useRef(null);

  const [AddFormData, setAddFormData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSelectDiv, setShowSelectDiv] = useState(true);

  const [selectIncidentData, setSelectIncidentData] = useState([]);
  const [selectChildData, setSelectChildData] = useState([]);
  const [personReportingData, setPersonReportingData] = useState([]);
  const [IncidentTypeData, setIncidentTypeData] = useState([]);
  const [childInjuryData, setChildInjuryData] = useState([]);
  const [fosterParentInjuryData, setFosterParentInjuryData] = useState([]);
  const [childSelectedForData, setChildSelectedForData] = useState([]);


  const [selectedIncidentId, setSelectedIncidentId] = useState([]);
  const [FormdatabyIncidentIdLoaded, setFormDatabyIncidentIdLoaded] = useState(
    []
  );
  const [
    FormdatabyIncidentIdLoadedStatus,
    setFormDatabyIncidentIdLoadedStatus,
  ] = useState(false);

  const [sfcsWorker, setsfcsWorker] = useState([]);
   
  const fetchSFCSWorker = async () => {
    // const providerid = sessionStorage.getItem('providerSessionId');
    const provider_id = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/sfcsworker/index", { provider_id });
    // if (!data.ok) {
    //     return;
    // }
    // data = await data.json();
    console.log("dcsd", data);
    setsfcsWorker(data.data.data[0]);
   
  };

  const FetchFormLoad = async () => {
    setIsLoading(true);
    let providerid = sessionStorage.getItem("providerSessionId");
    FormdatabyIncidentIdLoaded.NameOfFosterParent = sessionStorage.getItem("ProviderName");
    FormdatabyIncidentIdLoaded.IdentifySaintFrancisOfficeLocation  = JSON.parse(sessionStorage.getItem("AddressData")).Office_Address;
    if(sessionStorage.RoleInfo === "FosterHome"){
      setShowSelectDiv(false); 
    }

    let data = await post("/api/unusualincident/unusualincidentformload", {
      providerid,
    });

    // console.log("selected Incident ", data.data.data.recordsets[4]);
    setSelectIncidentData(data.data.data.recordsets[4]);
    setChildInjuryData(data.data.data.recordsets[3]);
    setFosterParentInjuryData(data.data.data.recordsets[3]);
    setPersonReportingData(data.data.data.recordsets[1]);
    setSelectChildData(data.data.data.recordsets[0]);
    setIncidentTypeData(data.data.data.recordsets[2]);
    setIsLoading(false);
  };

  function toNumber(value) {
    return Number(value);
  }

  const childSelectedData = (event) => {
    const childId = event.target.value;
    selectChildData.map((childData) => {
      if (childData.ClientID == childId) {
        setChildSelectedForData(childData);
        FormdatabyIncidentIdLoaded.ClientID =childId;
     FormdatabyIncidentIdLoaded.dateofbirth = childData.DOB;
     
      }
    });
    // selectChildData.filter(child=>child.ClientID == childId)
  };
  //  var arr = ["1", "2", "3"];
  //  var nums = arr.map(toNumber);
  const selectedUnusualIncidentList = [];
  const onSubmitButtonClick = async (status) => {
    let isRadioTrue =""
    if(FormdatabyIncidentIdLoaded.SaintFrancis == undefined && FormdatabyIncidentIdLoaded.JJA == undefined &&
      FormdatabyIncidentIdLoaded.KVC == undefined && FormdatabyIncidentIdLoaded.TFI == undefined &&
      FormdatabyIncidentIdLoaded.COC == undefined && FormdatabyIncidentIdLoaded.DCF == undefined &&
      FormdatabyIncidentIdLoaded.Other == undefined ){
   isRadioTrue = "true";
      }
   else{
    isRadioTrue = "false";
   }
      
    if(FormdatabyIncidentIdLoaded.AdultsInvolved == undefined || FormdatabyIncidentIdLoaded.AdultsInvolved == "" || FormdatabyIncidentIdLoaded.Client_InjuryTypeID == undefined ||
       FormdatabyIncidentIdLoaded.DiscoveryDate == undefined || FormdatabyIncidentIdLoaded.DiscoveryTime == undefined ||
        FormdatabyIncidentIdLoaded.IdentifySaintFrancisOfficeLocation == undefined || FormdatabyIncidentIdLoaded.ImmediateActionTaken == undefined || FormdatabyIncidentIdLoaded.ImmediateActionTaken == "" ||
        FormdatabyIncidentIdLoaded.IncidentDate == undefined || FormdatabyIncidentIdLoaded.IncidentDescription == undefined || FormdatabyIncidentIdLoaded.IncidentDescription == "" ||
        FormdatabyIncidentIdLoaded.IncidentTime == undefined || FormdatabyIncidentIdLoaded.IsHomeVisitIncident == undefined || 
        FormdatabyIncidentIdLoaded.NameOfFosterParent == undefined || FormdatabyIncidentIdLoaded.ProviderMemberID == undefined || 
        isRadioTrue == "true" || FormdatabyIncidentIdLoaded.Staff_InjuryTypeID == undefined ||
         FormdatabyIncidentIdLoaded.ClientID == undefined || FormdatabyIncidentIdLoaded.dateofbirth == undefined)
        //  FormdatabyIncidentIdLoaded.dateofbirth == undefined  || FormdatabyIncidentIdLoaded.SaintFrancis == undefined || 
        //  FormdatabyIncidentIdLoaded.JJA == undefined || FormdatabyIncidentIdLoaded.KVC == undefined ||
        //   FormdatabyIncidentIdLoaed.TFI == undefined || FormdatabyIncidentIdLoaded.COC == undefined ||
        //    FormdatabyIncidentIdLoaded.DCF == undefined|| FormdatabyIncidentIdLoaded.othertextfield == undefined 
     { 
     alert("Please enter the Mandatory fields");
      return;
     } 
     
   
    let Status;
    if(status == "fromSubmit")
    FormdatabyIncidentIdLoaded.Status = "Completed";
    //finding list of selected incidenttype and id pair

    let Administration = FormdatabyIncidentIdLoaded.AdminContact + "<br>" + 
    FormdatabyIncidentIdLoaded.AdminDate + "<br>" +
    FormdatabyIncidentIdLoaded.AdminTime  + "<br>" +
    FormdatabyIncidentIdLoaded.AdminEmail + "<br>" +
    FormdatabyIncidentIdLoaded.AdminBywhom ;

    let ProtectionReportingCenter = FormdatabyIncidentIdLoaded.ReportingCenterContact + "<br>" +
    FormdatabyIncidentIdLoaded.ReportingCenterByDate +  "<br>" +
    FormdatabyIncidentIdLoaded.ReportingCenterTime + "<br>"  +
    FormdatabyIncidentIdLoaded.ReportingCenterEmail + "<br>" +
    FormdatabyIncidentIdLoaded.ReportingCenterByWhom ;

    let SaintFrancisAgencyVerbalNotificationImmediately = FormdatabyIncidentIdLoaded.SFAVerbalNotificationContact + "<br>" +
    FormdatabyIncidentIdLoaded.SFAVerbalNotificationDate + "<br>" +
    FormdatabyIncidentIdLoaded.SFAVerbalNotificationTime + "<br>" +
    FormdatabyIncidentIdLoaded.SFAVerbalNotificationEmail + "<br>" +
    FormdatabyIncidentIdLoaded.SFAVerbalNotificationByWhom ;

    let SaintFrancisAgencyWrittenNotification = FormdatabyIncidentIdLoaded.SFAWrittenNotificationContact + "<br>" +
    FormdatabyIncidentIdLoaded.SFAWrittenNotificationDate + "<br>" +
    FormdatabyIncidentIdLoaded.SFAWrittenNotificationTime + "<br>" +
    FormdatabyIncidentIdLoaded.SFAWrittenNotificationEmail + "<br>" +
    FormdatabyIncidentIdLoaded.SFAWrittenNotificationByWhom ;


    let Police_Sheriff = FormdatabyIncidentIdLoaded.Police_SheriffContact + "<br>" +
    FormdatabyIncidentIdLoaded.Police_SheriffDate + "<br>" +
    FormdatabyIncidentIdLoaded.Police_SheriffTime + "<br>" +
    FormdatabyIncidentIdLoaded.Police_SheriffEmail + "<br>" +
    FormdatabyIncidentIdLoaded.Police_SheriffByWhom ;
    

    let list = userinfo.languages;

    let nums = list.map(toNumber);

    let selectedUnusualIncidentList = nums;

    console.log("selectedUnusualIncidentList", selectedUnusualIncidentList);

    console.log("FormData", FormdatabyIncidentIdLoaded);
    //e.preventDefault();
    FormdatabyIncidentIdLoaded.IncidentDescription = (FormdatabyIncidentIdLoaded.IncidentDescription).replace(/'/g,"''");
    FormdatabyIncidentIdLoaded.AdultsInvolved = (FormdatabyIncidentIdLoaded.AdultsInvolved).replace(/'/g,"''");
    FormdatabyIncidentIdLoaded.ImmediateActionTaken  = (FormdatabyIncidentIdLoaded.ImmediateActionTaken).replace(/'/g,"''");
    let providerid = parseInt(sessionStorage.getItem("providerSessionId"));
    let changedBy = sessionStorage.getItem("username");
    let ResourceWorkerD = sfcsWorker.Resource_Worker;
    let ResourceWorkermailID = sfcsWorker.EmailSend;
    let ActualProviderName =sessionStorage.getItem("ProviderName");

    let data = JSON.parse(sessionStorage.getItem("ProviderResourceWorkerData")).Email;
    // let UnusualIncident_FosterParentID = sessionStorage.getItem(
    //   "UnusualIncident_FosterParentID"
    // );
    html2canvas(inputRef.current).then(async (canvas) => {
      const imgData = canvas.toDataURL("image/jpeg");
      var doc = new jsPDF("p", "mm", "a4");
      var pageHeight = 295;
      var imgWidth = (canvas.width * 31) / 210;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      var position = 31;

      doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      setIsLoading(true)

      const out = doc.output("datauristring");
      const response = await axios.post("/api/unusualincident/CriticalUnusualIncidentmail",
        {
          pdf: out.split("base64,")[1],
          FormdatabyIncidentIdLoaded,
          ResourceWorkermailID,
          ActualProviderName,
        }
      );
      alert("Email Sent");

      window.location.reload(false);
    });
    await post("/api/unusualincident/setallformdata", {
      FormdatabyIncidentIdLoaded,
      changedBy,
      providerid,
      selectedUnusualIncidentList,
      Administration,
      ProtectionReportingCenter,
      SaintFrancisAgencyVerbalNotificationImmediately,
      SaintFrancisAgencyWrittenNotification,
      Police_Sheriff,
      Status,
      ResourceWorkerD,
      ResourceWorkermailID,
      ActualProviderName,
      data
      
    });
    alert("Record Added successfully !");
    let fromButtonClick= localStorage.getItem("fromButtonClick");
    if(fromButtonClick === "true")
    window.location.href ="/criticalunusualincident";
   // 
    history.push("/form/formindex");

  };

  const onUpdateButtonClick = () => {
    console.log("FormData", FormdatabyIncidentIdLoaded);
    alert("Updated");
    window.location.reload(false);
  };
  const selectClick = async () => {
    setIsLoading(true);
    sessionStorage.setItem(
      "UnusualIncident_FosterParentID",
      FormdatabyIncidentIdLoaded.UnusualIncident_FosterParentID
    );
    const UnusualIncident_FosterParentID =
      FormdatabyIncidentIdLoaded.UnusualIncident_FosterParentID;
    let providerid = sessionStorage.getItem("providerSessionId");
    let data = await post("/api/unusualincident/getdatabyselectedincidentid", {
      providerid,
      UnusualIncident_FosterParentID,
    });

    setFormDatabyIncidentIdLoaded(data.data.data[0]);
    setFormDatabyIncidentIdLoadedStatus(true);

    console.log("sdsd", data.data.data[0]);
    setIsLoading(false);
  };

  const closeClicked = () => {

    let fromButtonClick= localStorage.getItem("fromButtonClick");
    if(fromButtonClick === "true")
    window.location.href ="/criticalunusualincident";


    history.push("/form/formindex");
  };
  const deleteClick = () => {
    alert(" Deleted successfully !");
    window.location.reload(false);
  };

  // const [selectedUnusualTypeId, setUnusualTypeId] = useState({
  //   UnusualIncidentTypeID:[],
  //   IncidentType:[]
  // });

  // const handleIncidentType = (e) => {
  //   const { value, checked } = e.target;
  //   const { IncidentType } = selectedUnusualTypeId;
  //   if (checked) {
  //     setUnusualTypeId({
  //       IncidentType: [...selectedUnusualTypeId, value],
  //       UnusualIncidentTypeID: [...selectedUnusualTypeId, value],
  //     });
  //   }

  //   // Case 2  : The user unchecks the box
  //   else {
  //     setUnusualTypeId({
  //       IncidentType: selectedUnusualTypeId.filter((e) => e !== value),
  //       UnusualIncidentTypeID: selectedUnusualTypeId.filter((e) => e !== value),
  //     });
  //   }

  // };

  //test

  const [userinfo, setUserInfo] = useState({
    languages: [],
    response: [],
  });

  const EnableDisableTB = (e, value) => {
    var Other = document.getElementById("Other");
    var Identify = document.getElementById("Identify");
    Identify.disabled = Other.checked ? false : true;
    Identify.value = "";
    if (!Identify.disabled) {
      Identify.focus();
    }
    if (value == "SaintFrancis" && e.target.checked == true){

      FormdatabyIncidentIdLoaded.JJA = false;
      FormdatabyIncidentIdLoaded.KVC = false;
      FormdatabyIncidentIdLoaded.TFI = false;
      FormdatabyIncidentIdLoaded.COC = false;
      FormdatabyIncidentIdLoaded.DCF = false;
      FormdatabyIncidentIdLoaded.Other = false;

    }
    else if (value == "JJA" && e.target.checked == true){

      FormdatabyIncidentIdLoaded.SaintFrancis = false;
      FormdatabyIncidentIdLoaded.KVC = false;
      FormdatabyIncidentIdLoaded.TFI = false;
      FormdatabyIncidentIdLoaded.COC = false;
      FormdatabyIncidentIdLoaded.DCF = false;
      FormdatabyIncidentIdLoaded.Other = false;
    }
    else if (value == "KVC" && e.target.checked == true){

      FormdatabyIncidentIdLoaded.SaintFrancis = false;
      FormdatabyIncidentIdLoaded.JJA = false;
      FormdatabyIncidentIdLoaded.TFI = false;
      FormdatabyIncidentIdLoaded.COC = false;
      FormdatabyIncidentIdLoaded.DCF = false;
      FormdatabyIncidentIdLoaded.Other = false;
    }
    else if (value == "TFI" && e.target.checked == true){

      FormdatabyIncidentIdLoaded.SaintFrancis = false;
      FormdatabyIncidentIdLoaded.JJA = false;
      FormdatabyIncidentIdLoaded.KVC = false;
      FormdatabyIncidentIdLoaded.COC = false;
      FormdatabyIncidentIdLoaded.DCF = false;
      FormdatabyIncidentIdLoaded.Other = false;
    }
    else if (value == "COC" && e.target.checked == true){

      FormdatabyIncidentIdLoaded.SaintFrancis = false;
      FormdatabyIncidentIdLoaded.JJA = false;
      FormdatabyIncidentIdLoaded.TFI = false;
      FormdatabyIncidentIdLoaded.KVC = false;
      FormdatabyIncidentIdLoaded.Identify = false;
      FormdatabyIncidentIdLoaded.Other = false;
    }
    else if (value == "DCF" && e.target.checked == true){

      FormdatabyIncidentIdLoaded.JJA = false;
      FormdatabyIncidentIdLoaded.KVC = false;
      FormdatabyIncidentIdLoaded.TFI = false;
      FormdatabyIncidentIdLoaded.COC = false;
      FormdatabyIncidentIdLoaded.SaintFrancis = false;
      FormdatabyIncidentIdLoaded.Other = false;
    }

    else  {

      FormdatabyIncidentIdLoaded.JJA = false;
      FormdatabyIncidentIdLoaded.KVC = false;
      FormdatabyIncidentIdLoaded.TFI = false;
      FormdatabyIncidentIdLoaded.COC = false;
      FormdatabyIncidentIdLoaded.SaintFrancis = false;
      FormdatabyIncidentIdLoaded.DCF = false;
    }
    
  };

  const handleChange = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { languages } = userinfo;

    console.log(`${value} is ${checked}`);

    // Case 1 : The user checks the box
    if (checked) {
      setUserInfo({
        languages: [...languages, value],
        response: [...languages, value],
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setUserInfo({
        languages: languages.filter((e) => e !== value),
        response: languages.filter((e) => e !== value),
      });
    }

    console.log("userinfo.response", userinfo.response);
  };

  //end

  console.log("userinfo", userinfo);

  useEffect(() => {
    FetchFormLoad();
    fetchSFCSWorker();
  }, []);

  // console.log("FormdatabyIncidentIdLoaded", FormdatabyIncidentIdLoaded);

  return (
    <div className="main">
      <Header />

      <div className="main-content">
        <HeaderTopDetail />
        <div className="pagewithsidebar">
          <div className="container">
            <div className="pagewithsidebar-inner">
              <FormSideBar />
              <div className="tab-detail Critical"  ref={inputRef}>
                <div className="tab-detail-top">
                  <div className="Critical-unusual">
                    <img src={Myinfoinner} alt="" />
                    Critical/Unusual Incidents
                  </div>
                </div>

                <div className="tab-detail-bottom">
                  <form>
                  {/* { showSelectDiv ? (
                    <div className="select-incident">
                      <label>Select Incident:<span style={{color:"red"}}>*</span></label>
                      <div className="form-row seli">
                        <select
                          onChange={(e) =>
                            setFormDatabyIncidentIdLoaded({
                              ...FormdatabyIncidentIdLoaded,
                              UnusualIncident_FosterParentID: e.target.value,
                            })
                          }
                        >
                          <option value="0">Select</option>
                          {selectIncidentData
                            ? selectIncidentData.map((incidentdata) => (
                                <option
                                  value={
                                    incidentdata.UnusualIncident_FosterParentID
                                  }
                                >
                                  {incidentdata.Incident}
                                </option>
                              ))
                            : ""}
                        </select>
                        <Link to="#" onClick={selectClick} className="blue-btn">
                          Select
                        </Link>
                        <Link to="#" onClick={deleteClick} className="red-btn">
                          Delete
                        </Link>
                      </div>
                    </div>) : ""} */}
                    <div className="form-row mt-25">
                      <div className="label9 labelpadtop">
                        <label for="appt">Child:<span style={{color:"red"}}>*</span></label>
                      </div>
                      <div className="label23">
                        <select
                         onChange={childSelectedData}
                        >
                          {FormdatabyIncidentIdLoadedStatus ? (
                            selectChildData
                              .filter(
                                (childId) =>
                                  childId.ClientID ===
                                  FormdatabyIncidentIdLoaded.ClientID
                              )
                              .map((childId) => (
                                <option selected value={childId.ClientID}>
                                  {childId.ClientName}
                                </option>
                              ))
                          ) : (
                            <option value="0">Please Select</option>
                          )}
                          {selectChildData.map((childData) => (
                            <option value={childData.ClientID}>
                              {childData.ClientName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="label7"></div>
                      <div className="label12 labelpadtop">
                        <label for="birthday">Date of Birth:<span style={{color:"red"}}>*</span></label>
                      </div>
                      <div className="label23">
                        <input type="date" onChange={(e) =>
                          setAddFormData({
                              ...AddFormData,
                              dateofbirth: e.target.value,
                            })
                          } value={childSelectedForData.DOB?childSelectedForData.DOB.slice(0,10):''} id="birthday" name="birthday" />
                     
                      </div>
                    </div>

                    <div className="g-background">
                      <div className="form-row dandt">
                        <div className="one-fourth">
                          <label for="birthday">Date of Incident:<span style={{color:"red"}}>*</span></label>
                          {FormdatabyIncidentIdLoadedStatus ? (
                            <input
                              onChange={(e) =>
                                setFormDatabyIncidentIdLoaded({
                                  ...FormdatabyIncidentIdLoaded,
                                  IncidentDate: e.target.value,
                                })
                              }
                              value={
                                FormdatabyIncidentIdLoadedStatus
                                  ? FormdatabyIncidentIdLoaded.IncidentDate.slice(
                                      0,
                                      10
                                    )
                                  : ""
                              }
                              type="date"
                              id="birthday"
                              name="birthday"
                            />
                          ) : (
                            <input
                              onChange={(e) =>
                                setFormDatabyIncidentIdLoaded({
                                  ...FormdatabyIncidentIdLoaded,
                                  IncidentDate: e.target.value,
                                })
                              }
                              type="date"
                              id="birthday"
                              name="birthday"
                            />
                          )}
                          <small>*Future date will not be allowed.</small>
                        </div>
                        <div className="one-fourth">
                          <label for="appt">
                            Time of Incident:<span style={{color:"red"}}>*</span>{" "}
                            {FormdatabyIncidentIdLoadedStatus
                              ? FormdatabyIncidentIdLoaded.IncidentDate.slice(
                                  11,
                                  16
                                )
                              : ""}
                          </label>
                          <input
                            type="time"
                            id="appt"
                            name="appt"
                            onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                IncidentTime: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="one-fourth">
                          <label for="birthday">Date of Discovery:<span style={{color:"red"}}>*</span></label>
                          {FormdatabyIncidentIdLoadedStatus ? (
                            <input
                              type="date"
                              id="birthday"
                              name="birthday"
                              onChange={(e) =>
                                setFormDatabyIncidentIdLoaded({
                                  ...FormdatabyIncidentIdLoaded,
                                  DiscoveryDate: e.target.value,
                                })
                              }
                              value={
                                FormdatabyIncidentIdLoadedStatus
                                  ? FormdatabyIncidentIdLoaded.DiscoveryDate.slice(
                                      0,
                                      10
                                    )
                                  : ""
                              }
                            />
                          ) : (
                            <input
                              type="date"
                              id="birthday"
                              name="birthday"
                              onChange={(e) =>
                                setFormDatabyIncidentIdLoaded({
                                  ...FormdatabyIncidentIdLoaded,
                                  DiscoveryDate: e.target.value,
                                })
                              }
                            />
                          )}
                          <small>*Future date will not be allowed.</small>
                        </div>
                        <div className="one-fourth">
                          <label for="appt">
                            Time of Discovery:<span style={{color:"red"}}>*</span>
                            {FormdatabyIncidentIdLoadedStatus
                              ? FormdatabyIncidentIdLoaded.DiscoveryDate.slice(
                                  11,
                                  16
                                )
                              : ""}
                          </label>
                          {FormdatabyIncidentIdLoadedStatus ? (
                            <input
                              type="time"
                              id="appt"
                              name="appt"
                              onChange={(e) =>
                                setFormDatabyIncidentIdLoaded({
                                  ...FormdatabyIncidentIdLoaded,
                                  DiscoveryTime: e.target.value,
                                })
                              }
                              value={
                                FormdatabyIncidentIdLoadedStatus
                                  ? FormdatabyIncidentIdLoaded.DiscoveryDate.slice(
                                      11,
                                      16
                                    )
                                  : ""
                              }
                            />
                          ) : (
                            <input
                              type="time"
                              id="appt"
                              name="appt"
                              onChange={(e) =>
                                setFormDatabyIncidentIdLoaded({
                                  ...FormdatabyIncidentIdLoaded,
                                  DiscoveryTime: e.target.value,
                                })
                              }
                            />
                          )}
                        </div>
                      </div>
                      

                      <div className="form-row mt-30">
                        <div className="form-container">
                          <div className="formrowalign">
                            <label className="label50 labelpadtop">
                              Name(s) of Foster Parent(s):<span style={{color:"red"}}>*</span>
                            </label>
                            <input className="label50" type="text" 
                        
                               value={FormdatabyIncidentIdLoaded.NameOfFosterParent} 
                              ></input>
                          </div>
                          <div className="formrowalign">
                            <label className="label50 labelpadtop2">
                              Identify Saint Francis Office Location(s):<span style={{color:"red"}}>*</span>
                            </label>
                            <input className="label50" type="text" value={ FormdatabyIncidentIdLoaded.IdentifySaintFrancisOfficeLocation}></input>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-row mt-30 ">
                      <div className="formrowalign2 incident-type">
                        <label className="rowalign label25">
                          What agency placed child in the home:<span style={{color:"red"}}>*</span>
                        </label>
                        <span className="rowalign1 label12">
                          <input
                            type="radio"
                            name="agency_name"
                            id="SaintFrancis"
                            value={"SaintFrancis"}
                            onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                SaintFrancis  : e.target.checked,
                              })
                            }
                            //onClick={EnableDisableTB}
                            onClick={(e) => EnableDisableTB(e, "SaintFrancis")}
                          />
                          <label for="SaintFrancis">Saint Francis</label>
                        </span>
                        <span className="rowalign1 label7">
                          <input
                            type="radio"
                            name="agency_name"
                            id="JJA"
                            value={"JJA"}
                            
                            onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                JJA  : e.target.checked,
                              })
                            }
                            //onClick={EnableDisableTB}
                            onClick={(e) => EnableDisableTB(e, "JJA")}
                          />
                          <label for="JJA">JJA</label>
                        </span>
                        <span className="rowalign1 label7">
                          <input
                            type="radio"
                            name="agency_name"
                            id="KVC"
                            value={"KVC"}
                            onChange={(e) => 
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                KVC  : e.target.checked,
                              })
                            }
                           // onClick={EnableDisableTB}
                            onClick={(e) => EnableDisableTB(e, "KVC")}
                          />

                        
                          <label for="KVC">KVC</label>
                        </span>
                        <span className="rowalign1 label7">
                          <input
                            type="radio"
                            name="agency_name"
                            id="TFI"
                            value={"TFI"}
                          
                            onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                TFI  : e.target.checked,
                              })
                            }
                            //onClick={EnableDisableTB}
                            onClick={(e) => EnableDisableTB(e, "TFI")}
                          />
                        
                          <label for="TFI">TFI</label>
                        </span>
                        <span className="rowalign1 label7">
                          <input
                            type="radio"
                            name="agency_name"
                            id="COC"
                            value={"COC"}
              
                            onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                COC  : e.target.checked,
                              })
                            }
                            //onClick={EnableDisableTB}
                            onClick={(e) => EnableDisableTB(e, "COC")}
                          />
                         
                          <label for="COC">COC</label>
                        </span>
                        <span className="rowalign1 label7">
                          <input
                            type="radio"
                            name="agency_name"
                            id="DCF"
                            value={"DCF"}
                          
                            onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                DCF  : e.target.checked,
                              })
                            }
                            //onClick={(e)=>EnableDisableTB}
                            onClick={(e) => EnableDisableTB(e, "DCF")}
                          />
                        
                          <label for="DCF">DCF</label>
                        </span>
                        <div>
                          <span className="rowalign1">
                            <input
                              type="radio"
                              name="agency_name"
                              id="Other"
                              value={"Other"}
                             
                              onChange={(e) =>
                                setFormDatabyIncidentIdLoaded({
                                  ...FormdatabyIncidentIdLoaded,
                                  Other  : e.target.checked,
                                })
                              }
                              //onClick={(e)=>EnableDisableTB}
                              onClick={(e) => EnableDisableTB(e, "Other")}
                          />
                           
                            <label for="Other">Other</label>
                          </span>
                          <span className="rowalign1">
                            <p className="identifytext"> (Identify):</p>
                            <input
                              className="input_text"
                              id="Identify"
                              type="text"
                              onChange={(e) =>
                                setFormDatabyIncidentIdLoaded({
                                  ...FormdatabyIncidentIdLoaded,
                                  othertextfield  : e.target.value,
                                })
                              }
                            />
                          </span>
                        </div>
                      </div>
                    </div>

                    {/* <div className="g-background">
                      <div className="form-row">
                        <div className="one-half">
                          <label for="appt">Child:</label>
                          <select
                            onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                ClientID: e.target.value,
                              })
                            }
                          >
                            {FormdatabyIncidentIdLoadedStatus ? (
                              selectChildData
                                .filter(
                                  (childId) =>
                                    childId.ClientID ===
                                    FormdatabyIncidentIdLoaded.ClientID
                                )
                                .map((childId) => (
                                  <option selected value={childId.ClientID}>
                                    {childId.ClientName}
                                  </option>
                                ))
                            ) : (
                              <option value="0">Please Select</option>
                            )}
                            {selectChildData.map((childData) => (
                              <option value={childData.ClientID}>
                                {childData.ClientName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="one-half">
                          <label for="appt">Person Reporting:</label>

                          <select
                            onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                ProviderMemberID: e.target.value,
                              })
                            }
                          >
                          
                            {FormdatabyIncidentIdLoadedStatus ? (
                              personReportingData
                                .filter(
                                  (personReporting) =>
                                    personReporting.ProviderMemberID ===
                                    FormdatabyIncidentIdLoaded.ProviderMemberID
                                )
                                .map((personReporting) => (
                                  <option
                                    selected
                                    value={personReporting.ProviderMemberID}
                                  >
                                    {personReporting.ProviderName}
                                  </option>
                                ))
                            ) : (
                              <option value="0">Please Select</option>
                            )}
                            {personReportingData.map((personReporting) => (
                              <option value={personReporting.ProviderMemberID}>
                                {personReporting.ProviderName}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="one-half">
                          <label for="appt">Extent of Child Injury:</label>
                          <select
                            onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                Client_InjuryTypeID: e.target.value,
                              })
                            }
                          >
                            {FormdatabyIncidentIdLoadedStatus ? (
                              childInjuryData
                                .filter(
                                  (childInjury) =>
                                    childInjury.InjuryTypeID ===
                                    FormdatabyIncidentIdLoaded.Client_InjuryTypeID
                                )
                                .map((childInjury) => (
                                  <option
                                    selected
                                    value={childInjury.InjuryTypeID}
                                  >
                                    {childInjury.InjuryType}
                                  </option>
                                ))
                            ) : (
                              <option value="0">Please Select</option>
                            )}
                            {childInjuryData.map((childInjury) => (
                              <option value={childInjury.InjuryTypeID}>
                                {childInjury.InjuryType}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="one-half">
                          <label for="appt">
                            Extent of Foster Parent Injury:
                          </label>
                          <select
                            onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                Staff_InjuryTypeID: e.target.value,
                              })
                            }
                          >
                           
                            {FormdatabyIncidentIdLoadedStatus ? (
                              fosterParentInjuryData
                                .filter(
                                  (fosterparentinjury) =>
                                    fosterparentinjury.InjuryTypeID ===
                                    FormdatabyIncidentIdLoaded.Staff_InjuryTypeID
                                )
                                .map((fosterparentinjury) => (
                                  <option
                                    selected
                                    value={fosterparentinjury.InjuryTypeID}
                                  >
                                    {fosterparentinjury.InjuryType}
                                  </option>
                                ))
                            ) : (
                              <option value="0">Please Select</option>
                            )}
                            {fosterParentInjuryData.map(
                              (fosterparentinjury) => (
                                <option value={fosterparentinjury.InjuryTypeID}>
                                  {fosterparentinjury.InjuryType}
                                </option>
                              )
                            )}
                          </select>
                        </div>

                        {isLoading ? <LoadSpinner /> : ""}

                        <div className="note">
                          <b>Help</b>
                          <ol>
                            <li>
                              None - No observable or predictable injury will
                              arise from the incident.
                            </li>
                            <li>Low - No pain or mild pain.</li>
                            <li>
                              Moderate - Discomforting or distressing pain
                              (treatment may include doctor visit).
                            </li>
                            <li>
                              Severe - Intense, severe, unbearable pain
                              (treatment may include emergency room visit).
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div> */}

                    <div className="incident-type">
                      <div>
                      <label className="headtext">
                        Type of Incident
                        <span className="italictext">
                          (check all that apply)<span style={{color:"red"}}>*</span>
                        </span>
                      </label>
                      <ul>
                        {/* onChange={e => setIncidentTypeData({ ...IncidentTypeData, IncidentList: e.target.value })} */}
                        {IncidentTypeData
                          ? IncidentTypeData.map((incindenttype,index) => (
                              <li>
                                {/* setFormDatabyIncidentIdLoaded({
                                      ...FormdatabyIncidentIdLoaded,
                                      IncidentType: e.target.value,
                                    }) */}
                                <input
                                  onChange={handleChange}
                                  type="checkbox"
                                  id={"chkIncident"+index}
                                  name={"chkIncident"+index}
                                  value={incindenttype.UnusualIncidentTypeID}
                                />
                                <label for={"chkIncident"+index}>
                                  {" "}
                                  {incindenttype.IncidentType}{" "}
                                </label>
                              </li>
                            ))
                          : ""}
                      </ul>
                      </div>
                    </div>

                    <div className="form-row v-pass mt-25">
                      <p className="headtext">
                        Did incident take place while on home visit/pass?:<span style={{color:"red",float: "right"}}>*</span>
                      </p>
                      <span>
                        {FormdatabyIncidentIdLoadedStatus ? (
                          <input
                            checked={
                              FormdatabyIncidentIdLoadedStatus
                                ? FormdatabyIncidentIdLoaded.IsHomeVisitIncident ==
                                  true
                                  ? true
                                  : false
                                : ""
                            }
                            onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                IsHomeVisitIncident: e.target.checked,
                              })
                            }
                            type="radio"
                            id="Yes"
                            name="fav_language"
                          />
                        ) : (
                          <input
                            onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                IsHomeVisitIncident: e.target.checked,
                              })
                            }
                            type="radio"
                            id="Yes"
                            name="fav_language"
                          />
                        )}

                        <label for="Yes">Yes</label>
                      </span>
                      <span>
                        {FormdatabyIncidentIdLoadedStatus ? (
                          <input
                            type="radio"
                            id="No"
                            onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                IsHomeVisitIncident: !e.target.checked,
                              })
                            }
                            name="fav_language"
                            value="No"
                            checked={
                              FormdatabyIncidentIdLoadedStatus
                                ? FormdatabyIncidentIdLoaded.IsHomeVisitIncident ==
                                  false
                                  ? true
                                  : false
                                : ""
                            }
                          />
                        ) : (
                          <input
                            type="radio"
                            id="No"
                            onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                IsHomeVisitIncident: !e.target.checked,
                              })
                            }
                            name="fav_language"
                            value="No"
                          />
                        )}

                        <label for="No">No</label>
                      </span>
                    </div>

                    <div>
                      <div className="add-cmt">
                        <label className="headtext">
                          Describe Incident:
                          <span className="italictext">
                            (if more room is needed, pleases attach additional
                            sheets)<span style={{color:"red"}}>*</span>
                          </span>
                        </label>
                        {FormdatabyIncidentIdLoadedStatus ? (
                          <textarea
                            value={
                              FormdatabyIncidentIdLoadedStatus
                                ? FormdatabyIncidentIdLoaded.IncidentDescription
                                : ""
                            }
                            onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                IncidentDescription: e.target.value,
                              })
                            }
                          ></textarea>
                        ) : (
                          <textarea
                            onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                IncidentDescription: e.target.value,
                              })
                            }
                          ></textarea>
                        )}
                      </div>
                      <div className="actiontextarea">
                        <label className="headtext label17">
                          Immediate Action Taken:<span style={{color:"red"}}>*</span>
                        </label>
                        {FormdatabyIncidentIdLoadedStatus ? (
                          <textarea style={{"border":"1px solid black"}}
                            value={
                              FormdatabyIncidentIdLoadedStatus
                                ? FormdatabyIncidentIdLoaded.ImmediateActionTaken
                                : ""
                            }
                            onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                ImmediateActionTaken: e.target.value,
                              })
                            }
                          >
                            {" "}
                          </textarea>
                        ) : (
                          <textarea style={{"border":"1px solid black"}}
                            onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                ImmediateActionTaken: e.target.value,
                              })
                            }
                          >
                            {" "}
                          </textarea>
                        )}
                      </div>
                      {/* <label>All Adults Present for Child Incident:</label>
                      {FormdatabyIncidentIdLoadedStatus ? (
                        <textarea
                          onChange={(e) =>
                            setFormDatabyIncidentIdLoaded({
                              ...FormdatabyIncidentIdLoaded,
                              AdultsInvolved: e.target.value,
                            })
                          }
                          value={
                            FormdatabyIncidentIdLoadedStatus
                              ? FormdatabyIncidentIdLoaded.AdultsInvolved
                              : ""
                          }
                        ></textarea>
                      ) : (
                        <textarea
                          onChange={(e) =>
                            setFormDatabyIncidentIdLoaded({
                              ...FormdatabyIncidentIdLoaded,
                              AdultsInvolved: e.target.value,
                            })
                          }
                        ></textarea>
                      )} */}
                    </div>

                    <div className="form-row mt-25">
                    <div className="formrowalign2">
  <label className="rowalign label15 headtext">
    Extent of Child Injury:<span style={{ color: "red" }}>*</span>
  </label>

  <span className="rowalign label10">
    {FormdatabyIncidentIdLoadedStatus ? (
      childInjuryData
        .filter(
          (childInjury) =>
            childInjury.InjuryTypeID ===
            FormdatabyIncidentIdLoaded.Client_InjuryTypeID
        )
        .map((childInjury) => (
          <div key={childInjury.InjuryTypeID}>
            <input
              type="radio"
              name="child_injury"
              id={`injury_${childInjury.InjuryTypeID}`} // Unique ID
              value={childInjury.InjuryTypeID}
              checked
              onChange={(e) =>
                setFormDatabyIncidentIdLoaded({
                  ...FormdatabyIncidentIdLoaded,
                  Client_InjuryTypeID: e.target.value,
                })
              }
            />
            <label htmlFor={`injury_${childInjury.InjuryTypeID}`}>
              {childInjury.InjuryType}
            </label>
          </div>
        ))
    ) : (
      <option value="0"></option>
    )}
    
    {childInjuryData.map((childInjury) => (
      <div key={childInjury.InjuryTypeID}>
        <input
          type="radio"
          name="child_injury"
          id={`injury_${childInjury.InjuryTypeID}`} 
          value={childInjury.InjuryTypeID}
          onChange={(e) =>
            setFormDatabyIncidentIdLoaded({
              ...FormdatabyIncidentIdLoaded,
              Client_InjuryTypeID: e.target.value,
            })
          }
        />
        <label htmlFor={`injury_${childInjury.InjuryTypeID}`}>
          {childInjury.InjuryType}
        </label>
      </div>
    ))}
  </span>
</div>

                    </div>
                    <div className="form-row mt-10">
                    <div className="formrowalign2">
  <label className="rowalign label15 headtext">
  Foster Parent Injury:<span style={{ color: "red" }}>*</span>
  </label>

  <span className="rowalign label10">
    {FormdatabyIncidentIdLoadedStatus ? (
      fosterParentInjuryData
        .filter(
          (fosterparentinjury) =>
          fosterparentinjury.InjuryTypeID ===
            FormdatabyIncidentIdLoaded.Staff_InjuryTypeID
        )
        .map((fosterparentinjury) => (
          <div key={fosterparentinjury.InjuryTypeID}>
            <input
              type="radio"
              name="staff_injury"
              id={`injury_${fosterparentinjury.InjuryTypeID}`} // Unique ID
              value={fosterparentinjury.InjuryTypeID}
              checked
              onChange={(e) =>
                setFormDatabyIncidentIdLoaded({
                  ...FormdatabyIncidentIdLoaded,
                  Staff_InjuryTypeID: e.target.value,
                })
              }
            />
            <label htmlFor={`injury_${fosterparentinjury.InjuryTypeID}`}>
              {fosterparentinjury.InjuryType}
            </label>
          </div>
        ))
    ) : (
      <option value="0"></option>
    )}
    
    {fosterParentInjuryData.map((fosterparentinjury) => (
      <div key={fosterparentinjury.InjuryTypeID}>
        <input
          type="radio"
          name="staff_injury"
          id={`injury_${fosterparentinjury.InjuryTypeID}`} 
          value={fosterparentinjury.InjuryTypeID}
          onChange={(e) =>
            setFormDatabyIncidentIdLoaded({
              ...FormdatabyIncidentIdLoaded,
              Staff_InjuryTypeID: e.target.value,
            })
          }
        />
        <label htmlFor={`injury_${fosterparentinjury.InjuryTypeID}`}>
          {fosterparentinjury.InjuryType}
        </label>
      </div>
    ))}
  </span>
</div>
                    </div>

                    <div className="add-cmt2 mt-25">
                      <label className="headtext">
                        All adults present or responsible for the client during
                        the incident:<span style={{color:"red"}}>*</span>
                      </label>
                      {FormdatabyIncidentIdLoadedStatus ? (
                        <textarea
                          onChange={(e) =>
                            setFormDatabyIncidentIdLoaded({
                              ...FormdatabyIncidentIdLoaded,
                              AdultsInvolved: e.target.value,
                            })
                          }
                          value={
                            FormdatabyIncidentIdLoadedStatus
                              ? FormdatabyIncidentIdLoaded.AdultsInvolved
                              : ""
                          }
                        ></textarea>
                      ) : (
                        <textarea
                          onChange={(e) =>
                            setFormDatabyIncidentIdLoaded({
                              ...FormdatabyIncidentIdLoaded,
                              AdultsInvolved: e.target.value,
                            })
                          }
                        ></textarea>
                      )}
                    </div>

                    <div className="form-row mt-25">
                      <div className="label15 labelpadtop">
                        <label for="appt">Person Reporting:<span style={{color:"red"}}>*</span></label>
                      </div>
                      <div className="label23">
                        <select
                          onChange={(e) =>
                            setFormDatabyIncidentIdLoaded({
                              ...FormdatabyIncidentIdLoaded,
                              ProviderMemberID: e.target.value,
                            })
                          }
                        >
                          {/* <option>Please Select</option> */}
                          {FormdatabyIncidentIdLoadedStatus ? (
                            personReportingData
                              .filter(
                                (personReporting) =>
                                  personReporting.ProviderMemberID ===
                                  FormdatabyIncidentIdLoaded.ProviderMemberID
                              )
                              .map((personReporting) => (
                                <option
                                  selected
                                  value={personReporting.ProviderMemberID}
                                >
                                  {personReporting.ProviderName}
                                </option>
                              ))
                          ) : (
                            <option value="0">Please Select</option>
                          )}
                          {personReportingData.map((personReporting) => (
                            <option value={personReporting.ProviderMemberID}>
                              {personReporting.ProviderName}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="label7"></div>
                      <div className="label15 labelpadtop">
                        <label for="birthday">Date Completed:<span style={{color:"red"}}>*</span></label>
                      </div>
                      <div className="label23">
                        <input type="date" />
                      </div>
                    </div>

                    <div className="mt-25 notificationalign">
                      <div className="textfont">
                        Send to foster care worker. Original Placed in Child’s
                        file.
                      </div>
                      <div className="headtext">
                        <p>Notification</p>
                      </div>
                      <table>
                        <tr>
                          <th>Contact (If Applicable)</th>
                          <th>Contact Name</th>
                          <th>Date</th>
                          <th>Time</th>
                          <th>Verbal/Email</th>
                          <th>By whom?</th>
                        </tr>
                        <tr className="tableinputbg">
                          <td>Administration</td>
                          <td>
                            {" "}
                            <input type="text"   onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                AdminContact: e.target.value,
                              })
                            } />
                          </td>
                          <td>
                            {" "}
                            <input type="text"   onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                AdminDate: e.target.value,
                              })
                            }/>
                          </td>
                          <td>
                            {" "}
                            <input type="text"   onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                AdminTime: e.target.value,
                              })
                            }/>
                          </td>
                          <td>
                            {" "}
                            <input type="text"   onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                AdminEmail: e.target.value,
                              })
                            }/>
                          </td>
                          <td>
                            {" "}
                            <input type="text"   onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                AdminBywhom: e.target.value,
                              })
                            } />
                          </td>
                        </tr>
                        <tr>
                          <td>Protection Reporting Center (when applicable)</td>
                          <td>
                            {" "}
                            <input type="text"  onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                ReportingCenterContact: e.target.value,
                              })
                            } />
                          </td>
                          <td>
                            {" "}
                            <input type="text"  onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                ReportingCenterByDate: e.target.value,
                              })
                            }/>
                          </td>
                          <td>
                            {" "}
                            <input type="text" onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                ReportingCenterTime: e.target.value,
                              })
                            } />
                          </td>
                          <td>
                            {" "}
                            <input type="text"  onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                ReportingCenterEmail: e.target.value,
                              })
                            }/>
                          </td>
                          <td>
                            {" "}
                            <input type="text" onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                ReportingCenterByWhom: e.target.value,
                              })
                            } />
                          </td>
                        </tr>
                        <tr className="tableinputbg">
                          <td>
                            Saint Francis Agency verbal notification immediately
                          </td>
                          <td>
                            {" "}
                            <input type="text" onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                            SFAVerbalNotificationContact: e.target.value,
                              })
                            }/>
                          </td>
                          <td>
                            {" "}
                            <input type="text" onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                            SFAVerbalNotificationDate: e.target.value,
                              })
                            }/>
                          </td>
                          <td>
                            {" "}
                            <input type="text"onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                            SFAVerbalNotificationTime: e.target.value,
                              })
                            } />
                          </td>
                          <td>
                            {" "}
                            <input type="text" onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                            SFAVerbalNotificationEmail: e.target.value,
                              })
                            }/>
                          </td>
                          <td>
                            {" "}
                            <input type="text" onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                            SFAVerbalNotificationByWhom: e.target.value,
                              })
                            }/>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Saint Francis Agency written notification within 24
                            hours
                          </td>
                          <td>
                            {" "}
                            <input type="text" onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                            SFAWrittenNotificationContact: e.target.value,
                              })
                            } />
                          </td>
                          <td>
                            {" "}
                            <input type="text"  onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                            SFAWrittenNotificationDate: e.target.value,
                              })
                            }/>
                          </td>
                          <td>
                            {" "}
                            <input type="text"  onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                            SFAWrittenNotificationTime: e.target.value,
                              })
                            }/>
                          </td>
                          <td>
                            {" "}
                            <input type="text"  onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                            SFAWrittenNotificationEmail: e.target.value,
                              })
                            }/>
                          </td>
                          <td>
                            {" "}
                            <input type="text"  onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                            SFAWrittenNotificationByWhom: e.target.value,
                              })
                            }/>
                          </td>
                        </tr>
                        <tr className="tableinputbg">
                          <td>Police/Sheriff</td>
                          <td>
                            {" "}
                            <input type="text" onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                Police_SheriffContact: e.target.value,
                              })
                            } />
                          </td>
                          <td>
                            {" "}
                            <input type="text" onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                Police_SheriffDate: e.target.value,
                              })
                            }/>
                          </td>
                          <td>
                            {" "}
                            <input type="text"onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                Police_SheriffTime: e.target.value,
                              })
                            } />
                          </td>
                          <td>
                            {" "}
                            <input type="text" onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                Police_SheriffEmail: e.target.value,
                              })
                            }/>
                          </td>
                          <td>
                            {" "}
                            <input type="text" onChange={(e) =>
                              setFormDatabyIncidentIdLoaded({
                                ...FormdatabyIncidentIdLoaded,
                                Police_SheriffByWhom: e.target.value,
                              })
                            }/>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div className="action mt-25">
                      <p>STEPS OF ACTION</p>
                      <a>
                        When you observe or are part of a Critical/Unusual
                        Incident, proceed through the following steps:
                      </a>
                      <ol>
                        <li>
                          Take immediate action as needed (i.e. safety,
                          emergency procedures, first aid).
                        </li>
                        <li>
                          Decide if emergent assistance from anyone is needed.
                          <ul>
                            <li>
                              If emergent assistance is needed, contact the
                              appropriate people (i.e. police, fire department,
                              etc.)
                            </li>
                            <li>
                              For verbal notification to Saint Francis, the
                              emergency number is 1-888-732-4673
                            </li>
                          </ul>
                        </li>
                        <li>Complete the Critical/Unusual Incident report.</li>
                        <li>
                          Send Critical/Unusual Incident form to your foster
                          care worker by email or mail within 24 hours.
                        </li>
                        <li>
                          Allegations of abuse and neglect must be reported
                          immediately.
                        </li>
                      </ol>
                    </div>

                    <div className="action-btn">
                      <Link to="#" onClick={closeClicked} className="red-btn">
                        Close
                      </Link>

                      {FormdatabyIncidentIdLoadedStatus ? (
                        <Link
                          to="#"
                          onClick={onUpdateButtonClick}
                          className="blue-btn"
                        >
                          Submit
                        </Link>
                      ) : (
                        <Link
                          to="#"
                          onClick={ ()=>onSubmitButtonClick("fromSubmit")}
                          className="blue-btn"
                        >
                          Submit
                        </Link>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CriticalUnusualIncidentForm;
